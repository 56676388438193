import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Disable } from "react-disable";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import ShareSearchLine from "../ShareSearchLine";
import SearchLineSettingsOnlyShare from "./SearchLineSettingsOnlyShare";

interface Props {
    searchline: any;
    type: "opportunity" | "tender";
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
}
export interface SearchLine {
    id: string;
    title: string;
    region: string;
    searchterms: string[];
    defaultTerm: string[];
    update?: string;
    updateChannel?: string;
    results: string;
    active: string;
    loading: boolean;
}
export interface Color {
    id: string;
    colors: string[];
}

const SearchLineItemOnlyShare: React.FC<Props> = ({ searchline, refetch, type }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openShare, setOpenShare] = useState<boolean>(false);

    const dist = searchline.pivot
        ? {
              in_app: searchline.pivot.in_app,
              worklist: searchline.pivot.worklist,
              email_interval: searchline.pivot.email_interval,
          }
        : {
              in_app: true,
              worklist: true,
              email_interval: "daily",
          };

    const handleOpenShare = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Open menu with colleagues and teams
        setAnchorEl(event.currentTarget);
        setOpenShare(true);
    };

    const handleCloseShare = () => {
        // Open share menu
        setOpenShare(false);
        setAnchorEl(null);
        // Close menu
    };

    if (isMobile) {
        return <></>;
    }

    return (
        <Disable disabled={false}>
            <SearchLineSettingsOnlyShare
                color={searchline.color}
                type={type}
                searchline={searchline}
                refetch={refetch}
                handleOpenShare={handleOpenShare}
            />
            <ShareSearchLine
                alreadyShared={searchline.participants.map((user: any) => user.id)}
                type="tender"
                dist={dist}
                search={searchline}
                searchID={searchline.id}
                shareOpened={openShare}
                anchorEl={anchorEl}
                handleCloseSub={handleCloseShare}
                teamsComponent={[]}
                colleaguesComponents={[]}
            />
        </Disable>
    );
};

export default SearchLineItemOnlyShare;
