import React from "react";
import { IconButton, Drawer, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PdfToolbar from "../pdfViewer/header/PdfToolbar";

interface Props {
    open: boolean;
    onClose(): void;
    title?: string;
    bgColor: string;
    toolbarColor: string;
    doc_id?: string;
    module_item_id?: string;
    /**
     * url to load pdf
     */
    url?: string;
    downloadable: boolean;
}

const ContentDrawer: React.FC<Props> = ({ open, onClose, children, title, bgColor, toolbarColor, module_item_id, doc_id, url, downloadable }) => {
    return (
        <Drawer
            sx={{
                zIndex: 1600,
                "& .MuiDrawer-paper": {
                    color: "#ffffff",
                    width: { xs: "100vw", md: "70vw" },
                },
            }}
            anchor="right"
            open={open}
        >
            <div style={{ backgroundColor: `${bgColor}`, display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                {/* Topbar with closebutton and, optional: title */}
                <div
                    style={{
                        backgroundColor: `${toolbarColor}`,
                        height: "48px",
                        padding: "4px 8px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <IconButton size="small" onClick={onClose} style={{ marginRight: 16 }}>
                            <CloseIcon sx={{ color: "#ffffff" }} />
                        </IconButton>
                        <Typography noWrap>{title}</Typography>
                    </div>
                    <PdfToolbar
                        module_item_id={module_item_id}
                        doc_id={doc_id}
                        title={title}
                        url={url}
                        onClose={onClose}
                        downloadable={downloadable}
                    />
                    <div />
                </div>

                {/* 
                    div contains child components or data
                    close button always visible because data flows underneath
                 */}
                <div>{children}</div>
            </div>
        </Drawer>
    );
};

export default ContentDrawer;
