import React, { useState } from "react";
import HeadingBlock from "./HeadingBlock";
// import OptionBlock from "./OptionBlock";
import { Box, Link, Typography } from "@mui/material";
import ReadMoreComponent from "../ReadMoreComponent";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DocumentDrawer from "../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { GetScopeValuesOfCurrentTender_tender_scope } from "../../__generated__/GetScopeValuesOfCurrentTender";
interface Props {
    scope: GetScopeValuesOfCurrentTender_tender_scope;
    showSource: boolean;
    tender_id: string;
    downloadable: boolean;
}
interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
}
const ScopeWithoutLots: React.FC<Props> = ({ scope, showSource, tender_id, downloadable }) => {
    const { t } = useTranslation();
    const source = scope.source;
    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    const blue = "#173357";
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "", doc_id: "" });
    };

    // <Divider style={{ marginBottom: 8 }} />

    // {/* Block option: -> temporary filled with: initial time, option 1, option 2
    // TODO: connect to GQL */}
    // <OptionBlock scopeLines={scope.lines} />
    const activeLines = scope.total !== null || scope.max !== null || scope.min !== null;
    return (
        <React.Fragment>
            {/* Block Heading: Total expected scope */}

            <Box m={2} style={{ paddingBottom: activeLines ? 0 : 16 }}>
                <ReadMoreComponent text={scope.description} length={500} variant={"body2"} />
            </Box>

            {activeLines && (
                <div style={{ marginTop: 8, marginBottom: 8, paddingBottom: 16 }}>
                    {scope.total && (
                        <HeadingBlock title={t("tenderPage.TotalExpectedValue")} currency={scope.total.currency} value={scope.total.value} />
                    )}
                    {scope.min && <HeadingBlock title={t("tenderPage.MinimumExpectedValue")} currency={scope.min.currency} value={scope.min.value} />}
                    {scope.max && <HeadingBlock title={t("tenderPage.MaximumExpectedValue")} currency={scope.max.currency} value={scope.max.value} />}
                </div>
            )}

            {/* show/hide source of description */}
            {showSource && (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: -12, marginLeft: 16, marginBottom: 8 }}>
                    <Typography style={{ fontStyle: "italic", display: "inline", marginRight: 4 }} variant="caption">
                        {t("Source")}:
                    </Typography>

                    {source?.__typename === "Document" ? (
                        <>
                            {source.extension === "pdf" ? (
                                <Link
                                    underline="none"
                                    component="button"
                                    variant="caption"
                                    style={{ fontStyle: "italic" }}
                                    onClick={() => {
                                        setOpen({
                                            link: source.temporary_link,
                                            open: true,
                                            label: source.filename,
                                            doc_id: source.id,
                                        });
                                    }}
                                >
                                    {source.filename}
                                </Link>
                            ) : (
                                <Link
                                    underline="none"
                                    component="button"
                                    style={{ fontStyle: "italic" }}
                                    variant="caption"
                                    onClick={(e) => {
                                        // Stop click on listitem to prevent document-viewer to initiate
                                        e.stopPropagation();
                                        const element = document.createElement("a");
                                        element.setAttribute("download", `${source.filename}`);
                                        element.setAttribute(
                                            "href",
                                            `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFile/${source.id}`
                                        );
                                        element.style.display = "none";
                                        document.body.appendChild(element);
                                        element.click();
                                        document.body.removeChild(element);
                                    }}
                                >
                                    {source.filename}
                                </Link>
                            )}
                        </>
                    ) : source?.__typename === "Publication" ? (
                        <Typography style={{ fontStyle: "italic" }} variant="caption">
                            {moment(source?.dispatchdate).format("LL")} - {source?.host}, {source?.publication_type.name}
                        </Typography>
                    ) : (
                        ""
                    )}
                </div>
            )}
            {/* Drawer that contains clicked pdf file */}
            {typeof open.link === "string" && open.open === true && typeof open.label === "string" && (
                <DocumentDrawer
                    downloadable={downloadable}
                    open={open.open}
                    onClose={closeDrawer}
                    title={open.label}
                    color={blue}
                    url={open.link}
                    doc_id={open.doc_id}
                    module_item_id={tender_id}
                />
            )}
        </React.Fragment>
    );
};

export default ScopeWithoutLots;
