import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

interface Props {
    /**
     * Intro text
     */
    text: string;
}

const Introduction: React.FC<Props> = ({ text }) => {
    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Typography
            sx={{
                position: "relative",
                borderRadius: "8px",
                fontWeight: 500,
                display: "inline",
                fontSize: aboveMobile ? "15px" : "medium",
            }}
        >
            {text}
        </Typography>
    );
};

export default Introduction;
