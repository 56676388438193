import Mark from "mark.js";

interface Options {
    a: string;
    setDisabled: (arg0: boolean) => void;
    pageNumber: number;
    setNumPages: (arg0: number) => void;
    chosenWords: any;
}

export default async ({ a, setDisabled, pageNumber, setNumPages, chosenWords }: Options) => {
    const PDFJS = window.pdfjsLib;
    const DEFAULT_SCALE = 1.33;
    const url = a;
    // First, we need to load the document using the getDocument utility
    const loadingTask = PDFJS.getDocument(url);
    const pdf = await loadingTask.promise;
    // Number of pages in the documtent
    const { numPages } = pdf;
    setNumPages(numPages);
    const viewer = document.getElementById("viewer");

    setTimeout(function () {
        viewer?.replaceChildren();
        loadPage(pageNumber);
    });

    function createEmptyPage(num: any) {
        const page = document.createElement("div");
        const canvas = document.createElement("canvas");
        const wrapper = document.createElement("div");
        const textLayer = document.createElement("div");
        const annotationLayer = document.createElement("div");
        page.className = "page";
        wrapper.className = "canvasWrapper";
        textLayer.className = "textLayer";
        textLayer.setAttribute("id", `text-layer`);
        annotationLayer.className = "annotationLayer";

        page.setAttribute("id", `pageContainer${num}`);
        page.setAttribute("data-loaded", "false");
        page.setAttribute("data-page-number", num);
        canvas.setAttribute("id", `page${num}`);

        page.appendChild(wrapper);
        page.appendChild(textLayer);
        page.appendChild(annotationLayer);
        wrapper.appendChild(canvas);

        return page;
    }

    // Function to check if a term occurs as a whole word within a text node
    const isWholeWord = (text: string, term: string): boolean => {
        // Create a regular expression to match the term as a whole word
        const regex = new RegExp(`\\b${term}\\b`, "gi");

        // Test if the term occurs within the text as a whole word
        return regex.test(text);
    };

    /**
     * Check if the found term is part of an extended meaningful word.
     */
    const isPartOfExtendedWord = (fullText: string, term: string): boolean => {
        const extendedWordRegex = new RegExp(`${term}\\w*`, "i"); // Matches term followed by any word characters
        return extendedWordRegex.test(fullText);
    };

    function loadPage(pageNum: number) {
        viewer?.appendChild(createEmptyPage(pageNum));
        setDisabled(true);
        return pdf
            .getPage(pageNum)
            .then(
                (pdfPage: {
                    getViewport: (arg0: number) => any;
                    render: (arg0: { canvasContext: CanvasRenderingContext2D | null; viewport: any }) => void;
                    getTextContent: () => Promise<any>;
                    getAnnotations: () => Promise<any>;
                }) => {
                    const page = document.getElementById(`pageContainer${pageNum}`) as HTMLCanvasElement;
                    const canvas = page?.querySelector("canvas") as HTMLCanvasElement;
                    const wrapper = page?.querySelector(".canvasWrapper") as HTMLCanvasElement;
                    const container = page?.querySelector(".textLayer") as HTMLCanvasElement;

                    const canvasContext = canvas?.getContext("2d");
                    const viewport = pdfPage.getViewport(DEFAULT_SCALE);

                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    page.style.width = `${viewport.width}px`;
                    page.style.height = `${viewport.height}px`;
                    wrapper.style.width = `${viewport.width}px`;
                    wrapper.style.height = `${viewport.height}px`;
                    container.style.width = `${viewport.width}px`;
                    container.style.height = `${viewport.height}px`;

                    pdfPage.render({
                        canvasContext,
                        viewport,
                    });

                    pdfPage
                        .getTextContent()
                        .then((textContent) => {
                            PDFJS.renderTextLayer({
                                textContent,
                                container,
                                viewport,
                                textDivs: [],
                            });
                        })
                        .finally(() => {
                            // create instance to mark searchterm in documentpage(s)
                            if (document.querySelector(".textLayer") !== null) {
                                const instance = new Mark(document.querySelector(".textLayer") as HTMLCanvasElement);
                                // Iterate over each term and mark it
                                chosenWords.forEach((term: string) => {
                                    instance.markRegExp(new RegExp(term, "gi"), {
                                        element: "span",
                                        className: "foundItem",
                                        acrossElements: true,

                                        filter: (textNode: Text, foundTerm: string, totalCounter: number) => {
                                            const fullText = textNode.textContent || "";

                                            // Match whole words or related terms
                                            const isWholeOrExtendedWord =
                                                isWholeWord(fullText, foundTerm) || isPartOfExtendedWord(fullText, foundTerm);

                                            return isWholeOrExtendedWord;
                                        },
                                    });
                                });
                            }
                        });
                    setDisabled(false);

                    return pdfPage;
                }
            );
    }
};
