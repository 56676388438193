import React from "react";

// Import Material UI
import { Badge, Grid } from "@mui/material";

// Import components
import StatusChip from "../../../components/StatusChip";
import LikeOrgBtn from "../../../components/LikeOrgBtn";
import ChatButtonTopBar from "../../../components/ChatButtonTopBar";
import { QUERY_USER_LIKED_ORGS } from "../../../graphql/queryDefCurrentUser";
import { gql, useQuery } from "@apollo/client";
import { GetOrgStateForUser } from "../../../__generated__/GetOrgStateForUser";

interface Props {
    id: string;
    status: string | null;
    name: string | null;
    showStar: boolean;
}
const OrgTopBarModule: React.FC<Props> = ({ status, id, name, showStar }) => {
    const pink = "#E03660";

    const { data } = useQuery<GetOrgStateForUser>(QUERY_ORG_STATE_FOR_USER, {
        variables: { id: id },
        fetchPolicy: "network-only",
    });

    return (
        <Grid container xs={12} md={12} justifyContent="space-between" alignItems="center" style={{ paddingTop: 10 }}>
            {/* Current status of the tender */}
            <Grid item sm={6} xs={6}>
                {/* <StatusChip type="actief" />
                <StatusChip type="opgeheven" />
                <StatusChip type="in oprichting" /> */}
                <StatusChip type={status} colored={true} />
            </Grid>

            {/* TenderActions, differnent based on list context */}
            <Grid item sm={6} xs={6}>
                <div className="flex right">
                    {/* Mark AD with star */}
                    {showStar && <LikeOrgBtn org_id={id} buttonSize="medium" color={pink} refetchQueries={[QUERY_USER_LIKED_ORGS]} />}
                    {/* Add/edit note of AD */}
                    <Badge
                        sx={{
                            ".MuiBadge-standard": {
                                maxWidth: "16px",
                                minWidth: "16px",
                                minHeight: "16px",
                                maxHeight: "16px",
                                fontSize: "0.6rem",
                                marginRight: "7px",
                                marginTop: "7px",
                            },
                        }}
                        badgeContent={data?.tenderer?.unreadChatsCount}
                        color="primary"
                        max={999}
                    >
                        <ChatButtonTopBar
                            id={id}
                            name={name || ""}
                            module={"App\\Models\\MarketParty"}
                            notesAvailable={Boolean(data?.tenderer?.hasConversations)}
                            location="Organizations"
                        />
                    </Badge>
                    {/* TODO: v4.x inline update edit */}
                    {/* edit notification settings of the MP */}
                    {/* 
                    <Tooltip title={t("tenderDetail.edit-updates") as string} placement="top">
                        <IconButton onClick={() => setUpdatesModal((v) => !v)}>
                            <Autorenew fontSize="small" />
                        </IconButton>
                    </Tooltip> */}

                    {/* Dialog that show up when user clicks on edit updates */}
                    {/* <TenderUpdatesDialog open={updatesModal} handleClose={() => setUpdatesModal(false)} /> */}
                </div>
            </Grid>
        </Grid>
    );
};

export default OrgTopBarModule;

//? get all activities of current user
export const QUERY_ORG_STATE_FOR_USER = gql`
    query GetOrgStateForUser($id: ID!) {
        tenderer(id: $id) {
            hasConversations
            unreadChatsCount
        }
    }
`;
