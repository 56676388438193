import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
// import update from "immutability-helper";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// Import components
import FilterDrawer from "../../components/FilterDrawer/FilterDrawer";
import { OpportunityFilterInput, SortOrder } from "../../__generated__/globalTypes";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";

// Material UI components
import OpportunityTableActions from "./OpportunitySearch/OpportunityTableActions";
import { useLocation } from "react-router-dom";
import OpportunitySearchBar from "./OpportunitySearchBar";
import { Disable } from "react-disable";
import { GetAllDocsForOpportunities_filterOpportunityFiles_filters } from "../../__generated__/GetAllDocsForOpportunities";
import SearchWizardContainer from "../profile/Dialog/SearchWizardContainer";
import TopFilterBar, { DateRange } from "../../components/FilterDrawer/TopFilterBar";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IntRange } from "../../components/FilterDrawer/IntRangeFilterItem";
import TenderSearchRuleWizard from "../tenders/TenderSearchRuleWizard";
import MatomoEvent from "../../models/MatomoEvent";
import { useCustomOppOptions } from "../../components/contextProviders/OppCustomOptionProvider";

interface Props {
    /**
     * User input in searchbox
     */
    query: string;
    /**
     * set query as searchparam
     */
    setQuery: (query: string) => void;
    /**
     * reset page to chosen page
     */
    resetPage: () => void;
    /**
     * filter input
     */
    searchInput: OpportunityFilterInput;
    /**
     *
     * @param searchInput onchange filterinput
     */
    onChange(searchInput: OpportunityFilterInput): void;
    /**
     * start search with active filter(s)
     */
    startSearch(): void;
    /**
     * show button to create search rule
     */
    visible: boolean;

    /**
     *
     * @param view set view to selected viewtype
     */
    onViewClick?(view: number): void;
    /**
     * Chosen view, listview is default
     */
    activeView?: number;
    /**
     * Data to fill filtervalues from backend
     */
    filterData?: GetAllDocsForOpportunities_filterOpportunityFiles_filters[] | null;
    /**
     * loading state
     */
    loading: boolean;
    /**
     * Sorting columnname (needed for export function)
     */
    orderField: string;
    /**
     * Sorting order (needed for export function)
     */
    order: SortOrder;
    /**
     * columns
     */
    columns: any[];
    /**
     * total opportunities
     */
    total?: number;
    /**
     * Resetbutton to deactivate all filters
     */
    resetFilters: () => void;
}

const OpportunitySearch: React.FC<Props> = ({
    filterData,
    searchInput,
    onChange,
    startSearch,
    visible,
    query,
    setQuery,
    resetPage,
    loading,
    total,
    columns,
    order,
    orderField,
    resetFilters,
}) => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const { t } = useTranslation();
    // Check if user is on review page
    const reviewPage = pathname === "/opportunities/reviews";
    // Check if user is on worklist page
    const worklist = pathname === "/opportunities/worklist";
    const allpage = pathname === "/opportunities";
    const [openPopup, setOpenPopup] = useState<string | false>(false);
    const [publishedOppDate, setPublishedOppDate] = useState<DateRange>({ from: null, to: null });
    const [personalYear, setPersonalYear] = useState<DateRange>({ from: null, to: null });
    const [yearFrom, setYearFrom] = useState<DateRange>({ from: null, to: null });
    const [documentLastReviewed, setDocumentLastReviewed] = useState<DateRange>({ from: null, to: null });
    const [intRange, setIntRange] = useState<IntRange>({ from: 0, to: 0 });
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [caSearchQuery, setCaSearchQuery] = useState<string>("");
    const amount = filterData?.filter((f) => f.active === true).length;
    const wrongFilters = filterData?.filter((f) => f.searchRuleFilter === false && f.active === true);
    const allowedFilters = filterData?.filter((f) => f.searchRuleFilter === true && f.active === true);
    const [resetCustomFilter, setResetCustomFilter] = useState<boolean>(false);
    const { trackSiteSearch, trackEvent } = useMatomo();

    const { setCustomOppOptionsWorklist, setCustomOppOptionsReviews, setCustomOppOptionsAll } = useCustomOppOptions();

    // Track searchbox
    const trackSearch = (q: string) => {
        trackSiteSearch({ keyword: `${q}`, category: "Opportunity" });
    };
    /**
     * Get allowed filters for searchrule and create object to send to wizard
     * allowedFilters -> filter out filters that are not allowed to use in searchrule wizard
     * map entries in searchInput and check if allowedFilters include a title that is equal to a title in searchInput
     * if true, create object with key and value(s) from searchInput
     * As result, merge all objects in one object as key: value pairs like TenderFilterInput
     */
    const filterdFilters = Object.entries(searchInput)
        .map(([key, val]) => (allowedFilters?.map((w) => w.title).includes(key) ? { [key]: val } : null))
        .filter((f) => f !== null)
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});

    // close handler to close modal
    const closeModal = () => {
        setOpenModal(false);
    };

    // Track event
    const trackAllFilterBtn = (event: MatomoEvent) => {
        trackEvent(event);
    };

    useEffect(() => {
        // get query from session storage
        if (sessionStorage.getItem("ocaf")) {
            setCaSearchQuery(JSON.parse(sessionStorage.getItem("ocaf") || ""));
        }
        sessionStorage.removeItem("ocaf");
    }, []);

    /**
     * Check if device is mobile or not
     */
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    /**
     * Open filterdrawer
     */
    const [open, setOpen] = useState<boolean>(false);

    /**
     * Handler to close drawer
     */
    const closeDrawer = () => {
        setOpen(false);
    };

    /**
     * function to check state of button
     */
    const visibleAddBtn = () => {
        if (visible === false) {
            return "noShow";
        }
        if (visible && query.length > 2 && !loading) {
            return "show";
        }
        return "disabled";
    };

    /**
     * Reset handler to remove session storage key with data
     * CA filter
     */
    const handleResetCustomFilter = () => {
        if (pathname === "/opportunities/worklist") {
            setCustomOppOptionsWorklist([]);
            sessionStorage.removeItem("coow");
        }
        if (pathname === "/opportunities/reviews") {
            setCustomOppOptionsReviews([]);
            sessionStorage.removeItem("coor");
        }
        if (pathname === "/opportunities") {
            setCustomOppOptionsAll([]);
            sessionStorage.removeItem("cooa");
        }
    };
    return (
        <Disable disabled={loading} disabledOpacity={1}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                    {!worklist ? (
                        <OpportunitySearchBar
                            openModal={() => {
                                // TODO: if amount of active filters that are not searchrulefilters > 0 => show modal
                                if (amount !== undefined && amount > 0 && wrongFilters !== undefined && wrongFilters.length > 0) {
                                    setOpenModal(true);
                                } else {
                                    setOpenPopup("opportunity");
                                }
                            }}
                            query={query}
                            onChangeQuery={(q) => {
                                setQuery(q);
                                resetPage();
                                if (q.length > 2) {
                                    trackSearch(q);
                                }
                            }}
                            onSearch={() => {
                                startSearch();
                            }}
                            visible={visibleAddBtn()}
                        />
                    ) : (
                        // Spacer (no searchbar visible)
                        <div style={{ height: 52 }} />
                    )}
                </div>
            </div>

            {/* spacer */}
            <div style={{ height: 20 }} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {noMobile && (
                        /**
                         * Bar with top- and active filters
                         */
                        <TopFilterBar
                            pageview={"opportunities"}
                            filterData={filterData ? filterData : []}
                            resetPage={resetPage}
                            onChange={onChange}
                            startSearch={startSearch}
                            searchInput={searchInput}
                            publishedOppDate={publishedOppDate}
                            setPublishedOppDate={setPublishedOppDate}
                            personalYear={personalYear}
                            setPersonalYear={setPersonalYear}
                            yearFrom={yearFrom}
                            setYearFrom={setYearFrom}
                            documentLastReviewed={documentLastReviewed}
                            setDocumentLastReviewed={setDocumentLastReviewed}
                            IntRangeState={intRange}
                            setIntRangeState={setIntRange}
                            caSearchQuery={caSearchQuery}
                            setCaSearchQuery={setCaSearchQuery}
                            resetCustomFilter={resetCustomFilter}
                            setResetCustomFilter={setResetCustomFilter}
                        />
                    )}
                    {/*
                     * Button to open all filters drawer
                     */}
                    <Button
                        startIcon={<TuneIcon />}
                        size="small"
                        sx={{ color: "#000000" }}
                        onClick={() => {
                            setOpen(true);
                            trackAllFilterBtn({ category: "Opportunity", action: "Click-show-all—filters" });
                        }}
                        style={{ marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0 }}
                    >
                        {t("allFilters")}
                    </Button>
                    {allpage === true && sessionStorage.getItem("aof") !== "{}" && !loading && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setCaSearchQuery("");
                                setResetCustomFilter(true);
                                handleResetCustomFilter();
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                    {reviewPage === true && sessionStorage.getItem("rof") !== `{}` && !loading && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setCaSearchQuery("");
                                setResetCustomFilter(true);
                                handleResetCustomFilter();
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                    {worklist === true && sessionStorage.getItem("wof") !== "{}" && !loading && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setCaSearchQuery("");
                                setResetCustomFilter(true);
                                handleResetCustomFilter();
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                </div>

                {/*
                 * Component contains buttons for table
                 * - Change columns
                 * - export function
                 */}
                <OpportunityTableActions
                    worklist={worklist}
                    reviewPage={reviewPage}
                    allpage={allpage}
                    loading={loading}
                    searchInput={searchInput}
                    order={order}
                    total={total}
                    orderField={orderField}
                    query={query}
                    columns={columns}
                />
            </div>
            <TenderSearchRuleWizard
                open={openModal}
                onClose={closeModal}
                amount={amount}
                openWizard={() => setOpenPopup("opportunity")}
                wrongFilters={wrongFilters}
            />
            <SearchWizardContainer
                resetFilters={resetFilters}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                editRule={{
                    id: "newOppFromList",
                    name: "",
                    // state: null,
                    // distribution: null,
                    query: query,
                    // target: "",
                    filters: JSON.stringify(filterdFilters),
                    // color: "#9e9e9e",
                }}
            />
            <FilterDrawer
                loading={loading}
                open={open}
                onClose={closeDrawer}
                searchInput={searchInput}
                onChange={onChange}
                startSearch={startSearch}
                location={"opportunities"}
                filterData={filterData ? filterData : []}
                publishedOppDate={publishedOppDate}
                setPublishedOppDate={setPublishedOppDate}
                personalYear={personalYear}
                setPersonalYear={setPersonalYear}
                yearFrom={yearFrom}
                setYearFrom={setYearFrom}
                setDocumentLastReviewed={setDocumentLastReviewed}
                documentLastReviewed={documentLastReviewed}
                setIntRange={setIntRange}
                IntRangeState={intRange}
                caSearchQuery={caSearchQuery}
                setCaSearchQuery={setCaSearchQuery}
            />
        </Disable>
    );
};

export default OpportunitySearch;
