import React from "react";
import { Card, CardActionArea, CardMedia, CardContent, CardActions, Typography, Chip, Button, Box } from "@mui/material";
import { Today } from "@mui/icons-material";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";

import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";

import { useQuery } from "@apollo/client";
import { TendenzEvents, TendenzEventsVariables } from "../../__generated__/TendenzEvents";
import { TENDENZ_EVENTS } from "../../graphql/tendenzDefinitions";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
    a?: number;
}

// STYLING NAVIGATION AND PAGINATION IN INDEX.SCSS

const EditionCarousel: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { version: routeVersion } = useParams<{ version: string }>(); // Get the version from the URL

    const handleClick = (id: string) => {
        // Navigate to the detail page and scroll to the top

        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
        navigate(`/tendenz/${id}`);
    };

    /**
     * QUERY TENDENZ_EVENT
     */
    const { data, loading, error } = useQuery<TendenzEvents, TendenzEventsVariables>(TENDENZ_EVENTS, {
        variables: routeVersion ? { id: routeVersion, first: 10, page: 1 } : { first: 10, page: 1 },
        fetchPolicy: "network-only",
    });

    // if (history.editions.filter((v) => v.edition !== routeVersion).length === 0) {
    //     return null;
    // }

    if (loading || error) {
        return <Box py={4} px={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} />;
    }

    const mockData = data?.TendenzEvents.data ?? [];

    return (
        <Box py={4} px={4}>
            <div id="history" />
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Andere edities
            </Typography>
            <Swiper
                modules={[EffectCoverflow, Navigation, Pagination]}
                slidesPerGroup={1}
                centeredSlides={true}
                spaceBetween={10} // Space between slides
                pagination={{ clickable: true, type: "bullets", dynamicBullets: true }}
                navigation={true}
                breakpoints={{
                    640: {
                        slidesPerView: 1, // 1 slide per view for small screens
                    },
                    1024: {
                        slidesPerView: 2, // 2 slides per view for medium screens
                    },
                    1200: {
                        slidesPerView: 5, // 5 slides per view for larger screens
                    },
                    2500: {
                        slidesPerView: 8, // 8 slides per view for extra large screens
                    },
                }}
                style={{ padding: "32px 0px" }}
            >
                {mockData.map((edition: any, index: number) => (
                    <SwiperSlide key={index}>
                        <Card sx={{ maxWidth: 375, margin: "10px" }} onClick={() => handleClick(edition.id)}>
                            <CardActionArea sx={{ position: "relative" }}>
                                <div
                                    style={{
                                        position: "absolute",
                                        margin: "4px 0px 0px 4px",
                                        zIndex: 4,
                                    }}
                                >
                                    <Chip
                                        variant="filled"
                                        icon={<Today sx={{ color: "#fff !important", fontSize: "18px" }} />}
                                        label={moment(edition.start_date).format("LL")}
                                        sx={
                                            edition.type === "event"
                                                ? { zIndex: 4, backgroundColor: "#70a4b7", fontWeight: 600, color: "white" }
                                                : { zIndex: 4, backgroundColor: "#82b6a0", fontWeight: 600, color: "white" }
                                        }
                                    />
                                </div>
                                {edition.card_image ? (
                                    <CardMedia sx={{ height: 200 }} image={edition.card_image} title={edition.title} />
                                ) : (
                                    <div style={{ height: 200 }} />
                                )}
                                <CardContent>
                                    <Typography variant="h5" component="div" fontWeight={500} sx={{ minHeight: "48px" }}>
                                        {edition.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ whiteSpace: "pre-line", height: "100px", overflow: "hidden" }}
                                        dangerouslySetInnerHTML={{
                                            __html: edition.card_summary as string,
                                        }}
                                    />
                                </CardContent>
                                <CardActions sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography
                                        sx={
                                            edition.type === "event"
                                                ? { paddingLeft: "8px", fontWeight: 500, color: "#70a4b7" }
                                                : { paddingLeft: "8px", fontWeight: 500, color: "#82b6a0" }
                                        }
                                    >
                                        {edition.type}
                                    </Typography>

                                    <Button size="small" onClick={() => handleClick(edition.id)}>
                                        Bekijk
                                    </Button>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default EditionCarousel;
