import React from "react";
import { Avatar, AvatarGroup, Button, Tooltip } from "@mui/material";
import { ListItem, ListItemText, ListItemIcon, Typography, IconButton } from "@mui/material";

// Icons used on this page
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { GetUserSearchRules, GetUserSearchRules_currentUser_searches } from "../../../../__generated__/GetUserSearchRules";
import SearchLineSummarySentence from "../SearchLineSummarySentence";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";

interface Props {
    color: string;
    searchline: GetUserSearchRules_currentUser_searches;
    type: string;
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetUserSearchRules>>;
    handleOpenShare: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchLineSettingsOnlyShare: React.FC<Props> = ({ color, searchline, type, handleOpenShare }) => {
    const bgColor = "#f9f9f9";
    const green = "#225E4D";
    const blue = "#173357";

    return (
        // if type = tender => blue color, if type = opportunity => green color

        <ListItem
            style={{
                marginBottom: "5px",
                borderLeft: type === "tender" ? `5px solid ${blue}` : type === "opportunity" ? `5px solid ${green}` : undefined,
                backgroundColor: bgColor,
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <ListItemIcon
                    sx={{
                        minWidth: "40px",
                        opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                        display: "flex",
                        alignItems: "center",
                    }}
                    style={{ color: color }}
                >
                    {type === "tender" && searchline.owner?.id === localStorage.getItem("user_id") ? (
                        <IconButton style={{ marginRight: "8px", marginLeft: "-8px" }}>
                            <LocalOfferIcon sx={{ color: color }} />
                        </IconButton>
                    ) : (
                        <LocalOfferIcon sx={{ color: color }} />
                    )}
                </ListItemIcon>
                <ListItemText
                    sx={{
                        color: "#173357",
                        opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                    }}
                    /**
                     * primary = title of searchline
                     */
                    primary={
                        <div>
                            <Typography style={{ display: "flex", alignItems: "center", height: "21px" }}>{searchline.name}</Typography>
                        </div>
                    }
                    /**
                     * secondary = summary of searchline
                     * TODO, user can edit inline. add regions, add or remove searchterms.
                     * create react fragment to add more features
                     */
                    secondary={
                        <React.Fragment>
                            {type === "tender" && (
                                <SearchLineSummarySentence searchline={searchline} filterValues={searchline.filters_with_labels || null} />
                            )}
                        </React.Fragment>
                    }
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {searchline.state === "PENDING_ACCEPTANCE" && (
                    <Typography variant="button" sx={{ marginRight: "16px", fontSize: "12px", color: "#00000042" }}>
                        Nog niet geaccepteerd
                    </Typography>
                )}
                {/* Avatars => sharing results with those users */}
                {searchline.participants && (
                    <>
                        {searchline.participants.length === 1 && searchline.owner?.id === localStorage.getItem("user_id") ? (
                            // USER IS OWNER ADN THE ONLY PARTICIPANT
                            <Tooltip disableInteractive placement={"right-start"} title={<div>{searchline.owner?.employee.name}</div>}>
                                <AvatarGroup
                                    max={4}
                                    sx={{
                                        cursor: "pointer",
                                        marginRight: "8px",
                                        "& .MuiAvatar-root": {
                                            border: "1px solid #ffffff",
                                            width: (theme) => theme.spacing(3.5),
                                            height: (theme) => theme.spacing(3.5),
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    <Avatar sx={{ backgroundColor: "#173357" }}>
                                        {searchline.owner.employee.name?.slice(0, 1)}
                                        {searchline.owner.employee.familyname?.slice(0, 1)}
                                    </Avatar>
                                </AvatarGroup>
                            </Tooltip>
                        ) : (
                            <>
                                {searchline.participants.length > 0 ? (
                                    // MAP OVER PARTICIPANTS
                                    // BLUE AVATAR => OWNER
                                    // GREY => PARTICIPANTS
                                    <Tooltip
                                        disableInteractive
                                        placement={"right-start"}
                                        title={
                                            <>
                                                <span>Gemaakt door:</span>
                                                <br />
                                                <span>{searchline.owner?.employee.name}</span>
                                                <br />
                                                {searchline.participants.filter((p) => p?.employee.id !== searchline.owner?.employee.id).length >
                                                    0 && (
                                                    <div style={{ marginTop: "4px" }}>
                                                        <span>Gedeeld met:</span>
                                                    </div>
                                                )}
                                                {searchline.participants
                                                    .filter((p) => p?.employee.id !== searchline.owner?.employee.id)
                                                    .map((l, index) => {
                                                        return <div key={l?.id}>{l?.employee.name}</div>;
                                                    })}
                                            </>
                                        }
                                    >
                                        <AvatarGroup
                                            max={4}
                                            sx={{
                                                cursor: "pointer",
                                                marginRight: "8px",
                                                "& .MuiAvatar-root": {
                                                    border: "1px solid #ffffff",
                                                    width: (theme) => theme.spacing(3.5),
                                                    height: (theme) => theme.spacing(3.5),
                                                    fontSize: "0.8rem",
                                                },
                                            }}
                                        >
                                            <Avatar sx={{ backgroundColor: "#173357" }}>
                                                {searchline.owner?.employee?.name?.slice(0, 1)}
                                                {searchline.owner?.employee?.familyname?.slice(0, 1)}
                                            </Avatar>
                                            {/* Map avatar of users that liked tender */}
                                            {searchline.participants
                                                .filter((p) => p?.employee.id !== searchline.owner?.employee.id)
                                                .map((l, index) => {
                                                    return (
                                                        <Avatar key={l?.id}>
                                                            {l?.employee?.name?.slice(0, 1)}
                                                            {l?.employee?.familyname?.slice(0, 1)}
                                                        </Avatar>
                                                    );
                                                })}
                                        </AvatarGroup>
                                    </Tooltip>
                                ) : (
                                    <Tooltip disableInteractive placement={"right-start"} title={<div>{searchline.owner?.employee.name}</div>}>
                                        <AvatarGroup
                                            max={4}
                                            sx={{
                                                cursor: "pointer",
                                                marginRight: "8px",
                                                "& .MuiAvatar-root": {
                                                    border: "1px solid #ffffff",
                                                    width: (theme) => theme.spacing(3.5),
                                                    height: (theme) => theme.spacing(3.5),
                                                    fontSize: "0.8rem",
                                                },
                                            }}
                                        >
                                            <Avatar sx={{ backgroundColor: "#173357" }}>
                                                {searchline.owner?.employee?.name?.slice(0, 1)}
                                                {searchline.owner?.employee?.familyname?.slice(0, 1)}
                                            </Avatar>
                                        </AvatarGroup>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </>
                )}

                {/* On smaller devices -> all actions in submenu. */}
                {/* TODO: change searchline.id to state or percentage*/}
                <React.Fragment>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handleOpenShare}>Delen</Button>
                    </div>
                </React.Fragment>
            </div>
        </ListItem>
    );
};

export default SearchLineSettingsOnlyShare;
