import React, { useState } from "react";
import moment from "moment";
// Material UI
import { Disable } from "react-disable";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
// Components
import { DefaultSummary } from "../../routes/tenders/TenderDetailContainer";
import TndrRelatedTendersWidget from "./widgets/TndrRElatedTendersWidget";
import AwardInformationWidget from "./widgets/AwardInformationWidget";
import TenderContractWidget from "./widgets/TenderContractWidget";
import TenderScopeWidget from "./widgets/TenderScopeWidget";
import Documents, { DocProps } from "./widgets/Documents";
import SummaryWidget from "../tenderDetail/SummaryWidget";
import LabelWidget from "../LabelWidget/LabelWidget";
import TenderTitle from "./widgets/TenderTitle";
import CaWidgetBox from "./widgets/CaWidgetBox";
import TenderTopBar from "./TenderTopBar";
import WidgetBox from "../WidgetBox";

// GQL import
import { GET_CAS_FOR_OPPORTUNITY_DOCS } from "../../graphql/queryDefinitions";
import { GET_HIGHLIGHTED_SUMMARY } from "../../graphql/queryDefSearchAndHighlighting";
import { GetDefaultSummary_tender } from "../../__generated__/GetDefaultSummary";
import { highlightedSummary, highlightedSummaryVariables } from "../../__generated__/highlightedSummary";
import { useQuery } from "@apollo/client";
import TooltipOnSelect from "../TooltipOnSelect";
import SearchWizardContainer from "../../routes/profile/Dialog/SearchWizardContainer";
import { useMediaQuery, useTheme } from "@mui/material";
import HighlightInTenderDetail from "./HighlightInTenderDetail";
import { useReRunHighlighting } from "../contextProviders/HighlightContext";
import {
    GetCasForOpportunityDocs,
    GetCasForOpportunityDocsVariables,
    GetCasForOpportunityDocs_tender_clients,
} from "../../__generated__/GetCasForOpportunityDocs";
import OpportunityOnTenderDetailWrapper from "../opportunitywidget/OpportunityOnTenderDetailWrapper";
import ScheduleTimelineWrapper from "../ScheduleTimelineWrapper";

interface Props {
    defaultSummary: DefaultSummary;
    data: GetDefaultSummary_tender;
    tender_id: string;
}
const TenderDetail: React.FC<Props> = ({ data, defaultSummary, tender_id }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [openPopup, setOpenPopup] = useState<string | false>(false);
    const [highlighting, setHighlighting] = useState<DefaultSummary>();
    const documentDateCountCopy = [...data.documentDateCount] as DocProps[];
    const sortedDocumentDateCount = documentDateCountCopy.sort((a, b) => (moment(b.date).isBefore(moment(a.date)) ? -1 : 1));
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [cas, setCAS] = useState<GetCasForOpportunityDocs_tender_clients[]>();
    const { setRerunHighlight } = useReRunHighlighting();

    const { data: cadata } = useQuery<GetCasForOpportunityDocs, GetCasForOpportunityDocsVariables>(GET_CAS_FOR_OPPORTUNITY_DOCS, {
        variables: { id: tender_id },
        onCompleted: () => {
            if (cadata && cadata.tender && cadata.tender.clients) {
                setCAS(cadata.tender.clients);
            }
        },
    });

    /**
     * state to handle true/false for title modal
     */
    const [open, setOpen] = useState(false);

    /**
     * Highlighted summary query
     */
    useQuery<highlightedSummary, highlightedSummaryVariables>(GET_HIGHLIGHTED_SUMMARY, {
        variables: {
            id: tender_id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.highlightedTender) {
                setHighlighting({
                    id: tender_id,
                    titel: data.highlightedTender.namegiventocontract || null,
                    omschrijving: data.highlightedTender.omschrijving || null,
                    omvang: data.highlightedTender.omvang || null,
                });
            }
        },
    });

    return (
        <TooltipOnSelect>
            <HighlightInTenderDetail tender_id={tender_id} backup={data.searchresults}>
                <div>
                    <Grid container spacing={3}>
                        {/* TOPBAR  */}
                        <Grid item md={12} container justifyContent="space-between" alignItems="center">
                            <TenderTopBar
                                tender_id={tender_id}
                                tender_title={defaultSummary.titel}
                                status={data.status}
                                searches={data.searchresults}
                                onOpenSearchRuleWizard={() => setOpenPopup("tender")}
                            />
                        </Grid>

                        {/*
                         * START GRID WITH WIDGETS
                         * Title and relevant search information of the tender
                         */}
                        <Grid item md={12}>
                            <TenderTitle
                                title={
                                    highlighting !== undefined && highlighting.titel !== null
                                        ? highlighting.titel
                                        : defaultSummary.titel !== null
                                        ? defaultSummary.titel
                                        : ""
                                }
                                normal_title={data.namegiventocontract || data.published_namegiventocontract}
                                rawPublications={data.rawPublications}
                                onBtnClickOpen={() => {
                                    setOpen(true);
                                    setRerunHighlight(true);
                                }}
                                onBtnClickClose={() => {
                                    setOpen(false);
                                    setRerunHighlight(true);
                                }}
                                open={open}
                            />
                        </Grid>

                        {/*
                         * Summary widget: description, qualitativeScope and meta-data
                         */}
                        <Grid item md={12} style={{ paddingBottom: 0 }}>
                            <SummaryWidget
                                elevation={1}
                                id={tender_id}
                                omschrijving_source={data.omschrijving_source}
                                omvang_opdracht_source={data.omvang_opdracht_source}
                                description={
                                    highlighting !== undefined && highlighting.omschrijving !== null
                                        ? highlighting.omschrijving
                                        : defaultSummary.omschrijving !== null
                                        ? defaultSummary.omschrijving
                                        : ""
                                }
                                qualitativeScope={
                                    highlighting !== undefined && highlighting.omvang !== null
                                        ? highlighting.omvang
                                        : defaultSummary.omvang !== null
                                        ? defaultSummary.omvang
                                        : ""
                                }
                                subSectors={data.sectors}
                                host={data.published_host}
                                hostUrl={data.original_link}
                                deadline={data.deadline_inschrijving}
                                lastUpdate={data.updated_at}
                                firstDispatch={data.first_dispatchdate || ""}
                                awardCrit={data.gunningscriteria}
                                procedureType={data.type_procedure}
                                legalFramework={data.published_juridisch_kader}
                                legalBase={data.published_legal_basis}
                                type={data.translated_type}
                                tender_country={data.country?.name ? data.country.name : null}
                                lots={data.lots}
                            />
                        </Grid>

                        {/*
                         * LEFT column for the widgets
                         */}
                        <Grid item md={6} xs={12}>
                            {/*
                             * Contracting authority with primary and secundairy CA's
                             * If clients.length is over 0, show contracting authority
                             * If not show CABoxPublished component
                             */}
                            <CaWidgetBox tender_id={tender_id} />

                            {/*
                             * Show award information if state is 'AWARDED'
                             * Show correct component based on country
                             */}
                            <AwardInformationWidget tender_id={tender_id} />

                            {/*
                             * Contract information widget
                             * Show component when tender.contract is not 'null'
                             */}
                            <TenderContractWidget tender_id={tender_id} />
                            {/*
                             * Widget with scheduled items
                             * Show tender planning in timeline component when tender.events > 0
                             */}
                            <ScheduleTimelineWrapper tender_id={tender_id} host={data.published_host} hostUrl={data.original_link} />

                            {/*
                             * Widget to show opportunity documents of CA's
                             */}
                            {cas && cas.length > 0 ? (
                                <OpportunityOnTenderDetailWrapper caIDS={cas || []} />
                            ) : (
                                <Disable disabled>
                                    <WidgetBox loading={false} header={"Kansen"} highlight="green" padding={0} />
                                </Disable>
                            )}
                        </Grid>

                        {/* 
                /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////    RIGHT COLUMN    ///////////////////////////////////////////////////////
                /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                */}
                        <Grid item md={6} xs={12}>
                            {/*
                             * TODO: Connect labelsearch to GQL
                             */}
                            <LabelWidget tender_id={tender_id} />

                            {/*
                             * Document widget
                             * TODO: Connect documenttree to GQL
                             */}
                            {data.documentDateCount.length > 0 ? (
                                <Documents tender_id={tender_id} documentDateArray={sortedDocumentDateCount} tenderState={data.status} />
                            ) : (
                                <Disable disabled={true}>
                                    <WidgetBox header={t("tenderPage.Documents")} highlight="blue" padding={0} />
                                </Disable>
                            )}

                            {/*
                             * Scope widget
                             * Show scopewidget when tender.scope is not 'null'
                             */}
                            <TenderScopeWidget tender_id={tender_id} />

                            {/*
                             * Tenders related to tender
                             * show related tenders when related tenders > 0
                             */}
                            <TndrRelatedTendersWidget tender_id={tender_id} />

                            {/*
                             * Widget that shows all messages for tender
                             * Fallback is in messageboxwidget itself
                             */}
                            {/* <MessageBoxWidget
                        messages={tender.external_portal_messages}
                        remarkAward={tender.remark_award}
                        remarkAwardDate={tender.awardPublications.length > 0 ? tender.awardPublications[0].dispatchdate : null}
                        comment={tender.opmerking}
                        commentDate={tender.bids !== null && tender.bids.length > 0 ? tender.bids[0].created_at : null}
                        explanation={tender.displayPublications}
                    /> */}
                        </Grid>
                    </Grid>
                    {noMobile && <SearchWizardContainer openPopup={openPopup} setOpenPopup={setOpenPopup} editRule={null} />}
                </div>
            </HighlightInTenderDetail>
        </TooltipOnSelect>
    );
};

export default TenderDetail;
