import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem, MenuList, Popover, Typography } from "@mui/material";

interface Props {
    label: string;
    onDownload: (e: any) => void;
    text: string;
    downloadable: boolean;
}

const DateDownloadBtn: React.FC<Props> = ({ label, onDownload, text, downloadable }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        onDownload(e);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    // TODO: Add download function to onclose function. menu item
    return (
        <React.Fragment>
            <Typography
                color="textPrimary"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingLeft: "4px",
                    marginLeft: "-4px",
                    fontSize: "0.785rem",
                    marginBottom: "-1px",
                    "&:hover": {
                        backgroundColor: "#ececec",
                        borderRadius: "4px",
                    },
                }}
                aria-describedby={id}
                onClick={(e) => {
                    if (downloadable) {
                        e.stopPropagation();
                        handleClick(e);
                    }
                }}
            >
                {label} <ArrowDropDownIcon sx={{ color: "#9e9e9e" }} />
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    handleClose(e);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList dense autoFocusItem={open} style={{ padding: 0 }}>
                    <MenuItem onClick={handleClose}>{text}</MenuItem>
                </MenuList>
            </Popover>
        </React.Fragment>
    );
};

export default DateDownloadBtn;
