import React, { useState } from "react";
import { Dialog, AppBar, Toolbar, IconButton, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import TenderWizardBasePlate from "./TenderWizardBasePlate";
import { useTranslation } from "react-i18next";
import WarningModal from "./WarningModal";
import OpportunityBasePlate from "./opportunityWizard/OpportunityWizardBasePlate";
import TenderTitleToShow from "./TenderTitleToShow";
import WordModalGuide from "../../../components/guides/tenderWizard/WordModalGuide";
import DomainModalGuide from "../../../components/guides/tenderWizard/DomainModalGuide";
import { useQuery } from "@apollo/client";
import { GetAmountOfUserSearchRules } from "../../../__generated__/GetAmountOfUserSearchRules";
import ResultModalGuide from "../../../components/guides/tenderWizard/ResultModalGuide";
import DistModalGuide from "../../../components/guides/tenderWizard/DistModalGuide";
import OpportunityTitleToShow from "./OppTitleToShow";
import OppResultModalGuide from "../../../components/guides/opportunityWizard/OppResultModalGuide";
import OppDistModalGuide from "../../../components/guides/opportunityWizard/OppDistModalGuide";
import DistModalGuideInformative from "../../../components/guides/tenderWizard/DistModalGuideInformative";
import { QUERY_AMOUNT_OF_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";
import { useQueryBuilder } from "./QueryBuilder/QueryBuilderContext";
import { useCustomOptions } from "../../../components/contextProviders/TenderCustomOptionProvider";
import { useLocation } from "react-router-dom";
import { useCustomOppOptions } from "../../../components/contextProviders/OppCustomOptionProvider";

interface Props {
    openPopup: string | false;
    setOpenPopup: any;
    editRule: any;
    setEditRule?: (item: any) => void;
    resetFilters?: () => void;
}

const SearchWizardContainer: React.FC<Props> = ({ openPopup, setOpenPopup, editRule, setEditRule, resetFilters }) => {
    const { t } = useTranslation();
    const { reset } = useTenderSearchContext(); // Use the context hook to access context value and function
    const { pathname } = useLocation();
    const [warning, setWarning] = useState(false);
    // state for active step
    const [activeStep, setActiveStep] = useState(0);
    const [amountOfRules, setAmountOfRules] = useState<number | boolean>(false);
    const { resetAdvancedQuery } = useQueryBuilder();

    const { setCustomOptionsWorklist, setCustomOptionsReviews, setCustomOptionsAll } = useCustomOptions();
    const { setCustomOppOptionsWorklist, setCustomOppOptionsReviews, setCustomOppOptionsAll } = useCustomOppOptions();

    /**
     * Reset handler to remove session storage key with data
     * CA filter +
     * all other filters
     */
    const handleResetCustomFilter = () => {
        // tender objects
        if (pathname === "/tenders/worklist") {
            setCustomOptionsWorklist([]);
            sessionStorage.removeItem("cow");
            sessionStorage.removeItem("twf");
        }
        if (pathname === "/tenders/reviews") {
            setCustomOptionsReviews([]);
            sessionStorage.removeItem("cor");
            sessionStorage.removeItem("rtf");
        }
        if (pathname === "/tenders") {
            setCustomOptionsAll([]);
            sessionStorage.removeItem("coa");
            sessionStorage.removeItem("atf");
        }
        // opportunity objects
        if (pathname === "/opportunities/worklist") {
            setCustomOppOptionsWorklist([]);
            sessionStorage.removeItem("coow");
            sessionStorage.removeItem("wof");
        }
        if (pathname === "/opportunities/reviews") {
            setCustomOppOptionsReviews([]);
            sessionStorage.removeItem("coor");
            sessionStorage.removeItem("rof");
        }
        if (pathname === "/opportunities") {
            setCustomOppOptionsAll([]);
            sessionStorage.removeItem("cooa");
            sessionStorage.removeItem("aof");
        }
    };

    // Tender wizard steps
    const tenderSteps = [
        { id: 1, title: "Zoekwoorden opstellen" },
        // { id: 2, title: "Gebied kiezen" },
        { id: 3, title: "Zoekdomein kiezen" },
        { id: 4, title: "Resultaten verfijnen" },
        { id: 5, title: "Distributie" },
    ];

    // Opportunity wizard steps
    const oppSteps = [
        { id: 1, title: "Zoekwoorden opstellen" },
        { id: 3, title: "Resultaten verfijnen" },
        { id: 4, title: "Distributie" },
    ];

    /**
     * Handler to close the modal
     */
    const handleClose = () => {
        setWarning(false);
        setOpenPopup(false);
        setEditRule && setEditRule(null);
        setActiveStep(0);
        reset();
        resetAdvancedQuery();
        handleResetCustomFilter();
        resetFilters && resetFilters();
    };

    const handleWarning = () => {
        setWarning(true);
    };

    /**
     * Define the GQL query
     * Fetch amount of searchrules (tender + opportunity)
     */
    useQuery<GetAmountOfUserSearchRules>(QUERY_AMOUNT_OF_SEARCHRULES, {
        skip: amountOfRules !== false,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.currentUser) {
                const amount = (data.currentUser.opportunitySearches?.length || 0) + (data?.currentUser.searches.length || 0);
                setAmountOfRules(amount);
            } else {
                setAmountOfRules(true);
            }
        },
    });

    /**
     * Fullscreen dialog with a step-by-step guide to create a searchline with active filters etc.
     */
    return (
        <>
            <Dialog fullScreen open={typeof openPopup === "string" ? true : false} onClose={handleClose} disableEscapeKeyDown>
                <AppBar
                    sx={{
                        position: "relative",
                        background: "#ffffff",
                        color: "#000000",
                    }}
                >
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleWarning}>
                            <CloseIcon />
                        </IconButton>
                        {openPopup === "tender" && (
                            <TenderTitleToShow
                                step={tenderSteps[activeStep]}
                                InteractiveHelper={
                                    <>
                                        {editRule === null && (
                                            <>
                                                {activeStep === 0 ? ( // editRule has to be null
                                                    <WordModalGuide
                                                        hasSearchrules={amountOfRules === 0 && editRule === null ? true : false}
                                                        type={"tender"}
                                                    />
                                                ) : //? change all activesteps to avoid errors or wrong guides
                                                // : activeStep === 1 ? (
                                                //     <></>
                                                // )
                                                activeStep === 1 ? (
                                                    <DomainModalGuide hasSearchrules={amountOfRules === 0 ? true : false} />
                                                ) : activeStep === 2 ? (
                                                    <ResultModalGuide hasSearchrules={amountOfRules === 0 ? true : false} />
                                                ) : activeStep === 3 ? (
                                                    <>
                                                        {editRule === null ? (
                                                            <DistModalGuide hasSearchrules={amountOfRules === 0 ? true : false} />
                                                        ) : (
                                                            <DistModalGuideInformative
                                                                hasSearchrules={amountOfRules === 0 ? true : false}
                                                                dist={editRule.distribution}
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </>
                                }
                            />
                        )}
                        {openPopup === "opportunity" && (
                            <OpportunityTitleToShow
                                step={oppSteps[activeStep]}
                                InteractiveHelper={
                                    editRule === null && (
                                        <>
                                            {activeStep === 0 ? (
                                                <WordModalGuide
                                                    hasSearchrules={amountOfRules === 0 && editRule === null ? true : false}
                                                    type={"opportunity"}
                                                />
                                            ) : activeStep === 1 ? (
                                                <OppResultModalGuide hasSearchrules={amountOfRules === 0 ? true : false} />
                                            ) : activeStep === 2 ? (
                                                <OppDistModalGuide hasSearchrules={amountOfRules === 0 ? true : false} />
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )
                                }
                            />
                        )}
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: "#f4f4f4" }}>
                    {/* Wizard to create a tender searchrule */}
                    {openPopup === "tender" && (
                        <TenderWizardBasePlate
                            onClose={handleClose}
                            editRule={editRule}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            stepTitles={tenderSteps.map((step) => step.title)}
                        />
                    )}
                    {/* Wizard to create a opportunity searchrule */}
                    {openPopup === "opportunity" && (
                        <OpportunityBasePlate onClose={handleClose} editRule={editRule} activeStep={activeStep} setActiveStep={setActiveStep} />
                    )}
                </DialogContent>
            </Dialog>
            <WarningModal
                open={warning}
                onCancel={() => setWarning(false)}
                onExit={handleClose}
                helpText={"De instellingen worden niet opgeslagen als het venster wordt gesloten!"}
                closeText={t("Close_modal")}
                cancelText={t("Cancel")}
            />
        </>
    );
};

export default SearchWizardContainer;
