import React, { useState } from "react";
import { Card, CardContent, Typography, Menu, MenuItem, IconButton, Avatar } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

//icons used in this component
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfoTooltip from "./InfoTooltip";
import VCard from "vcard-creator";
import OverflowTip from "./OverflowTip";

interface Props {
    showLabel: boolean;
    social?: string;
    phone?: string;
    mobile?: string;
    full_name: string;
    first_name: string;
    last_name: string;
    role?: string;
    mail?: string;
    middle_name?: string;
    organization?: string;
    cardVariant: "outlined" | "elevation" | undefined;
    cardElevation: number;
    nameStyle: "inherit" | Variant | undefined;
    showmenu?: boolean;
    img?: string;
    logo?: string;
}

const Vcard: React.FC<Props> = ({
    showLabel,
    full_name,
    first_name,
    last_name,
    social,
    phone,
    mobile,
    role,
    mail,
    cardElevation,
    cardVariant,
    nameStyle,
    middle_name,
    organization,
    showmenu = true,
    img,
    logo,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const blue = "#465c84";
    const lightgrey = "#9e9e9e";
    const myVCard = new VCard();
    /**
     * Open menu
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    /**
     * Close menu
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Function to download individual vCard
     * @param vcfText vcard
     */
    const downloadTxtFile = (vcfText: any) => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/plain; charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = `${first_name}.vcf`;
        document.body.appendChild(element);
        element.click();
        handleClose();
    };

    const lastname = last_name;
    const firstname = first_name;
    const additional = middle_name;
    const prefix = "";
    const suffix = "";
    const org = organization as string;
    const roleFunction = role as string;
    const email = mail as string;
    const mob = mobile ? (mobile as string) : "";
    const phoning = phone ? (phone as string) : "";
    const linkedin = social as string;

    const CreateVCard = () => {
        myVCard
            // Add personal data
            .addName(lastname, firstname, additional, prefix, suffix)
            // Add work data
            .addCompany(org)
            .addJobtitle(roleFunction)
            // .addRole("Data Protection Officer")
            .addEmail(email)
            .addPhoneNumber(mob, "PREF;WORK")
            .addPhoneNumber(phoning, "WORK")
            .addSocial(linkedin, "Linkedin", firstname);

        const vCardString = myVCard.toString();
        /**
         * Bug in vCard
         * temporary fix to show linkedin url instead of CHARSET=UTF-8 in vCard download
         */
        const vCardreplacement = vCardString.replace(/SOCIALPROFILE;CHARSET=UTF-8;/gm, "SOCIALPROFILE;");

        return vCardreplacement;
    };

    /**
     * Copy function
     */
    const handleCopy = () => {
        const firstName = first_name !== "" && `Naam: ${first_name}\n`;
        const middleName = middle_name !== "" && `Tussenvoegsel: ${middle_name}\n`;
        const lastName = last_name !== "" && `Achternaam: ${last_name}\n`;
        const org = organization !== "" && `Organisatie: ${organization}\n`;
        const email = mail !== "" && `E-mail: ${mail}\n`;
        const workPhone = phone !== "" && `Telefoon: ${phone}\n`;
        const cellPhone = mobile !== "" && `Mobiel: ${mobile}\n`;
        const title = role !== "" && `Functie: ${role}\n`;
        const linkedin = social !== "" && `Linkedin: ${social}`;
        const text = ("" + firstName + middleName + lastName + email + org + workPhone + cellPhone + title + linkedin).replaceAll("false", "");
        navigator.clipboard.writeText(text);
        handleClose();
    };

    return (
        <React.Fragment>
            <Card sx={{ maxWidth: "500px", height: "223px" }} variant={cardVariant} elevation={cardElevation}>
                <CardContent style={{ display: "flex", flexDirection: "column", paddingBottom: 16 }}>
                    {/* Givenname + middlename + familyname */}
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: 8, justifyContent: "space-between", height: 70 }}>
                        <div style={{ maxWidth: "250px", marginRight: 16, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {img !== "" ? (
                                <Avatar src={img} style={{ marginRight: 8 }} />
                            ) : first_name === "" && last_name === "" ? (
                                <Avatar style={{ marginRight: 8 }} />
                            ) : (
                                <Avatar style={{ marginRight: 8 }}>{`${first_name.charAt(0).toUpperCase()}${last_name
                                    .charAt(0)
                                    .toUpperCase()}`}</Avatar>
                            )}
                            <div style={{ width: "95%" }}>
                                {/* Name */}
                                <Typography noWrap variant={nameStyle}>
                                    {full_name}
                                </Typography>

                                {/* function */}

                                {role && <OverflowTip tooltip={role} text={role} />}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                            {/* Optional: searchline label, can be set to true or false to display or not. */}
                            {/* {showLabel === true ? (
                                <InfoTooltip
                                    placement="bottom"
                                    title={"Match met zoekregel:"}
                                    content={
                                        <React.Fragment>
                                            <LocalOfferIcon style={{ marginRight: 8, color: "green" }} /> Zoekregel 1
                                            <LocalOfferIcon style={{ marginRight: 8, color: "blue" }} /> Zoekregel 2
                                        </React.Fragment>
                                    }
                                >
                                    <LocalOfferIcon style={{ marginRight: 8 }} />
                                </InfoTooltip>
                            ) : (
                                <></>
                            )} */}

                            {/* small menu to copy or download data, with last updated state */}
                            {logo && <img src={logo} alt="Logo" style={{ height: 50 }} />}

                            {showmenu === true && (
                                <IconButton size="small" onClick={handleClick} sx={{ alignSelf: "flex-start" }}>
                                    <MoreVertIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>

                    {/*  email */}
                    {mail && (
                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 8 }}>
                            <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%" }}>
                                <EmailIcon fontSize="small" sx={{ color: lightgrey }} />
                                <InfoTooltip placement="top" content={mail}>
                                    <a href={`mailto: ${mail}`} style={{ color: blue, marginLeft: "16px", paddingRight: "16px", width: "100%" }}>
                                        <Typography noWrap style={{ marginRight: 16 }}>
                                            {mail}
                                        </Typography>
                                    </a>
                                </InfoTooltip>
                            </div>
                        </div>
                    )}

                    {/*  cellphone */}
                    {mobile && (
                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 8 }}>
                            <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%" }}>
                                <PhoneIphoneIcon fontSize="small" sx={{ color: lightgrey }} />
                                <a href={`tel: ${mobile}`} style={{ color: blue, marginLeft: "16px", paddingRight: "16px", width: "100%" }}>
                                    <Typography noWrap style={{ marginRight: 16 }}>
                                        {mobile}
                                    </Typography>
                                </a>
                            </div>
                        </div>
                    )}

                    {/*  phone */}
                    {phone && (
                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 8 }}>
                            <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%" }}>
                                <PhoneIcon fontSize="small" sx={{ color: lightgrey }} />
                                <a href={`tel: ${phone}`} style={{ color: blue, marginLeft: "16px", paddingRight: "16px", width: "100%" }}>
                                    <Typography noWrap style={{ marginRight: 16 }}>
                                        {phone}
                                    </Typography>
                                </a>
                            </div>
                        </div>
                    )}

                    {/*  Linkedin */}
                    {social && (
                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 8 }}>
                            <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%" }}>
                                <LinkedInIcon fontSize="small" sx={{ color: lightgrey }} />
                                <a
                                    href={social}
                                    target="_blank"
                                    style={{ color: blue, marginLeft: "16px", paddingRight: "16px", width: "100%" }}
                                    rel="noreferrer"
                                >
                                    <Typography noWrap style={{ marginRight: 16 }}>
                                        {social}
                                    </Typography>
                                </a>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleCopy()}>Kopieer gegevens</MenuItem>
                <MenuItem onClick={() => downloadTxtFile(CreateVCard())}>Download vCard</MenuItem>
                {/* <Divider variant="middle" />
                <div style={{ margin: "6px 16px 0px 16px" }}>
                    <Typography sx={{        color: "#707070",
        width: 200,}}>laatst gevonden op:</Typography>
                    <Typography sx={{        color: "#707070",
        width: 200,}}>26-09-2021</Typography>
                </div> */}
            </Menu>
        </React.Fragment>
    );
};

export default Vcard;
