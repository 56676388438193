import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/tenderguide.svg";
import setTitle from "../utils/setTitle";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/login/LanguageSelector";
import TextField from "@mui/material/TextField";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GetCurrentUser } from "../__generated__/GetCurrentUser";
import { updateProfileSettings, updateProfileSettingsVariables } from "../__generated__/updateProfileSettings";
import { UPDATEPERSONALSETTINGS } from "../graphql/mutationDefinitions";
import { QUERY_CURRENT_USER_SETTINGS } from "../graphql/queryDefCurrentUser";
import TwoFactorModal from "./login/TwoFactorModal";
import { login2FA, login2FAVariables } from "../__generated__/login2FA";
import { Card, CardContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface Props {
    a?: number;
}

type LocationState = {
    email: string;
    from: {
        pathname: string;
        search: string;
        hash: string;
    };
};

const Login: React.FC<Props> = (props) => {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { from } = (location.state as LocationState) || { from: { pathname: "/" } };
    const [open2fa, setOpen2fa] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [login, { loading }] = useMutation<login2FA, login2FAVariables>(LOGIN_TG);
    const [getCurrentUser] = useLazyQuery<GetCurrentUser>(GET_CURRENT_USER);
    const [defaultMode, setDefaultMode] = useState<string | null>(null);

    const [modes, setModes] = useState([
        { mode: "totp", active: false },
        { mode: "mail", active: false },
    ]);

    const navigate = useNavigate();
    const { t } = useTranslation();

    // Set title of the page
    setTitle("Login");

    // If requested, extract the redirect url to redirect the user after login
    const redirectObject = (location.state as LocationState) && from;
    const redirectUrl = redirectObject ? `${redirectObject.pathname}${redirectObject.search}${redirectObject.hash}` : undefined;
    const [save] = useMutation<updateProfileSettings, updateProfileSettingsVariables>(UPDATEPERSONALSETTINGS);

    const handleLogin = async () => {
        try {
            const response = await login({
                variables: { email, password },
            });

            const data = response.data?.loginTG;

            setModes([
                { mode: "totp", active: Boolean(data?.two_factor_totp) },
                { mode: "mail", active: Boolean(data?.two_factor_mail) },
            ]);
            if (data?.two_factor_default_method) {
                setDefaultMode(data.two_factor_default_method.toLocaleLowerCase());
                setOpen2fa(true);
            } else if (data?.success && data.token) {
                // Handle successful login (e.g., store the token, redirect)
                localStorage.setItem("access_token", data.token);
                fetchDataOnSucces();
            } else {
                const err =
                    data?.message === "The provided credentials are incorrect."
                        ? t("auth.error-login-check-credentials")
                        : t(data?.message as string);
                setError(err);
            }
        } catch (e) {
            setError(t("auth.error-login-check-credentials"));
            localStorage.removeItem("acces_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("initials");
        }
    };

    const fetchDataOnSucces = async () => {
        try {
            const response = await getCurrentUser();

            localStorage.setItem("user_id", response.data?.currentUser.id || "undefined");

            localStorage.setItem("i18nextLng", "nl");
            // Check if the user has a language set, if not, set it to Dutch (nl)
            if (response.data?.currentUser.language !== "nl") {
                save({
                    variables: {
                        language: "nl",
                        mobile: response.data?.currentUser.employee.cellphone ? response.data?.currentUser.employee.cellphone : "",
                        phone: response.data?.currentUser.employee.phonenumber ? response.data?.currentUser.employee.phonenumber : "",
                        function: response.data?.currentUser.employee.function ? response.data?.currentUser.employee.function : "",
                    },
                    refetchQueries: [
                        QUERY_CURRENT_USER_SETTINGS, // DocumentNode object parsed with gql
                        "GetCurrentUserSettings", // Query name
                    ],
                });
                navigate(redirectUrl || "/dashboard");
            } else {
                navigate(redirectUrl || "/dashboard");
            }
        } catch {}
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loading) return;
        if (!email || !password) {
            setError(t("auth.error-login-no-input"));
            return;
        }
        handleLogin();
    };

    return (
        <>
            <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Card style={{ width: isMobile ? "90%" : "600px", maxWidth: "100%", position: "relative", padding: isMobile ? "0px" : "0px 32px" }}>
                    <LanguageSelector top={16} left={16} />
                    <CardContent>
                        <Logo style={{ marginTop: isMobile ? 32 : 32, marginBottom: isMobile ? 8 : 0 }} />
                        <form onSubmit={onSubmit}>
                            <div style={{ marginBottom: 16 }}>
                                <TextField
                                    type="email"
                                    label={t("auth.email")}
                                    placeholder={t("auth.email")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoFocus
                                    fullWidth
                                    variant="outlined"
                                    disabled={loading}
                                    autoComplete="username"
                                />
                            </div>
                            <div style={{ marginBottom: 16 }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <Link to={{ pathname: "/forgot-password" }}>{t("auth.forgot-password.title")}</Link>
                                </div>
                                <TextField
                                    type="password"
                                    label={t("auth.password")}
                                    placeholder={t("auth.password")}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    disabled={loading}
                                    autoComplete="current-password"
                                    helperText={
                                        //   If anything went wrong during the login procedure, error will be displayed here
                                        <div style={{ visibility: error ? "visible" : "hidden" }}>
                                            <Typography variant="caption" color="error.main">
                                                {error || "ERROR_PLACEHOLDER"}
                                            </Typography>
                                        </div>
                                    }
                                />
                            </div>

                            {/*
                             * bottom div of login form
                             * with link to request a demo and a logn button to submit login
                             */}
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <LoadingButton loading={loading} variant="contained" color="primary" type="submit" size="large" disabled={loading}>
                                    {loading ? t("auth.loading") : t("auth.login-button-label")}
                                </LoadingButton>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
            <TwoFactorModal
                modes={modes}
                defaultMethod={defaultMode}
                open={open2fa}
                setOpen={setOpen2fa}
                onSuccess={fetchDataOnSucces}
                email={email}
                password={password}
            />
        </>
    );
};

export default Login;

export const LOGIN_TG = gql`
    mutation login2FA($email: String!, $password: String!, $code: String) {
        loginTG(input: { email: $email, password: $password, code: $code }) {
            token
            two_factor_totp
            two_factor_mail
            two_factor_default_method
            message
            success
        }
    }
`;

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            language
            employee {
                id
                function
                cellphone
                phonenumber
            }
        }
    }
`;
