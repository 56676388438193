import React, { useState, useRef, useCallback } from "react";
import { Divider, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
// import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { debounce } from "lodash";
import { Clear } from "@mui/icons-material";

interface Props {
    query: string;
    onChangeQuery(query: string): void;
    onSearch(): void;
}
// TODO: Target changes to summary on query update (if another target is chosen)
const OrgSearchBar: React.FC<Props> = ({ query, onChangeQuery, onSearch }) => {
    const inputRef = useRef();
    // const { t } = useTranslation();
    const theme = useTheme();
    const grey = "#707070";
    // state to show user input in searchfield
    const [userValue, setUserValue] = useState("");
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // debounce function to prevent request if characters is < 3 except 0
    const debounced = useRef(debounce((value) => (value.length === 0 ? onChangeQuery(value) : value.length >= 3 && onChangeQuery(value)), 1100));

    const updateUserValue = useCallback(({ target: { value } }) => {
        setUserValue(value);
        debounced.current(value);
    }, []);

    return (
        <div
            style={{
                width: onlyMobile ? "95vw" : "480px",
                maxWidth: "100% !important",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/*
                 * Searchbox to type query
                 * onSearch -> start search with typed query
                 */}
                <Paper
                    square
                    component="form"
                    sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSearch();
                    }}
                >
                    <SearchIcon style={{ margin: "10px 0px 10px 6px", color: grey }} />
                    <InputBase
                        fullWidth
                        ref={inputRef}
                        sx={{
                            input: {
                                marginLeft: theme.spacing(1),
                                flex: 1,
                            },
                        }}
                        placeholder="Zoeken"
                        autoFocus={onlyMobile}
                        value={userValue}
                        onChange={updateUserValue}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    {/*
                     * RESET BUTTON
                     */}
                    <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                        <IconButton
                            disabled={userValue === ""}
                            color="primary"
                            size="small"
                            aria-label="directions"
                            onClick={(e) => {
                                e.stopPropagation();
                                setUserValue("");
                                onChangeQuery("");
                                onSearch();
                            }}
                        >
                            <Clear sx={{ color: userValue === "" ? "#b3b3b3" : "error.main" }} />
                        </IconButton>
                    </Tooltip>
                </Paper>
            </div>
            {/*
             * Div with 4 px whitespace
             */}
            <div style={{ height: 4 }} />
        </div>
    );
};

export default OrgSearchBar;
