import React, { useState, useRef, useCallback, useEffect } from "react";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
// import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import AdvancedQueryModal from "../profile/Dialog/AdvancedQueryModal";
import { useQueryBuilder } from "../profile/Dialog/QueryBuilder/QueryBuilderContext";
import AdvancedQueryField from "../../components/input/AdvancedQueryField";
import { Bolt, Clear } from "@mui/icons-material";

interface Props {
    query: string;
    onChangeQuery(query: string): void;
    onSearch(): void;
    visible: "show" | "disabled" | "noShow";
    openModal(): void;
}

// const keyframes = `
//     @keyframes lightning {
//         35% {
//         color: #707070;
//         opacity: 0.2;
//         }
//         50% {
//         color: #F57117;
//         opacity: 1;
//         }
//         65% {
//         color: #707070;
//         opacity: 0.2;
//         }
//     }
// `;

// TODO: Target changes to summary on query update (if another target is chosen)
const OpportunitySearchBar: React.FC<Props> = ({ query, onChangeQuery, onSearch, visible, openModal }) => {
    const inputRef = useRef();
    const { pathname } = useLocation();
    // const { t } = useTranslation();
    const theme = useTheme();
    const grey = "#707070";
    // QUERY BUILDER PROPS
    const [openAdvancedBuilder, setOpenAdvancedBuilder] = useState<boolean>(false);
    const { advancedQueryString, setActiveGroup, groups, resetAdvancedQuery } = useQueryBuilder();
    const [showThunder, setShowThunder] = useState(false);

    // state to show user input in searchfield
    // debounce function to prevent request if characters is < 3 except 0
    const debounced = useRef(debounce((value) => (value.length === 0 ? onChangeQuery(value) : value.length >= 3 && onChangeQuery(value)), 1100));
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    /**
     * get defaultQuery based on location(url)
     */
    const defaultQuery =
        pathname === "/opportunities"
            ? sessionStorage.getItem("aoq")
            : pathname === "/opportunities/reviews"
            ? sessionStorage.getItem("roq")
            : pathname === "/opportunities/worklist"
            ? sessionStorage.getItem("woq")
            : "";

    // check if defAultQuery is not null and parse json
    const defaultQueryJson = defaultQuery ? JSON.parse(defaultQuery) : "";
    const userOnTenderPage = Boolean(
        pathname === "/opportunities/worklist" || pathname === "/opportunities/reviews" || pathname === "/opportunities"
    );

    /**
     * Searchfield state with default value from sessionStorage (defaultQuery)
     * remove "" in query
     */
    const [userValue, setUserValue] = useState(defaultQueryJson || "");

    const updateUserValue = useCallback(({ target: { value } }) => {
        setUserValue(value);
        debounced.current(value);
    }, []);

    useEffect(() => {
        onChangeQuery(advancedQueryString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedQueryString]);

    return (
        <div
            style={{
                width: onlyMobile ? "95vw" : "480px",
                maxWidth: "100% !important",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/*
                 * Searchbox to type query
                 * onSearch -> start search with typed query
                 */}
                {advancedQueryString === "" ? (
                    <Paper
                        square
                        component="form"
                        sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSearch();
                        }}
                    >
                        <SearchIcon style={{ margin: "10px 0px 10px 6px", color: grey }} />
                        <InputBase
                            ref={inputRef}
                            sx={{ marginLeft: theme.spacing(1), flex: 1 }}
                            placeholder="Zoeken"
                            autoFocus={onlyMobile}
                            value={userValue}
                            onChange={updateUserValue}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        {/*
                         * RESET BUTTON
                         */}
                        <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                            <IconButton
                                disabled={userValue === ""}
                                color="primary"
                                size="small"
                                aria-label="directions"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setUserValue("");
                                    onChangeQuery("");
                                    onSearch();
                                }}
                            >
                                <Clear sx={{ color: userValue === "" ? "#b3b3b3" : "error.main" }} />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                ) : (
                    <AdvancedQueryField
                        query={advancedQueryString}
                        openModal={() => setOpenAdvancedBuilder(true)}
                        resetQuery={() => {
                            setUserValue("");
                            resetAdvancedQuery();
                        }}
                        createSearchRule={() => {
                            openModal && openModal();
                        }}
                        themeColor="#225E4D"
                        showAddBtn={userOnTenderPage}
                    />
                )}
                <Tooltip title="Geavanceerd zoeken" placement="right">
                    <div>
                        <IconButton
                            onMouseEnter={() => setShowThunder(true)}
                            onMouseLeave={() => setShowThunder(false)}
                            onClick={() => {
                                setOpenAdvancedBuilder(true);
                                setActiveGroup(groups[0].id);
                            }}
                            size="small"
                            style={{ margin: "0 6px", cursor: "pointer" }}
                        >
                            <Bolt
                                style={{
                                    animation: showThunder ? "lightning 0.5s ease-in-out" : undefined,
                                }}
                            />
                            {/* <style>{keyframes}</style> */}
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
            {/*
             * Div with 4 px whitespace
             */}
            <div style={{ height: 4 }} />
            {openAdvancedBuilder && (
                <AdvancedQueryModal open={openAdvancedBuilder} onClose={() => setOpenAdvancedBuilder(false)} themeColor="#225E4D" />
            )}
        </div>
    );
};

export default OpportunitySearchBar;
