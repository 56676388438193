import React, { useState } from "react";
import { List, Grid, Typography, ListItem, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { CheckIfUserIsAdmin } from "../../../../__generated__/CheckIfUserIsAdmin";
import { QUERY_SEARCHRULES, QUERY_USER_IS_SUB_ADMIN } from "../../../../graphql/queryDefCurrentUser";
import { GetUserSearchRules } from "../../../../__generated__/GetUserSearchRules";
import OrganisationSearchLineOnlyShare from "./OrganisationSearchLineOnlyShare";
import RenderPage from "../../../../components/loading/RenderPage";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import SearchOwnerFilter from "../SearchOwnerFilter";
import SearchLineItemOnlyShare from "./SearchLineItemOnlyShare";
import { FindInPage, LocalOffer } from "@mui/icons-material";
import OppSearchlineCopyOnly from "./OppSearchlineCopyOnly";

interface Props {
    chosenRules: string;
}

const SearchLineOnlyShare: React.FC<Props> = ({ chosenRules }) => {
    const { t } = useTranslation();
    const [uniqueOwners, setUniqueOwners] = useState<any[]>([]);
    const [chosenOwners, setChosenOwners] = useState<number[]>([]);

    /**
     * Define the GQL query
     */
    const { data: adminCheck } = useQuery<CheckIfUserIsAdmin>(QUERY_USER_IS_SUB_ADMIN, {
        fetchPolicy: "network-only",
    });

    /**
     * Define the GQL query
     */
    const { data, loading, error, refetch } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES, {
        skip: chosenRules === "all",
        onCompleted: (data) => {
            if (data && data.currentUser.searches) {
                const searchlines = data.currentUser.searches as any;
                const uniqueOwnerIds = [...new Set(searchlines.map((searchline: any) => searchline.owner?.id))];
                const uniqueOwners = uniqueOwnerIds.map((ownerId) => {
                    const ownerSearches = searchlines.filter((searchline: any) => searchline.owner?.id === ownerId);
                    return {
                        ...searchlines.find((searchline: any) => searchline.owner.id === ownerId).owner,
                        count: ownerSearches.length,
                    };
                });
                setUniqueOwners(uniqueOwners);
            }
        },
        fetchPolicy: "network-only",
    });

    if (chosenRules === "All") {
        return <OrganisationSearchLineOnlyShare admin={Boolean(adminCheck?.currentUser.subscription_admin)} />;
    }

    if (loading)
        /**
         * if loading -> show loading
         */
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );

    /**
     * if error or empty -> show error
     */
    if (error || !data)
        return (
            <List style={{ padding: 0 }}>
                <ListItem
                    sx={{
                        borderLeft: (theme) => `5px solid ${"#173357"}`,
                        backgroundColor: "#ffffff",
                        height: "75px",
                    }}
                >
                    <Typography variant="h6">{t("Error")}</Typography>
                </ListItem>
            </List>
        );

    const searchRuleData = data.currentUser;

    // Filter the array based on the chosenOwner IDs
    const filteredSearchlines =
        chosenOwners.length > 0
            ? searchRuleData.searches.filter((searchline) => chosenOwners.includes(parseInt(searchline?.owner?.id as string)))
            : searchRuleData.searches;

    return (
        <Grid>
            {/* 
                If searchline = 0, user sees an animation that shows what a searchline does.
                There is also a button on this page to create the first searchline.
                Animation is gone when user creates his first searchline.
         
                When there is 1 or more searchline, the user sees a list with all the lines
                In this list its possible to edit, share, delete and see the stats.
            */}

            <>
                {/*
                 * If chosenRules is 'all' => show all searchrules
                 * Tender and opportunity searchrules
                 */}
                {chosenRules === "my" ? (
                    <div>
                        {/*
                         * Tender searchrules
                         */}
                        <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
                            <SearchOwnerFilter owners={uniqueOwners} chosenOwners={chosenOwners} setChosenOwners={setChosenOwners} />
                        </Grid>

                        {/*
                         * LIST WITH SEARCHES THAT ARE NOT ACCEPTED YET
                         */}
                        <List style={{ padding: 0 }}>
                            {searchRuleData.ownedSearches
                                .filter((s) => s.state === "PENDING_ACCEPTANCE")
                                .map((searchline) => {
                                    return <SearchLineItemOnlyShare refetch={refetch} type={"tender"} key={searchline.id} searchline={searchline} />;
                                })}
                        </List>

                        {/*
                         * LIST WITH SEARCHES (accepted searches included)
                         */}
                        <List style={{ padding: 0 }}>
                            {filteredSearchlines
                                .filter((s) => s.state !== "PENDING_ACCEPTANCE")
                                .map((searchline) => {
                                    return <SearchLineItemOnlyShare refetch={refetch} type={"tender"} key={searchline.id} searchline={searchline} />;
                                })}
                        </List>

                        {searchRuleData?.opportunitySearches && searchRuleData?.opportunitySearches.length > 0 && filteredSearchlines.length > 0 && (
                            <Divider sx={{ margin: "16px 0px", color: "#cccccc" }}>Kansen</Divider>
                        )}

                        {/*
                         * Opportunity searchrules
                         */}
                        <List style={{ padding: 0 }}>
                            {searchRuleData?.opportunitySearches?.map((searchline) => {
                                return <SearchLineItemOnlyShare type={"opportunity"} key={searchline.id} searchline={searchline} refetch={refetch} />;
                            })}
                        </List>
                    </div>
                ) : (
                    // If chosenRules is "tenders" => show tender searchRules only
                    <div>
                        {/*
                         * Tender searchrules
                         */}

                        {chosenRules === "tenders" && (
                            <div>
                                <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
                                    <SearchOwnerFilter owners={uniqueOwners} chosenOwners={chosenOwners} setChosenOwners={setChosenOwners} />
                                </Grid>
                                {searchRuleData.searches.length ? (
                                    <List style={{ padding: 0 }}>
                                        {searchRuleData.searches.map((searchline) => {
                                            return (
                                                <SearchLineItemOnlyShare
                                                    refetch={refetch}
                                                    type={"tender"}
                                                    key={searchline.id}
                                                    searchline={searchline}
                                                />
                                            );
                                        })}
                                    </List>
                                ) : (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "70svh",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <LocalOffer sx={{ height: "9em", width: "9em", color: "#1733572a" }} />
                                            <Typography variant="h5" sx={{ mt: "16px", color: "#d4d4d4" }}>
                                                Geen zoekregels - aanbestedingen
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/*
                         * If chosenRules is "opportunities" => show opportunity searchrules only
                         * TODO: dynamic content
                         */}
                        {chosenRules === "opportunities" && (
                            <div>
                                <Grid
                                    container
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px", height: "40px" }}
                                >
                                    <Divider sx={{ margin: "16px 0px", color: "#cccccc", fontWeight: 500 }}>Kansen</Divider>
                                </Grid>
                                {searchRuleData?.opportunitySearches?.length ? (
                                    <List style={{ padding: 0 }}>
                                        {searchRuleData?.opportunitySearches?.map((searchline) => {
                                            return <OppSearchlineCopyOnly key={searchline.id} searchline={searchline} />;
                                        })}
                                    </List>
                                ) : (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "70svh",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <FindInPage sx={{ height: "9em", width: "9em", color: "#225E4D2a" }} />
                                            <Typography variant="h5" sx={{ mt: "16px", color: "#d4d4d4" }}>
                                                Geen zoekregels - kansen
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </>
        </Grid>
    );
};

export default SearchLineOnlyShare;
