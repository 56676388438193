import React from "react";
import { Avatar, Card, Divider, IconButton, Skeleton, Typography } from "@mui/material";
import { LinkedIn, Mail, Phone } from "@mui/icons-material";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    orgLogo: string;
    authorImg: string;
    authorName: string;
    authorTitle: string;
    linkedin: string;
    phone: string;
    mail: string;
    skeleton: boolean;
}

const ColumnAuthor: React.FC<Props> = ({ orgLogo, authorImg, authorName, authorTitle, linkedin, mail, phone, skeleton }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    if (skeleton) {
        return (
            <Card
                variant="outlined"
                sx={{
                    position: "relative",
                    // top: "32px",
                    borderRadius: "24px",
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Skeleton variant="rectangular" width={210} height={60} sx={{ margin: "16px" }} />

                <Divider variant="middle" flexItem />

                <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
                    <Skeleton variant="circular" width={72} height={72} />
                </div>

                <Skeleton animation="wave" variant="rounded" width={175} />

                <Skeleton animation="wave" width={100} />
                <div style={{ paddingTop: "16px" }}>
                    <IconButton disabled>
                        <LinkedIn sx={{ color: "#70a4b7" }} />
                    </IconButton>

                    <IconButton disabled>
                        <Mail sx={{ color: "#70a4b7" }} />
                    </IconButton>

                    <IconButton disabled>
                        <Phone sx={{ color: "#70a4b7" }} />
                    </IconButton>
                </div>
            </Card>
        );
    }
    return (
        <Card
            variant="outlined"
            sx={{
                position: "relative",
                // top: "32px",
                borderRadius: "24px",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <img src={orgLogo} alt="" style={{ padding: "16px", width: "50%" }} />

            <Divider variant="middle" flexItem />

            <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
                <Avatar sx={{ width: 72, height: 72 }} src={authorImg} />
            </div>
            <Typography variant="h5">{authorName}</Typography>
            <Typography variant="caption">{authorTitle}</Typography>
            <div style={{ paddingTop: "16px" }}>
                <IconButton
                    href={linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackevents({ category: "Column", action: "Click linkedin" })}
                >
                    <LinkedIn sx={{ color: "#70a4b7" }} />
                </IconButton>

                <IconButton href={`mailto:${mail}`} onClick={() => trackevents({ category: "Column", action: "Click mail" })}>
                    <Mail sx={{ color: "#70a4b7" }} />
                </IconButton>

                <IconButton href={`tel:${phone}`} onClick={() => trackevents({ category: "Column", action: "Click phone" })}>
                    <Phone sx={{ color: "#70a4b7" }} />
                </IconButton>
            </div>
        </Card>
    );
};

export default ColumnAuthor;
