import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Divider, Slide, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { Launch } from "@mui/icons-material";

interface Props {
    href: string;
    children: React.ReactNode;
    tooltip_title: string;
    tooltip_favicon: string;
    tooltip_text: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: "12px",
        maxWidth: 500,
    },
}));

const CustomLink: React.FC<Props> = ({ href, children, tooltip_title, tooltip_favicon, tooltip_text }) => {
    const { trackEvent } = useMatomo();
    const [hover, sethover] = useState<boolean>(false);
    const containerRef = React.useRef<HTMLElement>(null);

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Special handling for links that start with https://tool.tender.guide/
    if (href.startsWith("https://tool.tender.guide/")) {
        return (
            <a
                href={href}
                target="_blank"
                style={{ color: "#82b6a0", fontWeight: 500, marginRight: "4px", marginLeft: "4px" }}
                rel="noreferrer"
                title={tooltip_text} // Add tooltip text to the native title attribute
            >
                {children} {tooltip_title}
            </a>
        );
    }
    return (
        <LightTooltip
            onMouseOver={() => sethover(true)}
            onMouseLeave={() => sethover(false)}
            title={
                <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginBottom: "8px" }}>
                        <span style={{ marginTop: 4 }}>
                            <div
                                style={{
                                    height: 18,
                                    minWidth: 18,
                                    maxWidth: 18,
                                    marginRight: 8,
                                    backgroundImage: `url(${tooltip_favicon})`,
                                    backgroundSize: "cover",
                                    borderRadius: 6,
                                }}
                            />
                        </span>
                        <Typography fontWeight={500} dangerouslySetInnerHTML={{ __html: tooltip_title }} />
                    </div>
                    <Divider />
                    <Typography sx={{ marginTop: "6px" }} dangerouslySetInnerHTML={{ __html: tooltip_text }} />
                </>
            }
            placement="top"
        >
            <Box
                component={"a"}
                ref={containerRef}
                href={href}
                target="_blank"
                style={{
                    color: "#70a4b7",
                    marginRight: "4px",
                    marginLeft: "4px",
                    borderBottom: hover ? "none" : "1px solid #70a4b7",
                    textDecoration: "none",
                    position: "relative",
                }}
                rel="noreferrer"
                onClick={() => trackevents({ category: "Column", action: "Click link in content" })}
                title={tooltip_title} // Add tooltip title to the native title attribute
            >
                <Slide in={hover} container={containerRef.current} direction="left">
                    <div
                        style={{
                            position: "absolute",
                            top: -3,
                            right: 0,
                            background: "#fff",
                            padding: "4px",
                            zIndex: 10,
                        }}
                    >
                        <Launch sx={{ height: "16px", width: "16px" }} />
                    </div>
                </Slide>
                {children}
            </Box>
        </LightTooltip>
    );
};

export default CustomLink;
