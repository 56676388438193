import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { Disable } from "react-disable";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { verifyWithMail, verifyWithMailVariables } from "../../__generated__/verifyWithMail";
import { LoadingButton } from "@mui/lab";
import { VERIFY_LOGIN_BY_MAIL } from "../../graphql/twoFAdefinitions";

interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: () => void;
    email: string;
    password: string;
    sendCodeToMail: () => Promise<void>;
    loadingsend: boolean;
}

const MailCode: React.FC<Props> = ({ setOpen, onSuccess, email, password, sendCodeToMail, loadingsend }) => {
    const { t } = useTranslation();
    const [inputCode, setInputCode] = useState<string>("");

    const [verify, { loading }] = useMutation<verifyWithMail, verifyWithMailVariables>(VERIFY_LOGIN_BY_MAIL);
    const [error, setError] = useState<string | undefined>(undefined);
    const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

    const handleInputChange = (e: any) => {
        setInputCode(e.target.value);
    };

    const handleVerify = async () => {
        try {
            const response = await verify({
                variables: { email, password, code: inputCode },
            });
            const data = response.data?.verifyWithMail;

            if (data)
                if (data?.success && data?.token) {
                    // Handle successful 2FA login (e.g., store the token, redirect)
                    localStorage.setItem("access_token", data.token);
                    setOpen(false);
                    onSuccess();
                } else {
                    setErrorCode(data?.message as string);
                    setError(t(data?.message as string));
                }
        } catch (e) {
            setError("Verificatiecode incorrect");
            localStorage.removeItem("acces_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("initials");
        }
    };

    return (
        <>
            <Typography textAlign="center" variant="body1" fontWeight={500} sx={{ color: "#acacac" }}>
                Gebruik de verificatiecode die naar je e-mailadres is verstuurd
            </Typography>
            <Disable disabled={loading}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "32px",
                        flexDirection: "column",
                    }}
                >
                    <TextField
                        fullWidth
                        label="Voer je verificatiecode in"
                        value={inputCode}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ marginBottom: "16px" }}
                        error={!!error} // Show error state if there's an error
                        helperText={
                            <div style={{ visibility: error ? "visible" : "hidden" }}>
                                <Typography variant="caption" color="error.main">
                                    {error || "ERROR_PLACEHOLDER"}
                                </Typography>
                            </div>
                        }
                    />
                    <div>
                        {errorCode === "Retry" ? (
                            <>
                                <LoadingButton
                                    loading={loadingsend}
                                    variant="text"
                                    fullWidth={false}
                                    onClick={() => {
                                        sendCodeToMail();
                                        setError(undefined);
                                    }}
                                >
                                    Opnieuw verzenden
                                </LoadingButton>
                                <LoadingButton
                                    loading={loading}
                                    disabled={inputCode.length < 3}
                                    variant="contained"
                                    fullWidth={false}
                                    onClick={handleVerify}
                                >
                                    verifiëren
                                </LoadingButton>
                            </>
                        ) : (
                            <LoadingButton
                                loading={loading}
                                disabled={inputCode.length < 3}
                                variant="contained"
                                fullWidth={false}
                                onClick={handleVerify}
                            >
                                verifiëren
                            </LoadingButton>
                        )}
                    </div>
                </div>
            </Disable>
        </>
    );
};

export default MailCode;
