import React, { useState, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Material UI
import { Button, Paper, MenuItem, IconButton } from "@mui/material";

// Components
import ScheduleDialog from "./ScheduleDialog";
import WidgetBox from "./WidgetBox";
import WidgetBoxMenu from "./WidgetBoxMenu";
import { Launch } from "@mui/icons-material";
import PlanningExpandModal from "./boxes/PlanningExpandModal";
import { Disable } from "react-disable";
import ScheduleTimelineNoHoliday from "./ScheduleTimelineNoHoliday";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { addTenderEventToCalendar, addTenderEventToCalendarVariables } from "../__generated__/addTenderEventToCalendar";
import { SAVE_TENDER_EVENT } from "../graphql/mutationDefinitions";
import { toast } from "react-toastify";
import { GET_TENDER_EVENTS } from "../graphql/queryDefinitions";
import { GetTenderEvents, GetTenderEventsVariables } from "../__generated__/GetTenderEvents";

interface Props {
    itemLoop: any[];
    deadline: string;
    country: string | null;
    tender_id: string;
    host: string | null;
    hostUrl: string | null;
    elevation?: number;
    refetch: (variables?: Partial<GetTenderEventsVariables> | undefined) => Promise<ApolloQueryResult<GetTenderEvents>>;
    isResultOrSectorMatch: boolean;
}

// ComponentDidMount is used to
// execute the code
const ScheduleTimeline: React.FC<Props> = ({ itemLoop, deadline, country, tender_id, host, hostUrl, elevation, refetch, isResultOrSectorMatch }) => {
    // const [showChanges, setShowChanges] = useState(false);
    const [showSource, setShowSource] = useState(false);
    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const [openModal, setOpenModal] = useState(false);
    const [hideHoliday, setHideHoliday] = useState(false);
    const { t } = useTranslation();
    const ref1 = useRef(null);
    const [initState, setInit] = useState<any[]>(itemLoop);
    const [itemToCal, setItemToCal] = useState<Array<any>>([]);
    const [open, setOpen] = useState(false);
    const [holidays, setHolidays] = useState<Array<any>>([]);
    const [inViewport, setInViewport] = useState<boolean>(false);
    const newarr = [...initState];
    newarr.sort((a, b) => moment(a.planned).diff(b.planned));
    const isInViewport1 = useIsInViewport(ref1);
    const [hideReplacedItems, setHideReplacedItems] = useState<boolean>(false);

    useEffect(() => {
        setInit(itemLoop);
    }, [itemLoop]);

    /**
     * Handler to check if isInViewport1 changes boolean
     * If true -> scroll element with id "TODAY" into view
     */
    useEffect(() => {
        // 👇️ listen for changes
        if (isInViewport1 && inViewport === false) {
            const ref = document.getElementById("TODAY");

            setTimeout(function () {
                ref?.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                });
            }, 100);
            setInViewport(true);
            // document?.getElementById("TODAY")?.scrollIntoView({ behavior: "auto", block: "center" });
        }
    }, [isInViewport1, inViewport]);

    // function to check if element is in viewport
    function useIsInViewport(ref: any) {
        const [isIntersecting, setIsIntersecting] = useState(false);
        const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);
        useEffect(() => {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);
        return isIntersecting;
    }

    /**
     * Date to start array with
     */
    const start = moment(newarr[0].planned);

    /**
     * Deadline date is date to end with.
     * When deadline is null push "". moment cant handle null
     */
    const end = deadline !== null ? moment(deadline) : moment();

    /**
     * Props for holiday api
     * key = api key to get future holidays
     * language = language from local storage (user can change language)
     * year = year of first event date
     * secondYear = year of deadline date (its possible that deadline is in next year)
     * internationalDay = show holiday that is a national day
     * url1 = get all holiday dates of year 1
     * url2 = get all holiday dates of year 2 (possible that its same year as 1, in that case it wont be used in array)
     */
    const key = "3ee178e0-c083-4d3a-91cc-5f44f68bd547";
    const language =
        localStorage.getItem("i18nextLng") === "en-US"
            ? "en"
            : localStorage.getItem("i18nextLng") === "nl-nl"
            ? "nl"
            : localStorage.getItem("i18nextLng") === "nl-NL"
            ? "nl"
            : localStorage.getItem("i18nextLng");
    const year = moment(newarr[0].planned).format("YYYY");
    const secondYear = deadline !== null ? moment(deadline).format("YYYY") : moment().format("YYYY");

    const internationalDay = "true";
    const url1 = `https://holidayapi.com/v1/holidays?pretty&key=${key}&country=${country}&language=${language}&year=${year}&public=${internationalDay}`;
    const url2 = `https://holidayapi.com/v1/holidays?pretty&key=${key}&country=${country}&language=${language}&year=${secondYear}&public=${internationalDay}`;

    /**
     * Check if start & end date have same year
     */
    const yearCheck = moment(start).isSame(end, "year");

    /**
     * useEffect to fetch date from holidayApi
     * Fetch data from url1 & url 2
     * if yearCheck is true, url2 wont be executed.
     */
    useEffect(() => {
        const fetchOneYear = async () => {
            Promise.all([fetch(url1).then((value) => value.json()), !yearCheck ? fetch(url2).then((value) => value.json()) : Promise.resolve()])
                .then((value) => {
                    const array = yearCheck ? [...value[0].holidays] : [...value[0].holidays, ...value[1].holidays];
                    setHolidays(array);
                })
                .catch((err) => {
                    console.log("err", err);
                });
        };
        fetchOneYear();
    }, [url1, url2, yearCheck]);

    /**
     * Get all dates in event array between start date (first event in array) & end date (deadline)
     */
    const holidayArray = holidays.filter((item) => {
        const date = moment(item.date);
        return date >= start && date <= end;
    });

    /**
     * Today object to inject in array
     */
    const today = {
        id: "date_of_today",
        planned: moment().format(),
        name: "today",
        source: null,
        isReplacedBy: null,
        created_at: null,
        timeSignificant: null,
        isStored: false,
        tags: [],
    };

    /**
     * Combine event array with holiday array
     */
    const combinedArray = [...initState, ...holidayArray];

    /**
     * Get combined array and merge some props to make it easier to sort
     * props planned(event) and date(holiday) are combined in new array as prop planned.
     * Named it planned because of the logic in the timelineitem, this item was already build with planned.
     */
    const arr_with_holidays = combinedArray
        .map((obj) => {
            return {
                id: obj.id as string,
                planned:
                    (obj.timeSignificant === false ? moment(obj.planned).endOf("day").format() : (obj.planned as any | null)) ||
                    (obj.date as any | null),
                name: obj.name as string,
                source: obj.source as any | null,
                isReplacedBy: obj.isReplacedBy as any | null,
                created_at: obj.created_at as any | null,
                timeSignificant: obj.timeSignificant as boolean | null,
                isStored: obj.isStored as boolean,
                tags: obj.tags,
            };
        })
        .concat(today)
        .sort((a, b) => {
            return moment(a.planned).format().localeCompare(moment(b.planned).format());
        });

    /**
     * array without holiday events
     * same mapping as array with holidayevents
     */
    const arr_no_holidays = [...initState]
        .map((obj) => {
            return {
                id: obj.id as string,
                planned:
                    (obj.timeSignificant === false ? moment(obj.planned).endOf("day").format() : (obj.planned as any | null)) ||
                    (obj.date as any | null),
                name: obj.name as string,
                source: obj.source as any | null,
                isReplacedBy: obj.isReplacedBy as any | null,
                created_at: obj.created_at as any | null,
                timeSignificant: obj.timeSignificant as boolean | null,
                isStored: obj.isStored as boolean,
                tags: obj.tags,
            };
        })
        .concat(today)
        .sort((a, b) => {
            return moment(a.planned).format().localeCompare(moment(b.planned).format());
        });

    /**
     * Close dialog
     */
    const handleCloseAlert = () => {
        setOpen(false);
    };

    /**
     * Open dialog to add items to agenda
     */
    const handleOpenAlert = () => {
        setOpen(true);
    };

    /**
     * Add item to state, to put in agenda
     * Item can be set when its unique, no duplicates.
     * @param item selected item to add to calendar
     * TODO: change to mutation
     */
    const handleAdd = (item: any, state: boolean) => {
        // Add to calendar
        if (state) {
            setItemToCal([...itemToCal, item]);
        }
        // Remove from calendar
        else {
            setItemToCal(itemToCal.filter((i) => i !== item));
        }
    };

    /**
     * Clear setItemToCal to cancel selection
     */
    const deselectAll = () => {
        setItemToCal([]);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // TODO: add function to add all items to agenda in one click
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // Change state to hide replaced events
    // add delay so text in button can change out of view
    // const handleChanges = async () => {
    //     setAnchorEl(null);
    //     await sleep(100);
    //     setShowChanges((s) => !s);
    // };

    // Change state to show source of contract
    // add delay so text in button can change out of view
    const handleSource = async () => {
        setAnchorEl(null);
        await sleep(100);
        setShowSource((s) => !s);
    };

    // Change state to show/hide holiday
    const handleHoliday = async () => {
        setAnchorEl(null);
        await sleep(100);
        setHideHoliday((s) => !s);
    };

    // Change state to show/hide holiday
    const handleHidereplacedItems = async () => {
        setAnchorEl(null);
        await sleep(100);
        setHideReplacedItems((s) => !s);
    };

    /**
     * Mutation to save array of events
     * Loading and error state can be used give user some feedback when waiting or an error occurs
     */
    const [saveEvent, { loading, error }] = useMutation<addTenderEventToCalendar, addTenderEventToCalendarVariables>(SAVE_TENDER_EVENT);
    const [load, setLoad] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);
    const allItems = hideHoliday ? arr_no_holidays : arr_with_holidays;

    /**
     * Show only selected events based on itemToCal (contains selected event_id's)
     * allItems contains all events => merge is needed to recreate array when modal opens again after add/remove event(s)
     */
    const getSelectedItems = allItems.filter((item: any) => itemToCal.includes(item.id));

    /**
     * Save tender events to user calendar
     */
    const saveSelectedDates = () => {
        // Array with id's to save and change to number instead
        const arr = getSelectedItems.map((i: any) => parseInt(i.id));
        /**
         * Foreach event try to mutate action
         */
        setLoad(true);
        setComplete(false);
        if (arr.length > 0) {
            arr.forEach(async (eventID: number) => {
                // Function to like organization
                if (loading) {
                }
                if (error) {
                    toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await saveEvent({
                        variables: {
                            event_id: eventID,
                        },
                        refetchQueries: [GET_TENDER_EVENTS, "GetTenderEvents"],
                        awaitRefetchQueries: true,
                    });
                } catch (e) {
                    toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setLoad(false);
                    setComplete(true);
                }
            });
        }
    };

    /**
     * Render widgetbox to use for loading and error state
     */
    const renderPage = (content: React.ReactNode, disable: boolean) => {
        return (
            <Disable disabled={disable}>
                <div>
                    <div>
                        <WidgetBox
                            elevation={elevation}
                            header={t("tenderPage.Schedule")}
                            highlight="blue"
                            padding={0}
                            actions={
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setOpenModal(!openModal);
                                        }}
                                    >
                                        <Launch fontSize={"small"} />
                                    </IconButton>
                                    <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                                        {/* <MenuItem onClick={handleChanges}>{showChanges ? t("HideChanges") : t("ShowChanges")}</MenuItem> */}
                                        <MenuItem onClick={handleSource}>{showSource ? t("HideSource") : t("ShowSource")}</MenuItem>
                                        <MenuItem onClick={handleHidereplacedItems}>
                                            {hideReplacedItems ? "Toon vervangen items" : "Verberg vervangen items"}
                                        </MenuItem>
                                        <MenuItem onClick={handleHoliday}>{hideHoliday ? "Toon feestdagen" : "Verberg feestdagen"}</MenuItem>
                                        {/* 
                                            <MenuItem disabled onClick={handleClose}>
                                                {t("AddAllToAgenda")}
                                            </MenuItem> 
                                        */}
                                    </WidgetBoxMenu>
                                </div>
                            }
                        >
                            {content}
                        </WidgetBox>
                        {/*************
                         * Show buttons when at least one calendar item is selected.
                         * => button to add to calender
                         * => button to clear selected items
                         * Buttons placed in separate paper, this way the buttons are always visible.
                         *************/}
                        {itemToCal.length > 0 ? (
                            <Paper
                                square
                                sx={{
                                    flex: 7,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    background: "#ffffff",
                                    marginTop: "-28px",
                                    paddingTop: "16px",
                                    marginBottom: "28px",
                                }}
                            >
                                <Button size="small" style={{ margin: "0px 0px 16px 0px" }} onClick={() => deselectAll()}>
                                    {t("tenderPage.Cancel")}
                                </Button>
                                <Button
                                    size="small"
                                    onClick={handleOpenAlert}
                                    variant="contained"
                                    color="primary"
                                    style={{ margin: "0px 30px 16px 0px" }}
                                >
                                    {t("tenderPage.Add")}
                                </Button>
                            </Paper>
                        ) : (
                            <div />
                        )}
                    </div>
                    <div ref={ref1} />
                    {/*
                     * Dialog with selected cal items.
                     */}
                    {open && (
                        <ScheduleDialog
                            onCloseAlert={handleCloseAlert}
                            open={open}
                            allItems={getSelectedItems}
                            load={load}
                            complete={complete}
                            saveSelectedDates={saveSelectedDates}
                            onCloseAfterComplete={() => {
                                refetch();
                                // Close modal
                                handleCloseAlert();
                                // Set selection back to empty array to hide buttons
                                setItemToCal([]);
                                setComplete(false);
                            }}
                        />
                    )}
                    {openModal && (
                        <PlanningExpandModal
                            deadline={deadline}
                            hideReplacedItems={hideReplacedItems}
                            onHidereplacedItems={handleHidereplacedItems}
                            hideHoliday={hideHoliday}
                            onHideholiday={handleHoliday}
                            host={host}
                            hostUrl={hostUrl}
                            tender_id={tender_id}
                            itemToCal={itemToCal}
                            handleAdd={handleAdd}
                            showHandleAdd={false}
                            showSource={showSource}
                            refactoredArray={hideHoliday ? arr_no_holidays : arr_with_holidays}
                            handleClose={() => {
                                setOpenModal(!openModal);
                            }}
                            open={openModal}
                            isResultOrSectorMatch={isResultOrSectorMatch}
                        />
                    )}
                </div>
            </Disable>
        );
    };

    if (hideHoliday) {
        // Render planning without holiday
        return renderPage(
            <ScheduleTimelineNoHoliday
                isResultOrSectorMatch={isResultOrSectorMatch}
                dateArray={
                    hideReplacedItems
                        ? arr_no_holidays.filter((item) => item.isReplacedBy === null || item.isReplacedBy === undefined)
                        : arr_no_holidays
                }
                host={host}
                hostUrl={hostUrl}
                tender_id={tender_id}
                itemToCal={itemToCal}
                handleAdd={handleAdd}
                showSource={showSource}
                deadline={deadline}
            />,
            false
        );
    }
    // Render planning with holiday
    return renderPage(
        <ScheduleTimelineNoHoliday
            isResultOrSectorMatch={isResultOrSectorMatch}
            dateArray={
                hideReplacedItems
                    ? arr_with_holidays.filter((item) => item.isReplacedBy === null || item.isReplacedBy === undefined)
                    : arr_with_holidays
            }
            host={host}
            hostUrl={hostUrl}
            tender_id={tender_id}
            itemToCal={itemToCal}
            handleAdd={handleAdd}
            showSource={showSource}
            deadline={deadline}
        />,
        false
    );
};

export default ScheduleTimeline;
