import React from "react";
import { Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Typography } from "@mui/material";
import SchedulteTimeLineItem from "./ScheduleTimeLineItem";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_TENDER_INCOMPATIBLE_DATES } from "../graphql/queryDefinitions";
import { GetTenderIncompatibleDates, GetTenderIncompatibleDatesVariables } from "../__generated__/GetTenderIncompatibleDates";

interface Props {
    dateArray: any[];
    tender_id: string;
    host: string | null;
    hostUrl: string | null;
    itemToCal: any;
    handleAdd: (item: any, state: boolean) => void;
    showSource: boolean;
    deadline: string;
    isResultOrSectorMatch: boolean;
}

const ScheduleTimelineNoHoliday: React.FC<Props> = ({
    dateArray,
    host,
    hostUrl,
    tender_id,
    itemToCal,
    handleAdd,
    showSource,
    deadline,
    isResultOrSectorMatch,
}) => {
    /**
     * Fetch tender.events, deadline_inschrijving
     */
    const { data } = useQuery<GetTenderIncompatibleDates, GetTenderIncompatibleDatesVariables>(GET_TENDER_INCOMPATIBLE_DATES, {
        variables: { id: tender_id },
    });

    /**
     * import theme to use custom colorvariables outside useStyles
     */
    const todayIndicator = "#173357";
    return (
        <React.Fragment>
            <div style={{ height: "400px", overflow: "auto" }}>
                <Timeline
                    sx={{
                        "& .MuiTimeline-root": {
                            padding: 0,
                        },
                    }}
                >
                    {dateArray.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                {item.id === "date_of_today" ? (
                                    <div>
                                        {/* today indicator */}
                                        <TimelineItem
                                            sx={{
                                                // "& .MuiTimelineItem-root": {
                                                minHeight: "0px",
                                                display: "flex",
                                                alignItems: "center",
                                                // },
                                            }}
                                        >
                                            {/* show date of today */}
                                            <TimelineOppositeContent style={{ minWidth: "100px" }}>
                                                <Typography>{moment().format("D MMM")}</Typography>
                                            </TimelineOppositeContent>

                                            {/* Outlined dot to show user this is not an scheduled item */}
                                            <TimelineSeparator>
                                                <TimelineDot variant="outlined" style={{ borderColor: `${todayIndicator}` }} />
                                            </TimelineSeparator>

                                            {/* Small line to show an indicator that this is today */}
                                            <TimelineContent style={{ flex: 10 }}>
                                                <div id="TODAY" style={{ height: "2px", backgroundColor: `${todayIndicator}`, width: "100%" }} />
                                            </TimelineContent>
                                        </TimelineItem>
                                    </div>
                                ) : (
                                    // if timelineitem date is not firstfuturedate, show item without today indicator
                                    <SchedulteTimeLineItem
                                        deadline={deadline}
                                        incompatibleDates={data?.tender?.incompatibleDates ?? []}
                                        host={host}
                                        hostUrl={hostUrl}
                                        tender_id={tender_id}
                                        item={item}
                                        index={index}
                                        lastItem={dateArray.length - 1}
                                        itemToCal={itemToCal}
                                        onHandleAdd={handleAdd}
                                        showSource={showSource}
                                        showHandleAdd={item.isStored}
                                        showOptionToAdd={true}
                                        isResultOrSectorMatch={isResultOrSectorMatch}
                                    />
                                )}
                            </div>
                        );
                    })}
                </Timeline>
            </div>
        </React.Fragment>
    );
};

export default ScheduleTimelineNoHoliday;
