import React from "react";

interface Props {
    a?: number;
}

const ColumnDots: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                position: "absolute",
                top: "75vh",
                left: "-125px",
                height: "500px",
                width: "500px",
            }}
        >
            <svg id="10015.io" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <pattern
                        id="svg-pattern"
                        x="0"
                        y="0"
                        width="54"
                        height="54"
                        patternUnits="userSpaceOnUse"
                        patternTransform="translate(30, 30) rotate(0) skewX(0)"
                    >
                        <svg width="24" height="24" viewBox="0 0 100 100">
                            <g fill="#70a4b717" opacity="1">
                                <circle cx="50" cy="50" r="50" />
                            </g>
                        </svg>
                    </pattern>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="rgba(255, 255, 255, 0)" />
                <rect x="0" y="0" width="100%" height="100%" fill="url(#svg-pattern)" />
            </svg>
        </div>
    );
};

export default ColumnDots;
