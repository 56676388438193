import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//accordion imports
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography, Divider, Link, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

//pagination
import { useQuery } from "@apollo/client";

import { withStyles } from "tss-react/mui";
import { getSnippetsForLabelWidget, getSnippetsForLabelWidgetVariables } from "../../../../__generated__/getSnippetsForLabelWidget";
import WidgetBox from "../../../../components/WidgetBox";
import DocIconSwitch from "../../../../components/DocIconSwitch";
import SingleLabelSnippet from "../../../../components/LabelWidget/SingleLabelSnippet";
import DocumentDrawer from "../../../contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { GET_SNIPPETS_OF_DOCS } from "../../../../graphql/queryDefSearchAndHighlighting";

interface Props {
    tender_id: string;
    highlight?: boolean;
    variant?: "outlined" | "elevation" | undefined;
    title?: boolean;
    elevation?: number;
}

//accordion style
const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

// TODO: dynamic content
interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
}
const LabelWidget: React.FC<Props> = ({ tender_id, highlight, variant, title, elevation }) => {
    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    const [expanded, setExpanded] = useState<number | false>(0);
    const { t } = useTranslation();
    const blue = "#173357";
    const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // const docsWithLabels = documents.filter((document) => document.userSnippets !== null && (document.userSnippets || []).length > 0);
    const { data, loading, error } = useQuery<getSnippetsForLabelWidget, getSnippetsForLabelWidgetVariables>(GET_SNIPPETS_OF_DOCS, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    const renderPage = (content: React.ReactNode) => (
        <Grid item xs={12} md={6}>
            <WidgetBox
                header={title === false ? "" : t("tenderPage.Labels")}
                highlight={highlight === false ? undefined : "blue"}
                padding={0}
                elevation={0}
                actions={<div style={{ height: "30px" }} />}
            >
                {content}
            </WidgetBox>
        </Grid>
    );

    if (loading) return null;
    if (error || !data || data?.tender === null || data.tender.userLabels.length === 0) {
        return null;
    }
    // handler to close drawer
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "", doc_id: "" });
    };

    return renderPage(
        <>
            {/*
             * First accordion
             */}
            {data.tender?.userLabels.map((search, i) => {
                const arrayOfSnips = search.snippets?.flatMap((i) => i?.snippets?.map((j) => j));
                const uniqueLabels = [...new Set(search.snippets?.map((i) => i?.search))];
                const handleHighlighting = () =>
                    uniqueLabels.forEach((label) => {
                        const collection = document.getElementsByClassName(`search_${label?.id}`) as HTMLCollectionOf<HTMLElement>;
                        for (let i = 0; i < collection.length; i++) {
                            // styling on render
                            collection[i].style.textDecoration = `underline 3px ${label?.color}`;
                            collection[i].style.cursor = "pointer";
                            collection[i].style.fontStyle = "normal";

                            // styling on mouse enter (hover)
                            collection[i].onmouseenter = () => {
                                for (let k = 0; k < collection.length; k++) {
                                    collection[k].style.textDecoration = `underline 5px ${label?.color}`;
                                    collection[k].style.fontWeight = "500";
                                    collection[k].style.fontStyle = "normal";
                                }
                            };

                            // styling on mouse leave
                            collection[i].onmouseleave = () => {
                                for (let k = 0; k < collection.length; k++) {
                                    collection[k].style.textDecoration = `underline 3px ${label?.color}`;
                                    collection[k].style.fontWeight = "normal";
                                    collection[k].style.fontStyle = "normal";
                                }
                            };
                        }
                    });
                return (
                    <React.Fragment key={i}>
                        <Accordion
                            variant={variant ? variant : "outlined"}
                            expanded={expanded === i}
                            onChange={handleChange(i)}
                            onClick={() => handleHighlighting()}
                        >
                            {/*
                             * HeadingBlock that is inside summary
                             */}
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                        <DocIconSwitch icon={search?.document?.extension} />

                                        {/*
                                         * Document title with link to open document
                                         */}
                                        <Link
                                            underline="none"
                                            component="button"
                                            onClick={(e) => {
                                                if (search.document?.extension === "pdf") {
                                                    e.stopPropagation();
                                                    setOpen({
                                                        open: true,
                                                        link: search?.document?.temporary_link,
                                                        label: search?.document?.filename,
                                                        doc_id: search?.document?.id,
                                                    });
                                                } else {
                                                    // Stop click on listitem to prevent document-viewer to initiate
                                                    e.stopPropagation();
                                                    const element = document.createElement("a");

                                                    element.setAttribute(
                                                        "href",
                                                        `${search.document?.temporary_link}&response-content-disposition=attachment; filename=${search?.document?.filename}`
                                                    );

                                                    element.style.display = "none";
                                                    document.body.appendChild(element);
                                                    element.click();
                                                    document.body.removeChild(element);
                                                }
                                            }}
                                            // href={`${search?.document?.temporary_link}`}
                                            style={{ display: "flex", alignItems: "center", marginRight: 32, color: blue }}
                                        >
                                            <Typography style={{ textAlign: "left", display: "inline-block" }}>
                                                {search?.document?.filename}
                                            </Typography>
                                        </Link>
                                    </div>

                                    {/*
                                     * Map over searchrules
                                     */}
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {uniqueLabels.map((s, i) => {
                                            return <LocalOfferIcon className={`search_${s?.id}`} key={i} style={{ color: `${s?.color}` }} />;
                                        })}
                                    </div>
                                </div>
                            </AccordionSummary>
                            {/*
                             * Fragment of found word(s)
                             */}
                            <AccordionDetails
                                sx={{
                                    "& .MuiAccordionDetails-root": {
                                        padding: 0,
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop: "-16px",
                                    },
                                }}
                            >
                                <SingleLabelSnippet arrayOfSnips={arrayOfSnips} handleHighlighting={handleHighlighting} />
                            </AccordionDetails>
                        </Accordion>
                        {/*
                         * Divider
                         */}
                        {data.tender?.userLabels !== undefined && i !== data.tender?.userLabels?.length - 1 && <Divider />}
                    </React.Fragment>
                );
            })}
            {/* Drawer that contains clicked pdf file */}
            {typeof open.label === "string" && typeof open.link === "string" && open.open === true && (
                <DocumentDrawer
                    open={open.open}
                    onClose={closeDrawer}
                    title={open.label}
                    color={blue}
                    url={open.link}
                    doc_id={open.doc_id}
                    module_item_id={tender_id}
                    downloadable={true}
                />
            )}
        </>
    );
};

export default LabelWidget;
