import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { Today } from "@mui/icons-material";
import ColumnAuthor from "./ColumnAuthor";
import ColumnFooter from "./ColumnFooter";

interface Props {
    content: string;
}

const ColumnLoadAndError: React.FC<Props> = ({ content }) => {
    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const aboveTablet = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Grid container style={{ height: "100vh", position: "relative" }}>
            {/* HEADER GRID */}
            <Grid item container xs={12}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "26% 26% 49% 51% / 0% 0% 50% 50%",
                        backgroundColor: "#70a4b726",
                        padding: aboveTablet ? "128px 0px" : aboveMobile ? "64px 0px" : "16px 0px",
                    }}
                >
                    <div>
                        <Skeleton variant="rectangular" width={"50vw"} height={50} sx={{ margin: "16px" }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                        <Today
                            sx={{
                                height: aboveMobile ? "24px" : "16px",
                                width: aboveMobile ? "24px" : "16px",
                                marginRight: "8px",
                                color: "#0000008a",
                            }}
                        />
                        <Skeleton variant="rectangular" width={"100px"} />
                    </div>
                </Grid>

                <Grid item container columns={14} pt={aboveTablet ? 12 : aboveMobile ? 6 : 4}>
                    <Grid item xs={14} md={3}>
                        {/* COLUMN TO FILL WITH SHAPES */}
                    </Grid>
                    {/* CONTENT BOX */}
                    <Grid item xs={14} md={7} paddingBottom={aboveTablet ? "128px" : aboveMobile ? "64px" : "32px"} px={aboveTablet ? 0 : "16px"}>
                        <Typography variant="h3">{content === "loading" ? "" : content}</Typography>
                    </Grid>
                    <Grid item xs={14} md={4}>
                        <div
                            style={{
                                position: "sticky",
                                top: "32px",
                                width: "100%",
                                overflowX: "hidden",
                                padding: aboveTablet ? "0px 96px" : aboveMobile ? "0px 128px" : "0px",
                            }}
                        >
                            <ColumnAuthor
                                skeleton={true}
                                orgLogo={""}
                                authorImg={""}
                                authorName={""}
                                authorTitle={""}
                                linkedin={""}
                                phone={""}
                                mail={""}
                            />
                        </div>
                        ,
                    </Grid>
                </Grid>
            </Grid>

            {/* grid for spacing page*/}
            <Grid item xs={14} md={14} padding={aboveMobile ? 12 : 0} pt={aboveMobile ? 6 : 0} marginBottom={"64px"} />

            {/* FOOTER */}
            <ColumnFooter />
        </Grid>
    );
};

export default ColumnLoadAndError;
