import React, { useEffect, useState } from "react";
import SearchField from "../input/TenderSearchField";
import { Button } from "@mui/material";
import { SortOrder, TenderFilterInput, TenderFilterTarget } from "../../__generated__/globalTypes";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
// Material icons
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";

// Import Custom table buttons
import { useLocation } from "react-router-dom";
import TopFilterBar, { DateRange } from "../FilterDrawer/TopFilterBar";
import TenderTableActions from "./TenderTableActions";
import TenderSearchRuleWizard from "../../routes/tenders/TenderSearchRuleWizard";
import SearchWizardContainer from "../../routes/profile/Dialog/SearchWizardContainer";
import ViewMenu, { View } from "./ViewMenu";
import { Disable } from "react-disable";
import _ from "lodash";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Link } from "react-router-dom";
import InfoButtonTooltip from "../InfoButtonTooltip";
import { FilterTendersOverviewMinimum_filterTenders_filters } from "../../__generated__/FilterTendersOverviewMinimum";

import MatomoEvent from "../../models/MatomoEvent";
import { useCustomOptions } from "../contextProviders/TenderCustomOptionProvider";

interface Props {
    /**
     * User inpot in searchbox
     */
    query: string;
    /**
     * Target of search: summary, marked docs or all docs
     */
    target: TenderFilterTarget;
    /**
     * set query as searchparam
     */
    setQuery: (query: string) => void;
    /**
     * set target as searchparam
     */
    setTarget: (target: TenderFilterTarget) => void;
    /**
     * reset page to chosen page
     */
    resetPage: () => void;
    /**
     * filter input
     */
    searchInput: TenderFilterInput;
    /**
     *
     * @param searchInput onchange filterinput
     */
    onChange(searchInput: TenderFilterInput): void;
    /**
     * start search with active filter(s)
     */
    startSearch(): void;
    /**
     * show button to create search rule
     */
    visible: boolean;
    /**
     * Menu to show multiple view options: column, split, update and listview
     */
    viewButtons: boolean;
    /**
     *
     * @param view set view to selected viewtype
     */
    onViewClick?(view: number): void;
    /**
     * Chosen view, listview is default
     */
    activeView?: number;
    /**
     * Array of optional views
     */
    views?: View[];
    /**
     * Data to fill filtervalues from backend
     */
    filterData?: FilterTendersOverviewMinimum_filterTenders_filters[] | null;
    /**
     * loading state
     */
    loading: boolean;
    /**
     * Resetbutton to deactivate all filters
     */
    resetFilters: () => void;
    /**
     * Total amount of tenders
     */
    totalTenders?: number;
    /**
     * Sorting columnname (needed for export function)
     */
    orderField: string;
    /**
     * Sorting order (needed for export function)
     */
    order: SortOrder;
    /**
     * columns
     */
    columns: any[];
    /**
     * boolean to check if view is not preferred view
     */
    changeView?: boolean;
}

// Custom order to show for countries
export const sortCountry = ["België", "Nederland"];

const TenderSearch: React.FC<Props> = ({
    query,
    target,
    setQuery,
    setTarget,
    resetPage,
    searchInput,
    onChange,
    startSearch,
    visible,
    viewButtons,
    onViewClick,
    activeView,
    filterData,
    loading,
    resetFilters,
    totalTenders,
    order,
    orderField,
    columns,
    views,
    changeView,
}) => {
    const theme = useTheme();
    const { trackSiteSearch } = useMatomo();
    const { trackEvent } = useMatomo();
    const [open, setOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openPopup, setOpenPopup] = useState<string | false>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const [deadline, setDeadline] = useState<DateRange>({ from: null, to: null });
    const [firstPublication, setFirstPublication] = useState<DateRange>({ from: null, to: null });
    const [endDate, setEndDate] = useState<DateRange>({ from: null, to: null });
    const [endDateInc, setEndDateInc] = useState<DateRange>({ from: null, to: null });

    const [awardDate, setAwardDate] = useState<DateRange>({ from: null, to: null });
    const [startDateContract, setStartDateContract] = useState<DateRange>({ from: null, to: null });
    const [nextEndDateContract, setNextEndDateContract] = useState<DateRange>({ from: null, to: null });
    const [updatedAt, setUpdatedAt] = useState<DateRange>({ from: null, to: null });
    const [ratedDate, setRatedDate] = useState<DateRange>({ from: null, to: null });

    // Track event
    const trackAllFilterBtn = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * @param q track userinput
     */
    const trackSearch = (q: string) => {
        trackSiteSearch({ keyword: `${q}`, category: "Tender" });
    };
    /**
     * @param event open menu with views
     */
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close menu
     */
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    /**
     * close drawer with all filters
     */
    const closeDrawer = () => {
        setOpen(false);
    };

    /**
     * Close searchrule wizard
     */
    const closeModal = () => {
        setOpenModal(false);
    };

    const history = useLocation();
    // Check if user is on review page
    const reviewPage = history.pathname === "/tenders/reviews";
    // Check if user is on worklist page
    const worklist = history.pathname === "/tenders/worklist";
    // all page
    const allpage = history.pathname === "/tenders";
    // Check if device is mobile or not
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    // Number of filters that are active
    const amount = filterData?.filter((f) => f.active === true).length;
    // Number of filters that are not allowed to use in search rules
    const wrongFilters = filterData?.filter((f) => f.searchRuleFilter === false && f.active === true);
    const allowedFilters = filterData?.filter((f) => f.searchRuleFilter === true && f.active === true);
    // state for ca filter search
    const [caSearchQuery, setCaSearchQuery] = useState<string>("");
    const [resetCustomFilter, setResetCustomFilter] = useState<boolean>(false);
    //custom options for ca filter
    const { setCustomOptionsWorklist, setCustomOptionsReviews, setCustomOptionsAll } = useCustomOptions();
    useEffect(() => {
        // get query from session storage
        if (sessionStorage.getItem("tcaf")) {
            setCaSearchQuery(JSON.parse(sessionStorage.getItem("tcaf") || ""));
        }
    }, []);

    /**
     * Reset handler to remove session storage key with data
     * CA filter
     */
    const handleResetCustomFilter = () => {
        if (history.pathname === "/tenders/worklist") {
            setCustomOptionsWorklist([]);
            sessionStorage.removeItem("cow");
        }
        if (history.pathname === "/tenders/reviews") {
            setCustomOptionsReviews([]);
            sessionStorage.removeItem("cor");
        }
        if (history.pathname === "/tenders") {
            setCustomOptionsAll([]);
            sessionStorage.removeItem("coa");
        }
    };

    /**
     * Get allowed filters for searchrule and create object to send to wizard
     * allowedFilters -> filter out filters that are not allowed to use in searchrule wizard
     * map entries in searchInput and check if allowedFilters include a title that is equal to a title in searchInput
     * if true, create object with key and value(s) from searchInput
     * As result, merge all objects in one object as key: value pairs like TenderFilterInput
     */
    const filterdFilters = Object.entries(searchInput)
        .map(([key, val]) => (allowedFilters?.map((w) => w.title).includes(key) ? { [key]: val } : null))
        .filter((f) => f !== null)
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});

    /**
     * Check if selection contains only text (+specials) and numbers
     * Max 2 spaces, no special characters ('-' only)('*' first word only)
     */
    // const reg = new RegExp(/^[A-Za-zÀ-ȕ\d-*]+(\s[A-Za-zÀ-ȕ\d-]*){0,2}$/).test(query);
    // const newReg = new RegExp(/^[A-Za-zÀ-ȕ\d-~"*]+(\s[A-Za-zÀ-ȕ\d-"~]*){0,2}$/).test(query);

    const complexReg = new RegExp(
        /^(("([A-Za-zÀ-ȕ\d-]+(\s[A-Za-zÀ-ȕ\d-]+)*)?"~\d+)(\sOR\s("([A-Za-zÀ-ȕ\d-]+(\s[A-Za-zÀ-ȕ\d-]+)*)?"~\d+))*$|^([A-Za-zÀ-ȕ\d-]+\*)$)/
    ).test(query);

    const array_of_splitted_query_words = query.split(" ");
    // check if reg is true and query consists of several words.
    // check if first word has an "*" as last character
    // if its a '*' user can't type more words
    // other characters will be validated by regex

    const queryAcceptable =
        complexReg &&
        array_of_splitted_query_words[0].charAt(array_of_splitted_query_words[0].length - 1) === "*" &&
        array_of_splitted_query_words.length === 1
            ? true
            : complexReg &&
              array_of_splitted_query_words[0].charAt(array_of_splitted_query_words[0].length - 1) !== "*" &&
              array_of_splitted_query_words.length > 0
            ? true
            : false;

    const queryContainsAsteriskOrTilde = query.includes("*") || query.includes("~");

    // Check to show add button or disable button based on userinput
    const showAddBtn =
        // no terms and at least 1 allowed filter
        (query.length === 0 && _.isEmpty(filterdFilters) === false && visible === true && loading === false) ||
        // query at least 3 characters without filters
        (query.length > 2 && queryAcceptable && queryContainsAsteriskOrTilde && visible === true && loading === false) ||
        // query at least 3 characters and at least 1 allowed filter
        (query.length > 2 &&
            queryAcceptable &&
            queryContainsAsteriskOrTilde &&
            visible === true &&
            loading === false &&
            _.isEmpty(filterdFilters) === false);

    return (
        // Disable whole component if loading is set to true
        <Disable disabled={loading} disabledOpacity={1}>
            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                    <SearchField
                        // Modal to create a search rule
                        openModal={() => {
                            if (amount !== undefined && amount > 0 && wrongFilters !== undefined && wrongFilters.length > 0) {
                                setOpenModal(true);
                            } else {
                                setOpenPopup("tender");
                            }
                        }}
                        // Userinput in searchbox
                        query={query}
                        // Summary // marked docs // all docs
                        target={target}
                        // if userinput changes -> setQuery to new input and reset page to page 1
                        onChangeQuery={(q) => {
                            setQuery(q);
                            resetPage();
                            if (q.length > 2) {
                                trackSearch(q);
                            }
                        }}
                        // If user changes target -> set new target and reset page to page 1
                        onChangeTarget={(target) => {
                            setTarget(target);
                            resetPage();
                        }}
                        // Refetch query with new params
                        onSearch={() => {
                            startSearch();
                        }}
                        // boolean to show/enable/disable button to create a search rule
                        visible={showAddBtn}
                        allpage={allpage}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {/* 
                        Small menu for worklist and reviewpage, worklist has a fourth option available
                        not visible on all tender page
                       // TODO: translation
                    */}
                    {/* 
                    // TODO: enable when another view is working
                     */}

                    {/*
                     * Tooltip shows up when user clicks on a view thats not set as preferred view,
                     * User can navigate to preferences to change the preferred view
                     * changeview boolean is to check if a user's current view is preferred or not
                     */}
                    {changeView === true && noMobile && (
                        <InfoButtonTooltip
                            tooltipText={
                                <>
                                    Deze weergave als standaardweergave instellen? <br /> Ga naar de voorkeurenpagina of{" "}
                                    <Link to={`/profile/settings`} style={{ color: "#cccccc" }}>
                                        Klik hier
                                    </Link>
                                </>
                            }
                        />
                    )}

                    {viewButtons && onViewClick && noMobile && (
                        <ViewMenu
                            views={views}
                            handleClose={handleCloseMenu}
                            activeView={activeView}
                            onViewClick={(view) => {
                                onViewClick(view);
                            }}
                            handleClick={handleOpenMenu}
                            anchorEl={anchorEl}
                            worklist={worklist}
                            allPage={allpage}
                            reviewPage={reviewPage}
                        />
                    )}
                </div>
            </div>
            <div style={{ height: 20 }} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {noMobile && (
                        // Bar with topfilters and active filters
                        <TopFilterBar
                            pageview="tenders"
                            deadline={deadline}
                            setDeadline={setDeadline}
                            firstPublication={firstPublication}
                            setFirstPublication={setFirstPublication}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            endDateInc={endDateInc}
                            setEndDateInc={setEndDateInc}
                            filterData={filterData ? filterData : []}
                            resetPage={resetPage}
                            onChange={onChange}
                            startSearch={startSearch}
                            searchInput={searchInput}
                            awardDate={awardDate}
                            setAwardDate={setAwardDate}
                            startDateContract={startDateContract}
                            setStartDateContract={setStartDateContract}
                            nextEndDateContract={nextEndDateContract}
                            setNextEndDateContract={setNextEndDateContract}
                            updatedAt={updatedAt}
                            setUpdatedAt={setUpdatedAt}
                            ratedDate={ratedDate}
                            setRatedDate={setRatedDate}
                            setOpen={setOpen}
                            caSearchQuery={caSearchQuery}
                            setCaSearchQuery={setCaSearchQuery}
                            resetCustomFilter={resetCustomFilter}
                            setResetCustomFilter={setResetCustomFilter}
                        />
                    )}
                    {/* Button to open all filters drawer */}
                    <Button
                        sx={{ color: "#000000" }}
                        startIcon={<TuneIcon />}
                        size="small"
                        onClick={() => {
                            setOpen(true);
                            trackAllFilterBtn({ category: "Tender", action: "Click-show-all—filters" });
                        }}
                        style={{ marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0 }}
                    >
                        {t("allFilters")}
                    </Button>
                    {allpage === true && sessionStorage.getItem("atf") !== "{}" && !loading && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setCaSearchQuery("");
                                setResetCustomFilter(true);
                                handleResetCustomFilter();
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                    {reviewPage === true && sessionStorage.getItem("rtf") !== `{"reviews":[1]}` && !loading && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setCaSearchQuery("");
                                setResetCustomFilter(true);
                                handleResetCustomFilter();
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                    {worklist === true && sessionStorage.getItem("twf") !== "{}" && !loading && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setCaSearchQuery("");
                                setResetCustomFilter(true);
                                handleResetCustomFilter();
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                </div>

                {/* 
                    Div with actions 
                    TODO: archive and delete hidden when user hasn't select anything
                */}
                <TenderTableActions
                    worklist={worklist}
                    reviewPage={reviewPage}
                    activeView={activeView}
                    loading={loading}
                    allpage={allpage}
                    totalTenders={totalTenders}
                    searchInput={searchInput}
                    query={query}
                    target={target}
                    order={order}
                    orderField={orderField}
                    columns={columns}
                />
            </div>
            <TenderSearchRuleWizard
                open={openModal}
                onClose={closeModal}
                amount={amount}
                openWizard={() => setOpenPopup("tender")}
                wrongFilters={wrongFilters}
            />
            {/* // TODO: fill editRule with storage data */}
            <SearchWizardContainer
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                editRule={{
                    id: "newTenderFromList",
                    name: "",
                    state: null,
                    distribution: null,
                    query: query,
                    target: target,
                    filters: JSON.stringify(filterdFilters),
                    color: "#9e9e9e",
                }}
                resetFilters={resetFilters}
            />
            <FilterDrawer
                loading={loading}
                deadline={deadline}
                setDeadline={setDeadline}
                firstPublication={firstPublication}
                setFirstPublication={setFirstPublication}
                endDate={endDate}
                setEndDate={setEndDate}
                endDateInc={endDateInc}
                setEndDateInc={setEndDateInc}
                open={open}
                onClose={closeDrawer}
                filterData={filterData ? filterData : []}
                searchInput={searchInput}
                onChange={onChange}
                startSearch={startSearch}
                location={"tenders"}
                awardDate={awardDate}
                setAwardDate={setAwardDate}
                startDateContract={startDateContract}
                setStartDateContract={setStartDateContract}
                nextEndDateContract={nextEndDateContract}
                setNextEndDateContract={setNextEndDateContract}
                updatedAt={updatedAt}
                setUpdatedAt={setUpdatedAt}
                ratedDate={ratedDate}
                setRatedDate={setRatedDate}
                caSearchQuery={caSearchQuery}
                setCaSearchQuery={setCaSearchQuery}
            />
        </Disable>
    );
};

export default TenderSearch;
