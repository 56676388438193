import { QUERY_CURRENT_USER_SECTORS } from "../graphql/queryDefCurrentUser";
import { QUERY_GETLABELMATCHPARAMS_WITH_LABELS } from "../graphql/queryDefSearchAndHighlighting";
import { GET_TENDER_SECTORS } from "../graphql/queryDefinitions";
import gqlClient from "../graphql/gqlClient";

/**
 * Check if tender matches with searchrule or sector(s) on subscription
 * @param tenderID id of tender to check
 * @returns boolean
 */
export const DoesSectorMatchWithUserSubscription = async (tenderID: number) => {
    try {
        // Fetch all data concurrently
        const [searches, currentUserSectors, tenderData] = await Promise.all([
            gqlClient.query({
                query: QUERY_GETLABELMATCHPARAMS_WITH_LABELS,
                variables: { tender_id: tenderID },
                fetchPolicy: "network-only",
            }),
            gqlClient.query({
                query: QUERY_CURRENT_USER_SECTORS,
                fetchPolicy: "network-only",
            }),
            gqlClient.query({
                query: GET_TENDER_SECTORS,
                variables: { id: tenderID.toString() },
                fetchPolicy: "network-only",
            }),
        ]);

        // Extract sector IDs from the user's subscriptions
        const sectorIds = currentUserSectors.data?.currentUser.employee.organization.subscriptions.flatMap(
            (s: any) => s.sectors?.map((se: any) => se?.id) || []
        );

        if (!sectorIds || sectorIds.length === 0 || !tenderData) {
            return false;
        }

        // Extract tender sector IDs
        const tenderSectorIds = tenderData.data?.tender?.sectors.flatMap((s: any) => s.parent?.id);

        // TESTCASE
        // const excludedIds = ["80", "124"]; // IDs to exclude
        // const filteredSectorIds = tenderSectorIds.filter((id: any) => !excludedIds.includes(id));

        // Check if user has acces to sector(s) of tender
        const doesContain = sectorIds.some((id: string) => tenderSectorIds?.includes(id));

        // Check if there are search results
        const hasSearchResult = (searches.data?.GetSearchTenderMatch?.length ?? 0) > 1;

        // Return true if either condition is met
        return doesContain || hasSearchResult;
    } catch (error) {
        return false; // Fallback in case of errors
    }
};
