import { gql } from "@apollo/client";

//? get contract duration
export const GET_INITIAL_DURATION_DATA = gql`
    query GetInitialDuration($id: ID!) {
        tender(id: $id) {
            id
            contract_duration {
                lots
                value
            }
        }
    }
`;

//? gettype_procedure
export const GET_PROCEDURE_TYPE_COLUMN = gql`
    query GetProcedureTypeColumn($id: ID!) {
        tender(id: $id) {
            id
            type_procedure
        }
    }
`;

//? get type
export const GET_TYPE_COLUMN = gql`
    query GetTypeColumn($id: ID!) {
        tender(id: $id) {
            id
            translated_type
        }
    }
`;

//? get status
export const GET_STATUS_COLUMN = gql`
    query GetStatusColumn($id: ID!) {
        tender(id: $id) {
            id
            status
        }
    }
`;

//? get contract status
export const GET_CONTRACT_STATUS_COLUMN = gql`
    query GetStatusContractColumn($id: ID!) {
        tender(id: $id) {
            id
            status_contract {
                lots
                value
            }
        }
    }
`;

//? get sectors
export const GET_SECTORS_COLUMN = gql`
    query GetSectorsColumn($id: ID!) {
        tender(id: $id) {
            id
            sectors {
                id
                name
                parent {
                    id
                    name
                }
            }
        }
    }
`;

//? get type update
export const GET_UPDATE_TYPE_COLUMN = gql`
    query GetTypeUpdateColumn($id: ID!) {
        tender(id: $id) {
            id
            type_update
        }
    }
`;

//? get options
export const GET_OPTIONS_COLUMN = gql`
    query GetOptionsColumn($id: ID!) {
        tender(id: $id) {
            id
            options {
                lots
                value
            }
        }
    }
`;

//? get contract_description
export const GET_CONTRACT_DESCRIPTION_COLUMN = gql`
    query GetContractDescriptionColumn($id: ID!) {
        tender(id: $id) {
            id
            contract_description {
                lots
                value
            }
        }
    }
`;

//? get looptijd start
export const GET_DATE_START_COLUMN = gql`
    query GetDateStartColumn($id: ID!) {
        tender(id: $id) {
            id
            looptijd_start {
                lots
                value
            }
        }
    }
`;

//? get looptijd einde
export const GET_DATE_END_COLUMN = gql`
    query GetDateEndColumn($id: ID!) {
        tender(id: $id) {
            id
            looptijd_einde {
                lots
                value
            }
        }
    }
`;

//? get next looptijd einde
export const GET_NEXT_DATE_END_COLUMN = gql`
    query GetDateNextEndColumn($id: ID!) {
        tender(id: $id) {
            id
            next_looptijd_einde {
                lots
                value
            }
        }
    }
`;

//? get datum gunning
export const GET_AWARD_DATE_COLUMN = gql`
    query GetAwardDateColumn($id: ID!) {
        tender(id: $id) {
            id
            datum_gunning
        }
    }
`;

//? get date incl opties
export const GET_DATE_INCL_OPTIONS_COLUMN = gql`
    query GetDateIncOptionsColumn($id: ID!) {
        tender(id: $id) {
            id
            looptijd_einde_incl_opties {
                lots
                value
            }
        }
    }
`;

//? get updated_at
export const GET_UPDATED_AT_COLUMN = gql`
    query GetUpdatedAtColumn($id: ID!) {
        tender(id: $id) {
            id
            updated_at
        }
    }
`;

//? get first dispatch
export const GET_FIRST_DISPATCH_COLUMN = gql`
    query GetFirstDispatchColumn($id: ID!) {
        tender(id: $id) {
            id
            first_dispatchdate
        }
    }
`;

//? get published_vestigingsplaats
export const GET_PUBLISHED_PLACE_COLUMN = gql`
    query GetPublishedPlaceColumn($id: ID!) {
        tender(id: $id) {
            id
            published_vestigingsplaats
            country {
                alpha2
            }
        }
    }
`;

//? get published_adres
export const GET_PUBLISHED_ADRES_COLUMN = gql`
    query GetPublishedAddressColumn($id: ID!) {
        tender(id: $id) {
            id
            published_adres
        }
    }
`;

//? get published_postcode
export const GET_PUBLISHED_POSTAL_COLUMN = gql`
    query GetPublishedPostalColumn($id: ID!) {
        tender(id: $id) {
            id
            published_postcode
        }
    }
`;
//? get published_host
export const GET_PUBLISHED_HOST_COLUMN = gql`
    query GetPublishedHostColumn($id: ID!) {
        tender(id: $id) {
            id
            published_host
        }
    }
`;

//? get published_persoon
export const GET_PUBLISHED_CONTACT_PERSOON_COLUMN = gql`
    query GetPublishedContactPersoonColumn($id: ID!) {
        tender(id: $id) {
            id
            published_contactpersoon
        }
    }
`;

//? get published_phone
export const GET_PUBLISHED_PHONE_COLUMN = gql`
    query GetPublishedPhoneColumn($id: ID!) {
        tender(id: $id) {
            id
            published_telefoon
        }
    }
`;

//? get published_phone
export const GET_PUBLISHED_EMAIL_COLUMN = gql`
    query GetPublishedEmailColumn($id: ID!) {
        tender(id: $id) {
            id
            published_email
        }
    }
`;

//? get userstate
export const GET_USERSTATE_COLUMN = gql`
    query GetUserStateColumn($id: ID!) {
        tender(id: $id) {
            id
            userStatus
        }
    }
`;

//? get market party
export const GET_MARKET_PARTY_COLUMN = gql`
    query GetMarketPartyColumn($id: ID!) {
        tender(id: $id) {
            id
            published_participants
            bids {
                id
                winner
                lot {
                    id
                    number
                }
                marketParty {
                    id
                    name
                    visit_addresses {
                        id
                        city
                    }
                }
            }
            published_participants
        }
    }
`;

//? get market party
export const GET_BID_VALUE_COLUMN = gql`
    query GetBidValueColumn($id: ID!) {
        tender(id: $id) {
            id
            published_participants
            bids {
                totalValue {
                    currency
                    id
                    value
                }
                id
                bid
                winner
                combination
                lot {
                    id
                    number
                }
            }
        }
    }
`;

//? get market party
export const GET_SCOPE_COLUMN = gql`
    query GetScopeColumn($id: ID!) {
        tender(id: $id) {
            id
            scope {
                id
                min {
                    id
                    value
                    currency
                }
                max {
                    id
                    value
                    currency
                }
                total {
                    id
                    value
                    currency
                }
            }
        }
    }
`;

export const GET_ENDDATE_OF_TENDER_FOR_MP_COLUMN = gql`
    query GetEnddateForMPColumn($id: ID!) {
        tender(id: $id) {
            id
            looptijd_einde_incl_opties {
                lots
                value
            }
            bids {
                lot {
                    id
                    number
                    next_looptijd_einde
                }
                id
                winner
                marketParty {
                    id
                }
            }
            lots {
                id
            }
        }
    }
`;

export const GET_FIRST_ENDDATE_OF_TENDER_FOR_MP_COLUMN = gql`
    query GetFirstEnddateForMPColumn($id: ID!) {
        tender(id: $id) {
            id
            next_looptijd_einde {
                lots
                value
            }
            bids {
                lot {
                    id
                    number
                    next_looptijd_einde
                }
                id
                winner
                marketParty {
                    id
                }
            }
            lots {
                id
            }
        }
    }
`;

export const GET_WINNER_OF_TENDER_FOR_MP_COLUMN = gql`
    query GetWinnerForTenderColumn($id: ID!) {
        tender(id: $id) {
            id
            status
            bids {
                lot {
                    id
                    number
                }
                preliminaryAwarded
                id
                winner
                marketParty {
                    id
                }
            }
            lots {
                id
            }
        }
    }
`;
