import React from "react";
import { Avatar, AvatarGroup, Button, Tooltip } from "@mui/material";
import { ListItem, ListItemText, ListItemIcon, Typography, IconButton } from "@mui/material";

// Icons used on this page
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import { useQuery } from "@apollo/client";
import { QUERY_INITIALS } from "../../../../graphql/queryDefCurrentUser";
import { QueryInitials } from "../../../../__generated__/QueryInitials";
import { FindInPage } from "@mui/icons-material";
import OppSearchLineSummarySentence from "../OpportunityRules/OppSearchLineSummarySentence";

interface Props {
    searchline: GetUserSearchRules_currentUser_opportunitySearches;

    handleOpenCopy: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const OppSearchlineSettingsOnlyCopy: React.FC<Props> = ({ searchline, handleOpenCopy }) => {
    const bgColor = "#f9f9f9";
    const green = "#225E4D";

    const { data } = useQuery<QueryInitials>(QUERY_INITIALS);

    return (
        <ListItem
            style={{
                marginBottom: "5px",
                borderLeft: `5px solid ${green}`,
                backgroundColor: bgColor,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: "40px",
                    opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                }}
                style={{ color: green }}
            >
                <IconButton disabled style={{ marginRight: "8px", marginLeft: "-8px" }}>
                    <FindInPage sx={{ color: green }} />
                </IconButton>
            </ListItemIcon>

            <ListItemText
                sx={{
                    color: green,
                    opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                }}
                /**
                 * primary = title of searchline
                 */
                primary={
                    // Inline name editor
                    // showEdit ? (
                    // <Input
                    //     name={"name"}
                    //     value={searchState.name}
                    //     // onchange handler to change function and push with mutation to server
                    //     onChange={handleChangeSearch("name")}
                    //     endAdornment={
                    //         <InputAdornment position="end">
                    //             <IconButton
                    //                 aria-label="save name"
                    //                 size="small"
                    //                 onClick={() => {
                    //                     updateSearchRule();
                    //                     setShowEdit(false);
                    //                 }}
                    //             >
                    //                 <Check />
                    //             </IconButton>
                    //         </InputAdornment>
                    //     }
                    // />
                    // ) : (
                    // Show name of searchline
                    <Typography style={{ display: "flex", alignItems: "center", marginTop: 3, marginBottom: 3 }}>
                        {searchline.name}
                        {/* <IconButton style={{ width: 12, height: 12 }} onClick={() => setShowEdit(true)}>
                        <Edit style={{ width: 16, height: 16 }} />
                    </IconButton> */}
                    </Typography>
                    // )
                }
                /**
                 * secondary = summary of searchline
                 * TODO, user can edit inline. add regions, add or remove searchterms.
                 * create react fragment to add more features
                 */
                secondary={<OppSearchLineSummarySentence searchline={searchline} />}
            />

            <Tooltip disableInteractive placement={"right-start"} title={<div>{data?.currentUser.employee.name}</div>}>
                <AvatarGroup
                    max={4}
                    sx={{
                        cursor: "pointer",
                        marginRight: "8px",
                        "& .MuiAvatar-root": {
                            border: "1px solid #ffffff",
                            width: (theme) => theme.spacing(3.5),
                            height: (theme) => theme.spacing(3.5),
                            fontSize: "0.8rem",
                        },
                    }}
                >
                    <Avatar sx={{ backgroundColor: "#225E4D" }}>
                        {data?.currentUser.employee.name?.slice(0, 1)}
                        {data?.currentUser.employee.familyname?.slice(0, 1)}
                    </Avatar>
                </AvatarGroup>
            </Tooltip>

            <React.Fragment>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={handleOpenCopy}>Kopiëren</Button>
                </div>
            </React.Fragment>
        </ListItem>
    );
};

export default OppSearchlineSettingsOnlyCopy;
