import React, { useState, useEffect, useCallback } from "react";
import { Paper, Button, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import setTheme from "../../../utils/setTheme";
import { materialTheme } from "../../../assets/styles";

//components used in this page
import ResultsModal from "./ResultsModal";
import DomainModal from "./DomainModal";
import DistModal from "./DistModal";
import { useTranslation } from "react-i18next";
import {
    DistributionIntervalInput,
    SearchDistributionMoment,
    SortOrder,
    TenderFilterInput,
    TenderFilterSource,
    TenderFilterTarget,
    TenderSearchInput,
} from "../../../__generated__/globalTypes";
import {
    FilterSearchRuleTenders,
    FilterSearchRuleTendersVariables,
    FilterSearchRuleTenders_filterTenders_filters,
    FilterSearchRuleTenders_filterTenders_results,
} from "../../../__generated__/FilterSearchRuleTenders";
import { toast } from "react-toastify";
import { SaveSearch, SaveSearchVariables } from "../../../__generated__/SaveSearch";
import { Disable } from "react-disable";
import { GetUserSearchRules_currentUser_searches } from "../../../__generated__/GetUserSearchRules";
import { UpdateTenderSearchRule, UpdateTenderSearchRuleVariables } from "../../../__generated__/UpdateTenderSearchRule";
import { useTour } from "../../../components/contextProviders/TourContext";
import { useCustomSearchOptions } from "../../../components/contextProviders/SearchRuleCustomOptionProvider";
import { SAVESEARCHRULE, SET_SEARCH_DISTRIBUTION, UPDATESEARCHRULE } from "../../../graphql/mutationDefinitions";
import { QUERY_ALL_SEARCHTERMS, QUERY_SEARCHRULES, QUERY_USER_IS_SUB_ADMIN } from "../../../graphql/queryDefCurrentUser";
import { QUERY_TENDERS } from "../../../graphql/queryDefinitions";
import { GetWordArray } from "../../../__generated__/GetWordArray";
import { setSearchDistribution, setSearchDistributionVariables } from "../../../__generated__/setSearchDistribution";
import { SHARE_TENDER_SEARCH } from "../SearchRule/CopySearchline";
import { shareTenderSearch, shareTenderSearchVariables } from "../../../__generated__/shareTenderSearch";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";
import { CheckIfUserIsAdmin } from "../../../__generated__/CheckIfUserIsAdmin";
import { REMOVE_USER_FROM_SEARCH } from "../../../graphql/mutationDefProjects";
import { removeUserFromSearch, removeUserFromSearchVariables } from "../../../__generated__/removeUserFromSearch";
import WordModalQueryBuilder from "./WordModalQueryBuilder";
import { generateUniqueId, useQueryBuilder } from "./QueryBuilder/QueryBuilderContext";
import { useLocation } from "react-router-dom";
import { useCustomOptions } from "../../../components/contextProviders/TenderCustomOptionProvider";

interface Props {
    onClose(): void;
    editRule: GetUserSearchRules_currentUser_searches;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    stepTitles: string[];
}

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.ALL_TENDERS,
    target: TenderFilterTarget.SUMMARY,
    filters,
};

// TODO: add filtered tenders query to this component
const defaultVariables: FilterSearchRuleTendersVariables = {
    page: 1,
    count: 50,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const TenderWizardBasePlate: React.FC<Props> = ({ onClose, editRule, activeStep, setActiveStep, stepTitles }) => {
    const { t } = useTranslation();
    const client = useApolloClient();

    const {
        query,
        domain,
        filters,
        name,
        moment,
        dist,
        sector,
        checkedSub,
        country,
        staticLoad,
        copyIDS,
        shareIDS,
        color,
        idsToDelete,
        setIdsToDelete,
        setQuery,
        setDomain,
        setFilters,
        setName,
        setMoment,
        setDist,
        setSector,
        setCheckedSub,
        setStaticLoad,
        copySearchTo,
        shareSearchWith,
        setColor,
        setCountry,
        reset,
    } = useTenderSearchContext(); // Use the context hook to access context value and function

    const { setCustomOptions } = useCustomSearchOptions();
    const {
        areThereAnyWordsSelected,
        advancedQueryString,
        handleGenerateQuery,
        resetAdvancedQuery,
        areThereNEARConditionsNotValid,
        // containsOnlyOperator,
        createKeywordwithConditions,
        detectSingleOperatorUsage,
        setGroups,
        groups,
    } = useQueryBuilder();
    const { pathname } = useLocation();

    // state for active page
    const [page] = useState(defaultVariables.page);
    // state for rows per page
    const [rowsPerPage] = useState(defaultVariables.count);
    // TourOpener
    const { setOpenTour, setTourVariables, setCurrentStep } = useTour();
    const { refetch } = useQuery<GetWordArray>(QUERY_ALL_SEARCHTERMS);

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("Laden...");
    // get tenderdata based on query and domain state
    const [filterData, setFilterData] = useState<FilterSearchRuleTenders_filterTenders_filters[] | null>([]);
    // get tenderfilterdate based on query and domain state
    const [tendersData, setTendersData] = useState<FilterSearchRuleTenders_filterTenders_results | null>();

    /**
     * Define the GQL query
     */
    const { data: adminCheck } = useQuery<CheckIfUserIsAdmin>(QUERY_USER_IS_SUB_ADMIN, {
        fetchPolicy: "network-only",
    });

    // Check if any group has conditions
    const doesGroupContainConditions = (): boolean => {
        return groups.some((group) => group.conditions && group.conditions.length > 0);
    };

    /**
     * Check if editRule is defined
     * This should mean a user want to edit a rule
     */
    useEffect(() => {
        if (editRule) {
            /**
             * If query is not empty, set chosenWords to query of editRule
             */
            if (!!editRule.query) {
                // Returns a single operator or false (when multiple operators used)
                const checkOperators = detectSingleOperatorUsage(editRule.query);

                if (checkOperators !== false) {
                    const conditions = createKeywordwithConditions(editRule.query, checkOperators);

                    setGroups([
                        {
                            id: generateUniqueId(), // Unique identifier for each group
                            conditions: conditions, // Array of conditions in the group
                        },
                    ]);
                }
            }

            const sharewithUsers = editRule.participants ? editRule.participants.filter((u) => u?.id !== localStorage.getItem("user_id")) : [];
            if (sharewithUsers.length > 0) {
                shareSearchWith(sharewithUsers.map((u) => parseInt(u?.id as string)));
            }
            /**
             * Set domain to domain of editRule
             */
            setDomain(editRule.target as TenderFilterTarget);
            /**
             * Parse filterdata
             */
            const filterValues = JSON.parse(editRule.filters);

            /**
             * Create objects of filtervalues if they exist
             */
            const mappedFilterLabels = filterValues && Object.entries(filterValues);
            /**
             * Get values of filter with title "subSectors"
             * if they exist -> fill checkedSub with values
             */
            const subSectorValues = mappedFilterLabels && mappedFilterLabels.filter(([key, value]: any) => key === "subSectors");
            const sectorValues = mappedFilterLabels && mappedFilterLabels.filter(([key, value]: any) => key === "sectors");
            const countryValues = mappedFilterLabels && mappedFilterLabels.filter(([key, value]: any) => key === "country");
            const mapCountries = countryValues.map(([key, value]: any) => value);

            if (countryValues && countryValues.length > 0) {
                setCountry(mapCountries[0].map((country: any) => parseInt(country)));
            }

            if (
                subSectorValues &&
                subSectorValues.length > 0
                // && editRule.query?.length === 0
            ) {
                /**
                 * Map subsector values
                 */
                const mapping = subSectorValues.map(([key, value]: any) => value);
                /**
                 * Set values to checkedSub state => this will be used to check checkboxes and disable searchTerm list
                 */
                setCheckedSub(mapping[0].map((item: any) => item));
            }

            if (sectorValues && sectorValues.length > 0) {
                /**
                 * Map sector values
                 */
                const mapping = sectorValues.map(([key, value]: any) => value);

                /**
                 * Set values to checkedSub state => this will be used to check checkboxes and disable searchTerm list
                 */
                setSector(mapping[0].map((item: any) => item));
            }

            /**
             * Set filter to all filtervalues the user has chosen before
             */
            setFilters(filterValues);
            /**
             * if editRule.id === "newTenderFromList" means that user has clicked on 'add button' in tender overview
             */
            if (editRule && editRule.id === "newTenderFromList") {
                // setQuery if user has input otherwise empty string
                setQuery(editRule.query ? editRule.query : "");

                //Empty name -> user can give a name
                setName("");

                // setDist to default -> worklist:true
                setDist({
                    worklist: true,
                    in_app: true,
                    email_interval: DistributionIntervalInput.DAILY,
                });

                // setStep to 2 -> resultmodal (cant go back)
                setActiveStep(3);
            } else {
                setName(editRule.name);
                /**
                 * Set dist to dist of searchrule the user wants to edit
                 */
                // const distributionsetting = JSON.parse(editRule.distribution);

                // const updateEmail = distributionsetting.emails?.map((mail: any) => ({
                //     email: mail.email,
                //     interval: mail.interval.toUpperCase(),
                // }));

                // moment to be result
                setMoment(editRule.moment);
                setColor(editRule.color);

                setDist({
                    in_app: editRule.pivot?.in_app as boolean,
                    email_interval: editRule.pivot?.email_interval as DistributionIntervalInput,
                    worklist: editRule.pivot?.worklist as boolean,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        editRule,
        setActiveStep,
        setCheckedSub,
        setColor,
        setCountry,
        setDist,
        setDomain,
        setFilters,
        setMoment,
        setName,
        setQuery,
        setSector,
        shareSearchWith,
    ]);

    // Define the GQL query
    const [run, { error }] = useLazyQuery<FilterSearchRuleTenders, FilterSearchRuleTendersVariables>(QUERY_TENDERS, {
        variables: {
            page: page,
            count: rowsPerPage,
            orderField: defaultVariables.orderField,
            order: defaultVariables.order,
            search: {
                source: TenderFilterSource.ALL_TENDERS,
                target: domain,
                query: query,
                filters: filters,
            },
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterTenders && data.filterTenders !== undefined && data.filterTenders !== null) {
                setFilterData(data.filterTenders.filters);
                setTendersData(data.filterTenders.results);
                setLoading(false);
            }
        },
    });
    const [setDistribution, { loading: loaddist, error: errodist }] = useMutation<setSearchDistribution, setSearchDistributionVariables>(
        SET_SEARCH_DISTRIBUTION
    );

    const [share_tndr_search, { loading: loadingCopy, error: errorCopy }] = useMutation<shareTenderSearch, shareTenderSearchVariables>(
        SHARE_TENDER_SEARCH
    );
    const [removeUsers] = useMutation<removeUserFromSearch, removeUserFromSearchVariables>(REMOVE_USER_FROM_SEARCH);

    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<SaveSearch, SaveSearchVariables>(SAVESEARCHRULE);
    const [update, { loading: loadingUpdateMutation, error: errorUpdateMutation }] = useMutation<
        UpdateTenderSearchRule,
        UpdateTenderSearchRuleVariables
    >(UPDATESEARCHRULE);

    const variables = {
        page: page,
        count: rowsPerPage,
        orderField: defaultVariables.orderField,
        order: defaultVariables.order,
        search: {
            source: TenderFilterSource.ALL_TENDERS,
            target: domain,
            query: query,
            filters: filters,
        },
    };

    /**
     * stop sharing with selected user
     */
    const stopShareTenderSearch = async () => {
        /**
         * foreach user id in checked => send mutation
         */
        if (idsToDelete.length > 0) {
            try {
                removeUsers({
                    variables: {
                        user_ids: idsToDelete,
                        search_id: parseInt(editRule.id),
                    },
                    refetchQueries: [QUERY_SEARCHRULES, "GetUserSearchRules"],
                });
            } catch (e) {
                // toast.error("Fout tijdens opslaan (catch)");
            } finally {
                setIdsToDelete([]);
            }
        }
    };

    /**
     * Function to update an existing searchrule
     */
    const updateSearchRule = async () => {
        if (errorMutation) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await update({
                variables: {
                    color: color,
                    id: editRule?.id,
                    name: name,
                    search: {
                        query: query,
                        filters: filters,
                        target: domain,
                    },
                    moment: moment as SearchDistributionMoment,
                },
                onCompleted: () => {
                    setActiveStep(0);
                    onClose();
                    reset();
                    resetAdvancedQuery();
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
        setCustomOptions([]);
    };

    const saveSearchRule = async () => {
        if (errorUpdateMutation) {
            toast.error("Fout tijdens opslaan (error)");
            return;
        }

        try {
            const result = await save({
                variables: {
                    color: color,
                    name: name,
                    search: {
                        query: query,
                        filters: filters,
                        target: domain,
                    },
                    moment: moment as SearchDistributionMoment,
                },
            });

            // Save Distribution
            const saveDistributionPromise = new Promise<void>((resolve, reject) => {
                const saveDist = async () => {
                    try {
                        const addMyself = shareIDS.concat(parseInt(localStorage.getItem("user_id") as string));
                        if (addMyself.length > 0) {
                            for (const user_id of addMyself) {
                                if (errodist) {
                                    // toast.error("Fout tijdens opslaan (error)");
                                    reject("Error during distribution");
                                }

                                await setDistribution({
                                    variables: {
                                        search_id: result.data?.saveTenderSearch?.id as string,
                                        user_id: user_id.toString(),
                                        distribution: {
                                            in_app: dist.in_app,
                                            worklist: dist.worklist,
                                            email_interval: dist.email_interval,
                                        },
                                    },
                                });
                            }
                        }
                        handleResetCustomFilter();
                        resolve(); // Success
                    } catch (e) {
                        toast.error("Fout tijdens opslaan");
                        reject("Error during distribution");
                    }
                };
                saveDist();
            });

            // Copy Tender Search
            const copyTenderSearchPromise = new Promise<void>((resolve, reject) => {
                if (copyIDS.length > 0) {
                    const copyTender = async () => {
                        try {
                            for (const user_id of copyIDS) {
                                if (loadingCopy) {
                                    // Optionally handle loading state here
                                }
                                if (errorCopy) {
                                    toast.error("Er ging iets mis met het kopiëren");
                                }
                                await share_tndr_search({
                                    variables: {
                                        user_id: user_id,
                                        search_id: parseInt(result.data?.saveTenderSearch?.id as string),
                                    },
                                    onCompleted: () => {
                                        toast.success("Zoekregel gedeeld", { autoClose: 1500 });
                                    },
                                });
                            }
                            resolve(); // Success
                        } catch (e) {
                            toast.error("Er ging iets mis met het kopiëren");
                            reject("Error during copying");
                        } finally {
                            copySearchTo([]);
                        }
                    };
                    copyTender();
                } else {
                    resolve(); // No users to copy to
                }
            });

            // Wait for both promises to finish
            await Promise.all([saveDistributionPromise, copyTenderSearchPromise]);

            // After all completed, refetch the queries and close modal
            client.refetchQueries({
                include: ["GetUserSearchRules"],
            });
            setActiveStep(0);
            resetAdvancedQuery();
            onClose();
            reset();
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        } finally {
            setCustomOptions([]);
        }
    };

    /**
     * Run query whenever a state changes
     */
    useEffect(() => {
        setLoading(true);
        run({
            variables: {
                page: page,
                count: rowsPerPage,
                orderField: defaultVariables.orderField,
                order: defaultVariables.order,
                search: {
                    source: TenderFilterSource.ALL_TENDERS,
                    target: domain,
                    query: query,
                    filters: filters,
                },
            },
        });
    }, [run, page, rowsPerPage, query, domain, filters]);

    /**
     * switch case for active step
     * show different components based on active step
     */
    const getStepContent = (step: number) => {
        switch (step) {
            default:
                return "Zoekregel wizard!";
            case 0:
                // add option to add/select search terms
                return <WordModalQueryBuilder query={query} setQuery={setQuery} type={"tender"} />;

            // case 1:
            //     // Show region selector
            //     return <RegionModal />;
            case 1:
                // add option to change domain in query
                return <DomainModal domain={domain} setDomain={setDomain} />;
            case 2:
                // show results based on chosen terms (step 1) and chosen domain (step 2)

                return (
                    <ResultsModal
                        tenderData={tendersData?.data}
                        filterData={filterData ? [...filterData] : null}
                        loading={loading}
                        loadingText={loadingText}
                        error={error}
                        totalItems={tendersData?.paginatorInfo.total}
                        count={tendersData?.paginatorInfo.count}
                        startSearch={() => {
                            setLoading(true);
                            run({ variables });
                        }}
                    />
                );
            case 3:
                // add option to choose distribution settings
                return (
                    <DistModal
                        editRule={Boolean(editRule)}
                        isAdmin={Boolean(adminCheck)}
                        color={color}
                        name={name}
                        setName={setName}
                        setDist={setDist}
                        dist={dist}
                        type={"tender"}
                        shareSearchWith={shareSearchWith}
                        copySearchTo={copySearchTo}
                        copyIDS={copyIDS}
                        shareIDS={shareIDS}
                        tenderDomain={domain}
                        tenderFilters={filters}
                    />
                );
        }
    };

    // Get total amount of steps
    const totalSteps = () => {
        return stepTitles.length;
    };

    //
    /**
     * Get last step
     * @returns last step boolean
     */
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    /**
     * Reset tour variable
     */
    const resetTour = () => {
        setOpenTour(false);
        setTourVariables([], "#173155", "");
        setCurrentStep(0);
    };

    // Handler to go to next step
    // Check if next step is last step. If so, save mutation and close modal
    const handleNext = () => {
        // Word modal
        if (activeStep === 0) {
            // If active step is 0
            // and chosenWords.length > 0, set query to single string with OR operator
            if (areThereAnyWordsSelected()) {
                handleGenerateQuery();

                setFilters({ country: country });
                if (editRule && checkedSub.length === 0 && sector.length > 0) {
                    setFilters(JSON.parse(editRule.filters));
                }
                setStaticLoad(true);
                // reset tour variables to avoid errors
                resetTour();
                // after 400ms go to next step
                setTimeout(() => {
                    setActiveStep(1);
                    setStaticLoad(false);
                }, 400);
            }
            // set country filter to country state
            if (country.length > 0) {
                setFilters({ ...filters, country: country });
            }
            // if user has subSectors selected => set subSecors as active filter
            // with the chosen subsectors as values.
            // empty the query
            // setactivestep to 2 => Resultsmodal
            if (checkedSub.length > 0 || sector.length > 0) {
                !doesGroupContainConditions() ? setActiveStep(2) : setActiveStep(1);
                const updatedFilters: TenderFilterInput = { country: country };

                if (checkedSub.length > 0) {
                    updatedFilters.subSectors = checkedSub;
                }

                if (sector.length > 0) {
                    updatedFilters.sectors = sector;
                }

                if (editRule) {
                    // if editRule is set, set filters to the filters of the editRule and update subSectors if user adds a subSector
                    setFilters({ ...filters, ...updatedFilters });
                } else {
                    setFilters(updatedFilters);
                }
            }

            // Refetch wordquery
            refetch();
        }
        ///  Region filter
        //!  if (activeStep === 1) {
        //!    set region filter to chosen options
        //!    setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //! }

        // Domain modal
        // if active step is 1 => run variables with and setactivestep to resultsmodal on nextclick
        if (activeStep === 1) {
            // Domain modal
            setLoading(true);
            run({ variables });
            setStaticLoad(true);
            // reset tour variables to avoid errors
            resetTour();
            // after 400ms go to next step
            setTimeout(() => {
                setActiveStep(2);
                setStaticLoad(false);
            }, 400);
        }
        // Resultmodal
        if (activeStep === 2) {
            setStaticLoad(true);
            // reset tour variables to avoid errors
            resetTour();
            // after 400ms go to next step
            setTimeout(() => {
                setActiveStep(3);
                setStaticLoad(false);
            }, 400);
        }
    };

    // SET QUERY TO ADVANCED QUERY STRING
    useEffect(() => {
        if (query !== advancedQueryString) {
            setQuery(advancedQueryString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedQueryString, query]);

    /**
     * Handler to save searchrule
     */
    const saveSearchRuleAndClose = async () => {
        if (name.length >= 3 && moment !== null) {
            saveSearchRule();
            resetTour();
        }
    };

    /**
     * Handler to update searchrule
     */
    const updateSearchRuleAndClose = async () => {
        if (idsToDelete.length > 0 && editRule.id !== "newTenderFromList") {
            stopShareTenderSearch();
        }

        if (name.length >= 3 && moment !== null) {
            updateSearchRule();
            resetTour();
        }
    };

    /**
     * Handler to go to previous step
     */
    const handleBack = () => {
        if (activeStep === 2) {
            if (checkedSub.length > 0 || sector.length > 0) {
                setActiveStep(0);
            } else if (!doesGroupContainConditions() && checkedSub.length === 0 && sector.length === 0) {
                setActiveStep(0);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    /**
     * Blue colorTheme
     */
    setTheme("blue");
    const [loadTextIndex, setLoadTextIndex] = useState(0);
    const shuffle = useCallback(() => {
        const names = ["Laden...", "Documenten doorzoeken...", "Resultaten verzamelen..."];
        // const index = Math.floor(Math.random() * names.length);
        setLoadingText(names[loadTextIndex]);
        setLoadTextIndex(loadTextIndex + 1);
    }, [loadTextIndex]);

    useEffect(() => {
        if (loading) {
            const intervalid = setInterval(shuffle, 3000);
            return () => clearInterval(intervalid);
        } else {
            setLoadingText("Laden...");
            setLoadTextIndex(0);
        }
        return;
    }, [loading, shuffle]);

    const { setCustomOptionsWorklist, setCustomOptionsReviews, setCustomOptionsAll } = useCustomOptions();

    /**
     * Reset handler to remove session storage key with data
     * CA filter +
     * all other filters
     */
    const handleResetCustomFilter = () => {
        if (pathname === "/tenders/worklist") {
            setCustomOptionsWorklist([]);
            sessionStorage.removeItem("cow");
            sessionStorage.removeItem("twf");
        }
        if (pathname === "/tenders/reviews") {
            setCustomOptionsReviews([]);
            sessionStorage.removeItem("cor");
            sessionStorage.removeItem("rtf");
        }
        if (pathname === "/tenders") {
            setCustomOptionsAll([]);
            sessionStorage.removeItem("coa");
            sessionStorage.removeItem("atf");
        }
    };

    return (
        <ThemeProvider theme={materialTheme}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                {/* Paper element contains activestep */}
                <Paper
                    sx={{
                        height: `calc(93vh - 64px)`,
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f4f4f4",
                    }}
                    elevation={0}
                >
                    <div>{getStepContent(activeStep)}</div>
                </Paper>
                {/* Navigation to switch to different steps.
                    - if active step is 3, show button with "save" instead of "next"
                    - if active step is 0, dont show "back" button
               */}
                <div style={{ display: "flex", flexDirection: "row-reverse", width: "90%" }}>
                    {!isLastStep() && activeStep === 0 ? (
                        <Disable
                            disabled={
                                (!areThereAnyWordsSelected() && checkedSub.length === 0 && sector.length === 0) || areThereNEARConditionsNotValid()
                            }
                            data-tut="reactour__nextBtn"
                        >
                            <Button
                                disabled={!areThereAnyWordsSelected() && checkedSub.length === 0 && sector.length === 0}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {`${t("mainModal.Next")}`} {staticLoad && <CircularProgress size={14} sx={{ marginLeft: "4px", color: "#ffffff" }} />}
                            </Button>
                        </Disable>
                    ) : !isLastStep() ? (
                        <Button variant="contained" color="primary" onClick={handleNext} data-tut="reactour__nextBtn">
                            {`${t("mainModal.Next")}`} {staticLoad && <CircularProgress size={14} sx={{ marginLeft: "4px", color: "#ffffff" }} />}
                        </Button>
                    ) : editRule && editRule.id !== "newTenderFromList" ? (
                        /**
                         * If editRule is true, show button with update mutation instead of save mutation.
                         * If name of searchrule is smaller than 3 characters, or no dist.moment is not set or disable save button
                         */
                        <Disable disabled={loadingUpdateMutation || name.length < 3 || typeof moment !== "string"} disabledOpacity={1}>
                            <Button
                                disabled={loadingUpdateMutation || name.length < 3 || typeof moment !== "string"}
                                variant="contained"
                                color="primary"
                                onClick={updateSearchRuleAndClose}
                            >
                                {/* Show loading spinner when saving searchrule */}
                                {loadingUpdateMutation ? (
                                    <>
                                        {`${t("mainModal.Save")}`} <CircularProgress size={14} style={{ marginLeft: 4 }} />
                                    </>
                                ) : (
                                    `${t("mainModal.Save")}`
                                )}
                            </Button>
                        </Disable>
                    ) : (
                        /**
                         * If editRule is false, show button with save mutation instead of update mutation.
                         * If name of searchrule is smaller than 3 characters, or no dist.moment is not set or disable save button
                         */
                        <Disable
                            disabled={loadingMutation || loaddist || name.length < 3 || typeof moment !== "string"}
                            disabledOpacity={1}
                            data-tut="reactour__distSave"
                        >
                            <Button
                                disabled={loadingMutation || loaddist || name.length < 3 || typeof moment !== "string"}
                                variant="contained"
                                color="primary"
                                onClick={saveSearchRuleAndClose}
                            >
                                {/* Show loading spinner when saving searchrule */}
                                {loadingMutation || loaddist ? (
                                    <>
                                        {`${t("mainModal.Save")}`} <CircularProgress size={14} style={{ marginLeft: 4 }} />
                                    </>
                                ) : (
                                    `${t("mainModal.Save")}`
                                )}
                            </Button>
                        </Disable>
                    )}

                    {/*
                     * Show backbutton when id is not newTenderFromList
                     * if id is newTenderFromList -> show previous button only on distribution page
                     */}
                    {editRule && editRule.id === "newTenderFromList" ? (
                        <Button
                            style={{ display: activeStep === 0 ? "none" : activeStep === 2 ? "none" : "block" }}
                            onClick={handleBack}
                            data-tut="reactour__distSave"
                        >
                            {t("mainModal.Previous")}
                        </Button>
                    ) : (
                        <Button style={{ display: activeStep === 0 ? "none" : "block" }} onClick={handleBack}>
                            {t("mainModal.Previous")}
                        </Button>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default TenderWizardBasePlate;
