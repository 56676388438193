import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import MainLayout from "../../components/layout/MainLayout";
import { Route, Routes, useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import setTheme from "../../utils/setTheme";
import { greyTheme } from "../../assets/styles";
import ColumnDetailContainer from "./ColumnDetailContainer";

interface Props {
    a?: number;
}

const ColumnsModule: React.FC<Props> = (props) => {
    const history = useLocation();
    setTheme("darkGray");

    const { trackPageView } = useMatomo();

    // Track page view
    trackPageView({
        documentTitle: "Columns", // optional
        href: `https://tool.tender.guide${history.pathname}`, // optional
    });

    return (
        <ThemeProvider theme={greyTheme}>
            <MainLayout page="columns">
                <Routes>
                    {/* Enrichment routes */}
                    {/* Tender for users routes */}
                    <Route path="" element={<ColumnDetailContainer />} />

                    <Route path=":id" element={<ColumnDetailContainer />} />
                    {/* <Route path="*" element={<NotFound404 url={"/columns"} />} /> */}
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default ColumnsModule;
