import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
// gql imports
import { useLazyQuery, useQuery } from "@apollo/client";
import { Disable } from "react-disable";
import moment from "moment";
import {
    GetTenderDocsWithPagination,
    GetTenderDocsWithPaginationVariables,
    GetTenderDocsWithPagination_tenderDocuments,
} from "../../../../__generated__/GetTenderDocsWithPagination";
import { GetDocumentsList, GetDocumentsListVariables } from "../../../../__generated__/GetDocumentsList";
import WidgetBox from "../../../../components/WidgetBox";
import Search from "../../../../components/tenderDetail/widgets/Search";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import MarkedDocGroup from "../../../../components/tenderDetail/widgets/MarkedDocGroup";
import MarkedDocument from "../../../../components/tenderDetail/widgets/MarkedDocument";
import DocumentGroup from "../../../../components/tenderDetail/widgets/DocumentGroup";
import ListOfDocs from "../../../../components/tenderDetail/widgets/ListOfDocs";
import { QUERY_GETDOCUMENTSLIST, QUERY_GETPAGINATIONDOCLIST } from "../../../../graphql/queryDefinitions";
import { DoesSectorMatchWithUserSubscription } from "../../../../hooks/isAccountAllowed";

interface Props {
    tender_id: string;
    documentDateArray: DocProps[];
}
export interface DocProps {
    __typename: "DocumentDateCount";
    amount: string;
    date: string;
}
/**
 * Widget for documents on Tender Detail page
 * - Show interactive list of documents
 */
const Documents: React.FC<Props> = ({ tender_id, documentDateArray }) => {
    const { t } = useTranslation();
    const [showMarked, setShowMarked] = useState(false);
    const [folder, setFolder] = useState("");
    const [DocsData, setDocsData] = useState<GetTenderDocsWithPagination_tenderDocuments | null>();
    const [expanded, setExpanded] = useState<string | false>(documentDateArray[0].date);
    const [count, setCount] = useState(25);
    const [isVisible, setIsVisible] = useState(false); // State to control visibility
    const [loading, setLoading] = useState(true); // Loading state for UI feedback

    // expand accordion based on lot ID
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        setFolder("");
    };

    // state to switch between show and hide marked docs
    const showMarkedDocs = () => {
        setShowMarked(!showMarked);
    };

    /**
     * query for all documents with pagination
     */
    const [run, { error: DocsError, loading: DocsLoading }] = useLazyQuery<GetTenderDocsWithPagination, GetTenderDocsWithPaginationVariables>(
        QUERY_GETPAGINATIONDOCLIST,
        {
            variables: { tender_id: parseInt(tender_id), folder: folder },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                setDocsData(data.tenderDocuments);
            },
        }
    );

    // Set page title
    useEffect(() => {
        if (expanded !== false) {
            run({
                variables: {
                    tender_id: parseInt(tender_id),
                    folder: folder,
                    date: moment(expanded).format("YYYY-MM-DD"),
                    count: count,
                },
            });
        } else {
            run({
                variables: {
                    tender_id: parseInt(tender_id),
                    folder: folder,
                    count: count,
                },
            });
        }
    }, [run, folder, tender_id, expanded, count]);

    /**
     * query for marked documents
     */
    const { data } = useQuery<GetDocumentsList, GetDocumentsListVariables>(QUERY_GETDOCUMENTSLIST, {
        variables: { id: tender_id },
    });

    /**
     * short name for data.tender
     */
    const list = data ? data.tender : null;

    useEffect(() => {
        const checkVisibility = async () => {
            setLoading(true); // Set loading to true while checking
            const result = await DoesSectorMatchWithUserSubscription(parseInt(tender_id));
            setIsVisible(result); // Update visibility state
            setLoading(false); // Loading done
        };

        checkVisibility();
    }, [tender_id]);

    /**
     * @param content Return folders/files
     */
    const renderPage = (content: React.ReactNode) => (
        <WidgetBox header={t("tenderPage.Documents")} highlight="blue" padding={0} elevation={0} actions={<div style={{ height: 30 }} />}>
            {/* div with searchbar
             * marked documents button
             * downloadbutton */}
            <Search
                downloadable={isVisible}
                marked={showMarked}
                relevantAmount={list !== null ? list.relevantDocuments.length : 0}
                handleMarkedDocs={showMarkedDocs}
                tenderId={tender_id}
            />
            {/* Div shows up when user clicks on button
             *  Default is hide */}
            {content}
        </WidgetBox>
    );
    /**
     * If DocsData is empty and DocsLoading is true, show spinner
     */
    if ((!DocsData && DocsLoading) || loading) {
        return renderPage(<FullPageSpinner />);
    }

    /**
     * Check if error occured
     */
    if (DocsError || DocsData === undefined || DocsData === null) {
        return renderPage(<p>error</p>);
    }

    /**
     * Set folder to clicked folder
     */
    const handleDirectoryClick = (item: string) => {
        setCount(25);
        setFolder(folder + `/${item}`);
    };

    return renderPage(
        <div>
            {showMarked && (
                <React.Fragment>
                    <MarkedDocGroup
                        tender_id={tender_id}
                        label={t("tenderPage.MarkedDocs")}
                        amountDocuments={list?.relevantDocuments.length}
                        defaultExpanded
                    >
                        {list?.relevantDocuments.map(
                            ({
                                id,
                                created_at,
                                filename,

                                extension,
                                temporary_link,
                                // lot
                            }) => {
                                return (
                                    <MarkedDocument
                                        downloadable={isVisible}
                                        label={filename}
                                        icon={extension}
                                        key={id}
                                        date={created_at}
                                        link={temporary_link}
                                        file_id={id}
                                        tender_id={tender_id}
                                        // lotNumber={lot?.number}
                                        lotNumber={undefined}
                                        lots={[]}
                                    />
                                );
                            }
                        )}
                    </MarkedDocGroup>
                    <Divider />
                </React.Fragment>
            )}
            <Disable disabled={DocsLoading} disabledOpacity={0.6}>
                {documentDateArray
                    .sort((a, b) => (moment(b.date).isBefore(moment(a.date)) ? -1 : 1))
                    .map((d, i) => {
                        return (
                            <div key={i}>
                                <DocumentGroup
                                    downloadable={isVisible}
                                    loading={DocsLoading}
                                    tender_id={tender_id}
                                    expanded={expanded}
                                    handleChange={handleChange(d.date)}
                                    label={moment(d.date || "").format("LL")}
                                    clicked={d.date}
                                    amountDocuments={parseInt(d.amount)}
                                    defaultExpanded={i === 0}
                                >
                                    <ListOfDocs
                                        downloadable={isVisible}
                                        setCount={setCount}
                                        count={count}
                                        loading={DocsLoading}
                                        tender_id={tender_id}
                                        setFolder={setFolder}
                                        folder={folder}
                                        data={DocsData}
                                        onDirectoryClick={handleDirectoryClick}
                                        date={d.date}
                                    />
                                </DocumentGroup>
                                {i !== documentDateArray.length - 1 && <Divider />}
                            </div>
                        );
                    })}
            </Disable>
        </div>
    );
};
export default Documents;
