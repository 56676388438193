import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import SearchTermList from "../SearchTerm/SearchTermList";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";
import ReactCountryFlag from "react-country-flag";
import SectorSearch from "./SectorSearch";
import { ExpandMore } from "@mui/icons-material";
import QueryBuilder from "./QueryBuilder/QueryBuilder";
import { useTranslation } from "react-i18next";
import InformationDialog from "./InformationDialog";
import { useQueryBuilder } from "./QueryBuilder/QueryBuilderContext";

interface Props {
    type: "tender" | "opportunity";
    query: string;
    setQuery: (query: React.SetStateAction<string>) => void;
}

const WordModalQueryBuilder: React.FC<Props> = ({ type, query, setQuery }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addCondition } = useQueryBuilder();
    const { areThereAnyWordsSelected, handleGenerateQuery } = useQueryBuilder();

    const { checkedSub, sector } = useTenderSearchContext(); // Use the context hook to access context value and function
    const [expanded, setExpanded] = useState<string | false>(false);

    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Check  if user has a sector or subsector chosen without words. open accordion
    useEffect(() => {
        if (checkedSub.length > 0 && !areThereAnyWordsSelected()) {
            setExpanded("panel1");
        } else if (sector.length > 0 && !areThereAnyWordsSelected()) {
            setExpanded("panel1");
        } else {
            setExpanded(false);
        }
    }, [areThereAnyWordsSelected, checkedSub.length, sector.length]);

    /**
     * chosenWords -> words that are selected by the user
     */
    const [openDialog, setOpenDialog] = useState(false);

    /**
     * handler to close subsectorpanel
     */
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**
     * handler to close the information dialog
     */
    const handleCloseInformationDialog = () => {
        setOpenDialog(false);
    };

    /**
     * Add a word to the searchterm selection for this specific search rule.
     * @param s selected word to add to selection for this search rule
     */
    const handleSelect = (s: string) => {
        setExpanded(false);
        addCondition(s);
        handleGenerateQuery();
    };

    /**
     *  Text for dialog that shows up when user clicks on ( i )
     */
    const textHelper = "?";

    // TODO: remove hide to show systemrules option 4.x
    const hide = false;
    const shouldReturnTrue: boolean = (sector.length > 0 && !areThereAnyWordsSelected) || (checkedSub.length > 0 && !areThereAnyWordsSelected);

    return (
        <Grid container spacing={3} sx={{ height: "85vh", width: "90vw" }}>
            {/* Left side: Word List */}
            <Grid item xs={3} sx={{ height: "100%" }}>
                <SearchTermList onSelect={handleSelect} subSector={shouldReturnTrue} />
            </Grid>

            {/* Right side: Query Builder and Expandable Option */}
            <Grid item xs={9} sx={{ height: "100%" }}>
                <div style={{ width: onlyMobile ? "100%" : undefined }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <h3>{t("wordModal.chosenterms")}</h3>
                        {/* Infobutton to open a modal with helptext */}
                        {/* <IconButton size="small" style={{ marginLeft: 4 }}>
                                <InfoOutlinedIcon fontSize="small" onClick={handleClickOpenInformationDialog} />
                            </IconButton> */}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", height: type === "tender" ? `calc(100% - 56px)` : "calc(100% - 22px)" }}>
                    <Paper
                        variant={type === "tender" ? "outlined" : "elevation"}
                        elevation={0}
                        sx={{ marginBottom: "32px", height: "100%", overflow: "auto" }}
                    >
                        <QueryBuilder themeColor={type === "tender" ? "#173357" : "#225E4D"} hideButtons />
                    </Paper>

                    {type === "tender" && (
                        <Accordion
                            data-tut="reactour__searchWithSubSector"
                            disabled={areThereAnyWordsSelected()}
                            variant="outlined"
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                        >
                            {/*
                             * HeadingBlock  inside summary
                             */}
                            <AccordionSummary
                                sx={{
                                    maxHeight: "50px",
                                    "& .MuiAccordionSummary-root": { minHeight: "48px !important", maxHeight: "48px !important" },
                                    "& .Mui-expanded": { minHeight: "48px !important", maxHeight: "48px !important" },
                                }}
                                expandIcon={<ExpandMore />}
                            >
                                <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
                                    {query.length !== 0 ? "Deelsector kan in de resultatenstap als filter worden geactiveerd" : "Of kies deelsector"}
                                    {/*
                                     *  Show countryflag with textual helper
                                     */}
                                    <Tooltip placement="right" title={"Deelsectoren zijn alleen beschikbaar voor Nederland"}>
                                        <div style={{ display: "inline", height: 26, marginLeft: 4 }}>
                                            <ReactCountryFlag
                                                style={{
                                                    height: 11,
                                                    marginLeft: 4,
                                                }}
                                                countryCode={"NL"}
                                                svg
                                            />
                                        </div>
                                    </Tooltip>
                                </Typography>
                            </AccordionSummary>
                            {/* sectorsearch component*/}
                            <SectorSearch />
                        </Accordion>
                    )}
                    {type === "opportunity" && hide && (
                        <Accordion
                            data-tut="reactour__searchWithSubSector"
                            disabled={query.length !== 0}
                            variant="outlined"
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                        >
                            {/*
                             * HeadingBlock  inside summary
                             */}
                            <AccordionSummary
                                sx={{
                                    "& .MuiAccordionSummary-root": { minHeight: "48px !important", maxHeight: "48px !important" },
                                    "& .Mui-expanded": { minHeight: "48px !important", maxHeight: "48px !important" },
                                }}
                                expandIcon={<ExpandMore />}
                            >
                                <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
                                    Of kies een suggestie
                                </Typography>
                            </AccordionSummary>
                            {/* sectorsearch component*/}
                            {/* //TODO: Add checked systemrule query data (setQuery(checked)) */}
                            {/* <SystemRuleSearch words={query} checked={checkedSub} setChecked={setCheckedSub} expanded={expanded} /> */}
                        </Accordion>
                    )}

                    {/* InformationDialog for user to see information for the search options. */}
                    <InformationDialog
                        open={openDialog}
                        onClose={handleCloseInformationDialog}
                        dialogTitle={"Informatievenster"}
                        dialogText={textHelper}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default WordModalQueryBuilder;
