import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import CopySearchLine from "../CopySearchline";
import OppSearchlineSettingsOnlyCopy from "./OppSearchlineSettingsOnlyCopy";

interface Props {
    searchline: GetUserSearchRules_currentUser_opportunitySearches;
}

export interface Color {
    id: string;
    colors: string[];
}

const OppSearchlineCopyOnly: React.FC<Props> = ({ searchline }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openShare, setOpenShare] = useState<boolean>(false);

    const handleOpenShare = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Open menu with colleagues and teams
        setAnchorEl(event.currentTarget);
        setOpenShare(true);
    };

    const handleCloseShare = () => {
        // Open share menu
        setOpenShare(false);
        setAnchorEl(null);
    };

    if (isMobile) {
        return <></>;
    }

    return (
        <React.Fragment>
            <OppSearchlineSettingsOnlyCopy searchline={searchline} handleOpenCopy={handleOpenShare} />

            <CopySearchLine
                type={"opportunity"}
                searchID={searchline.id}
                shareOpened={openShare}
                anchorEl={anchorEl}
                handleCloseSub={handleCloseShare}
                teamsComponent={[]}
                colleaguesComponents={[]}
            />
        </React.Fragment>
    );
};

export default OppSearchlineCopyOnly;
