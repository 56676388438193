import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import {
    AccountTree,
    ArrowDropDown,
    ArrowDropUp,
    Business,
    Description,
    DesktopWindowsOutlined,
    Notifications,
    People,
    Today,
    TrendingUp,
} from "@mui/icons-material";

interface Props {
    handleView: (view: string) => void;
}

interface Category {
    icon: keyof typeof iconMap | undefined; // Change to allow only keys of iconMap
    link: string;
    name: string;
}

const iconMap: { [key: string]: React.ElementType } = {
    DesktopWindowsOutlined: DesktopWindowsOutlined,
    AccountTree: AccountTree,
    Today: Today,
    People: People,
    Description: Description,
    TrendingUp: TrendingUp,
    Business: Business,
    Notifications: Notifications,
};

const categories: Category[] = [
    { icon: "DesktopWindowsOutlined", link: "dashboard", name: "Dashboard" },
    { icon: "AccountTree", link: "projects", name: "Projecten" },
    { icon: "Today", link: "calender", name: "Agenda" },
    { icon: "People", link: "organizations", name: "Marktpartijen" },
    { icon: "Description", link: "tenders", name: "Aanbestedingen" },
    { icon: "TrendingUp", link: "opportunities", name: "Kansen" },
    { icon: "Business", link: "contracting_authorities", name: "Aanbestedende diensten" },
    { icon: "Notifications", link: "notifications", name: "Meldingen" },
    { icon: undefined, link: "personal", name: "Persoonlijk" },
    { icon: undefined, link: "users_teams", name: "Gebruikers & teams" },
    { icon: undefined, link: "searches", name: "Zoekregels" },
    { icon: undefined, link: "preferences", name: "Voorkeuren" },
    { icon: undefined, link: "logbook", name: "Logboek" },
    { icon: undefined, link: "column", name: "Columns" },
    { icon: undefined, link: "general", name: "Algemeen" },
];

const CatSelector: React.FC<Props> = ({ handleView }) => {
    const [cat, showcat] = useState<boolean>(true);

    const getCategoryIcon = (iconName: keyof typeof iconMap | undefined): React.ReactElement | null => {
        const IconComponent = iconName ? iconMap[iconName] : undefined;
        if (IconComponent) {
            return <IconComponent />;
        } else {
            return null;
        }
    };

    const mappedCategories = categories.map((category) => ({
        ...category,
        icon: getCategoryIcon(category.icon),
    }));

    return (
        <React.Fragment>
            <strong>
                <Typography
                    variant="body1"
                    onClick={() => showcat(!cat)}
                    sx={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "0px 18px", fontWeight: 600, marginBottom: "2px" }}
                >
                    Categorieën{cat ? <ArrowDropUp /> : <ArrowDropDown />}
                </Typography>
                {cat && (
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            overflowX: "auto",
                            paddingBottom: "8px",
                            margin: "0px 14px",
                        }}
                    >
                        {mappedCategories.map(({ icon, link, name }) => {
                            return (
                                <Paper
                                    key={name}
                                    sx={{
                                        fontWeight: "normal",
                                        height: "24px",
                                        width: "fit-content",
                                        padding: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "4px",
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: "#f3f3f3",
                                        },
                                    }}
                                    onClick={() => {
                                        handleView(link);
                                    }}
                                >
                                    {icon && React.cloneElement(icon, { sx: { fontSize: "16px", marginRight: "4px", color: "#707070" } })}
                                    {name}
                                </Paper>
                            );
                        })}
                    </Box>
                )}
            </strong>
        </React.Fragment>
    );
};

export default CatSelector;
