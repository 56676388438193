import React from "react";
// import useGlobalState from "../state/useGlobalState";
import IconButton from "@mui/material/IconButton";

// Import material icons
// import ZoomInIcon from "@mui/icons-material/ZoomIn";
// import ZoomOutIcon from "@mui/icons-material/ZoomOut";
// import RotateRightIcon from "@mui/icons-material/RotateRight";
// import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import GetAppIcon from "@mui/icons-material/GetApp";
// import { useZoom } from "../../contextProviders/ZoomContext";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import { Launch } from "@mui/icons-material";

interface Props {
    module_item_id?: string;
    doc_id?: string;
    title?: string;
    /**
     * url to load pdf
     */
    url?: string;
    onClose?(): void;
    downloadable: boolean;
}

const PdfToolbar: React.FC<Props> = ({ title, module_item_id, doc_id, url, onClose, downloadable }) => {
    const { pathname } = useLocation();
    // const { zoom, setZoom } = useZoom();
    // const [zoom, setZoom] = useState(1);

    // const zoomIn = () => {
    //     setZoom(zoom * 1.2);
    // };

    // const zoomOut = () => {
    //     setZoom(zoom / 1.2);
    // };

    const firstPath = pathname.split("/")[1];

    return (
        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            {/* <Tooltip title="Inzoomen" placement="bottom">
                <IconButton size="small" onClick={zoomIn}>
                    <ZoomInIcon  sx={{ color:"#ffffff" }}  />
                </IconButton>
            </Tooltip>

            <Tooltip title="Uitzoomen" placement="bottom">
                <IconButton size="small" onClick={zoomOut}>
                    <ZoomOutIcon  sx={{ color:"#ffffff" }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Pagina draaien" placement="bottom">
                <IconButton size="small">
                    <RotateLeftIcon  sx={{ color:"#ffffff" }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Pagina draaien" placement="bottom">
                <IconButton size="small">
                    <RotateRightIcon  sx={{ color:"#ffffff" }}  />
                </IconButton>
            </Tooltip> */}
            {/* {pathname.includes("admin") && url && onClose && ( */}
            {url && onClose && downloadable && (
                <Tooltip title="Open in nieuw tabblad" placement="bottom">
                    <IconButton
                        style={{ marginRight: 4 }}
                        size="small"
                        onClick={(e) => {
                            if (downloadable) {
                                // Stop click on listitem to prevent document-viewer to initiate
                                // create link and add url + target blank on click
                                e.stopPropagation();
                                const element = document.createElement("a");
                                element.setAttribute("href", `${url}`);
                                element.setAttribute("target", `_blank`);
                                element.style.display = "none";
                                document.body.appendChild(element);
                                element.click();
                                // remove link attributes
                                document.body.removeChild(element);
                                // Close drawer
                                onClose();
                            }
                        }}
                    >
                        <Launch sx={{ color: "#ffffff" }} />
                    </IconButton>
                </Tooltip>
            )}
            {firstPath === "tenders" && downloadable && (
                <Tooltip title="Download pdf" placement="bottom">
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            if (module_item_id && doc_id) {
                                // Stop click on listitem to prevent document-viewer to initiate
                                e.stopPropagation();
                                const element = document.createElement("a");
                                element.setAttribute("download", `${title}`);
                                element.setAttribute("href", `${process.env.REACT_APP_API_ROOT}/tenders/${module_item_id}/downloadFile/${doc_id}`);
                                element.style.display = "none";
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }
                        }}
                    >
                        <GetAppIcon sx={{ color: "#ffffff" }} />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default PdfToolbar;
