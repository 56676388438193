import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Paper, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { WEBINAR_LINK } from "../../graphql/queryDefUserAndTeams";
import { webinar } from "../../__generated__/webinar";
import { Forward10, Pause, PlayArrow, Replay10, VolumeOff, VolumeUp } from "@mui/icons-material";

interface Props {
    a?: number;
}

// Styled Paper component
const StyledPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    maxWidth: "60vw",
    margin: "0 auto",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
        // For screens smaller than 'sm' (mobile)
        maxWidth: "90vw", // Set max-width to 95% of the viewport width on mobile
    },
}));

const WebinarViewer: React.FC<Props> = (props) => {
    const { data: webinardata } = useQuery<webinar>(WEBINAR_LINK);
    // Reference to the video element
    const videoReff = useRef<HTMLVideoElement | null>(null);

    // State to track if the video is playing or paused
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);

    // Function to mute/unmute the video
    const toggleMute = () => {
        if (videoReff.current) {
            videoReff.current.muted = !videoReff.current.muted;
            setIsMuted(videoReff.current.muted);
        }
    };

    // Function to jump forward by 10 seconds
    const skipForward = () => {
        if (videoReff.current) {
            videoReff.current.currentTime += 10; // Skip forward by 10 seconds
        }
    };

    // Function to jump backward by 10 seconds
    const skipBackward = () => {
        if (videoReff.current) {
            videoReff.current.currentTime -= 10; // Skip backward by 10 seconds
        }
    };

    // Function to toggle play/pause
    const togglePlay = () => {
        if (videoReff.current) {
            if (videoReff.current.paused) {
                videoReff.current.play();
                setIsPlaying(true);
            } else {
                videoReff.current.pause();
                setIsPlaying(false);
            }
        }
    };

    if (!webinardata?.currentUser.webinar) {
        return <></>;
    }

    return (
        <>
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Webinar
            </Typography>
            <StyledPaper>
                <div
                    style={{
                        position: "relative",
                        display: "block",
                        margin: "0 auto",
                        width: "100%",
                        maxWidth: "100%",
                        height: "100%",
                    }}
                >
                    <div>
                        <video ref={videoReff} width="100%" height="auto">
                            <source src={webinardata?.currentUser.webinar} type="video/mp4" />
                            {/* // Your browser does not support the video tag. //{" "} */}
                        </video>

                        {!isPlaying && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    zIndex: 2,
                                    cursor: "pointer",
                                    backgroundColor: "#00000050",
                                    marginBottom: "6px",
                                }}
                                onClick={togglePlay}
                            >
                                <IconButton
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        width: "100px",
                                        height: "100px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <PlayArrow style={{ fontSize: "64px", color: "#173357" }} />
                                </IconButton>
                            </div>
                        )}
                    </div>
                </div>
                {/* Custom Controls */}
                <div className="custom-controls" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {/* Mute/Unmute Button */}
                    <IconButton onClick={toggleMute}>{isMuted ? <VolumeOff /> : <VolumeUp />}</IconButton>

                    <div>
                        <IconButton onClick={skipBackward}>
                            <Replay10 />
                        </IconButton>

                        {/* Play/Pause Button */}
                        <IconButton onClick={togglePlay}>{isPlaying ? <Pause /> : <PlayArrow />}</IconButton>

                        {/* Forward/Backward Buttons */}
                        <IconButton onClick={skipForward}>
                            <Forward10 />
                        </IconButton>
                    </div>
                    <div style={{ width: "40px" }} />
                </div>
            </StyledPaper>
        </>
    );
};

export default WebinarViewer;
