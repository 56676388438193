import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
// import { Link } from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, IconButton, Toolbar, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import moment from "moment";
import ScheduleTimeLineItem from "../ScheduleTimeLineItem";
import { GET_TENDER_INCOMPATIBLE_DATES } from "../../graphql/queryDefinitions";
import { GetTenderIncompatibleDates, GetTenderIncompatibleDatesVariables } from "../../__generated__/GetTenderIncompatibleDates";
import { useQuery } from "@apollo/client";

interface Props {
    handleClose(): void;
    open: boolean;
    host: string | null;
    hostUrl: string | null;
    tender_id: string;
    hideHoliday: boolean;
    onHideholiday: () => Promise<void>;
    itemToCal: any;
    onHidereplacedItems: () => Promise<void>;
    hideReplacedItems: boolean;
    handleAdd: (item: any, state: boolean) => void;
    showSource: boolean;
    showHandleAdd: boolean;
    refactoredArray: {
        id: string;
        planned: any;
        name: string;
        source: any;
        isReplacedBy: any;
        isStored: boolean;
    }[];
    deadline: string;
    isResultOrSectorMatch: boolean;
}

const PlanningExpandModal: React.FC<Props> = ({
    handleClose,
    open,
    refactoredArray,
    host,
    hostUrl,
    tender_id,
    itemToCal,
    handleAdd,
    showSource,
    showHandleAdd,
    hideReplacedItems,
    onHidereplacedItems,
    onHideholiday,
    hideHoliday,
    deadline,
    isResultOrSectorMatch,
}) => {
    const { t } = useTranslation();

    const white = "#ffffff";
    const todayIndicator = "#173357";
    const [centered, setToCenter] = useState<boolean>(false);

    const { data } = useQuery<GetTenderIncompatibleDates, GetTenderIncompatibleDatesVariables>(GET_TENDER_INCOMPATIBLE_DATES, {
        variables: { id: tender_id },
    });

    /**
     * refactor array based on hide/show repalced items
     */
    const refactor = hideReplacedItems
        ? refactoredArray.filter((item) => item.isReplacedBy === null || item.isReplacedBy === undefined)
        : refactoredArray;

    /**
     * Last item in array
     */
    const lastItem = refactor.length - 1;

    useEffect(() => {
        if (!centered) {
            setTimeout(() => {
                document?.getElementById("TODAYYY")?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
            }, 200);
            setToCenter(true);
        }
    }, [centered]);

    return (
        <Dialog
            open={open}
            scroll={undefined}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "85%",
                    height: "80vh",
                },
            }}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <Toolbar
                sx={{
                    backgroundColor: todayIndicator,
                    color: white,
                }}
                disableGutters
                style={{ display: "flex", justifyContent: "space-between", paddingLeft: 16, paddingRight: 16 }}
            >
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* Tender title as chat title */}
                    <Typography variant="body2">{t("tenderPage.Schedule")}</Typography>
                </div>
                {/* conversation name like private notes, team.. or name of colleague  and closebutton to close modal*/}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* <Typography variant="body2">{conversationsTest.find((c) => c.id === conversation)?.name}</Typography> */}
                    <IconButton onClick={handleClose} size="small">
                        <Close htmlColor={white} />
                    </IconButton>
                </div>
            </Toolbar>
            <DialogContent style={{ paddingTop: 0 }}>
                <div>
                    <Timeline
                        sx={{
                            "& .MuiTimeline-root": {
                                padding: 0,
                            },
                        }}
                    >
                        {refactor.map((item, index) => {
                            return (
                                <div key={index}>
                                    {item.id === "date_of_today" ? (
                                        <div>
                                            {/* today indicator */}
                                            <TimelineItem
                                                sx={{
                                                    minHeight: 0,
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {/* show date of today */}
                                                <TimelineOppositeContent>
                                                    <Typography>{moment().format("D MMM")}</Typography>
                                                </TimelineOppositeContent>

                                                {/* Outlined dot to show user this is not an scheduled item */}
                                                <TimelineSeparator>
                                                    <TimelineDot variant="outlined" style={{ borderColor: `${todayIndicator}` }} />
                                                </TimelineSeparator>

                                                {/* Small line to show an indicator that this is today */}
                                                <TimelineContent style={{ flex: 10 }}>
                                                    <div
                                                        id="TODAYYY"
                                                        style={{ height: "2px", backgroundColor: `${todayIndicator}`, width: "100%" }}
                                                    />
                                                </TimelineContent>
                                            </TimelineItem>
                                        </div>
                                    ) : (
                                        // if timelineitem date is not firstfuturedate, show item without today indicator
                                        <ScheduleTimeLineItem
                                            isResultOrSectorMatch={isResultOrSectorMatch}
                                            deadline={deadline}
                                            showOptionToAdd={false}
                                            host={host}
                                            hostUrl={hostUrl}
                                            tender_id={tender_id}
                                            item={item}
                                            index={index}
                                            lastItem={lastItem}
                                            itemToCal={itemToCal}
                                            onHandleAdd={handleAdd}
                                            showSource={open}
                                            showHandleAdd={item.isStored}
                                            incompatibleDates={data?.tender?.incompatibleDates ?? []}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Timeline>
                </div>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <div style={{ width: "100px" }} />
                <div style={{ display: "flex", justifyContent: "center", margin: "12px 0px" }}>
                    {/*
                     *  Checkbox to show/hide replaced events
                     */}
                    <FormControlLabel
                        control={<Checkbox checked={!hideReplacedItems} onChange={onHidereplacedItems} inputProps={{ "aria-label": "controlled" }} />}
                        label={"Gewijzigd"}
                    />
                    <div style={{ width: "16px" }} />
                    {/*
                     *  Checkbox to show/hide holiday events
                     */}
                    <FormControlLabel
                        control={<Checkbox checked={!hideHoliday} onChange={onHideholiday} inputProps={{ "aria-label": "controlled" }} />}
                        label={"Feestdagen"}
                    />
                </div>
                <Button onClick={handleClose} color="primary">
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlanningExpandModal;
