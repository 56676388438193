import React, { useState } from "react";
import { Checkbox, Chip, Divider, Link, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/GetApp";
// import DocumentViewer from "./DocumentViewer";

import MarkedDocIconSwitch from "../../MarkedDocIconSwitch";
import DocIconSwitch from "../../DocIconSwitch";
import DocumentDrawer from "../../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { useTranslation } from "react-i18next";
interface Props {
    label: string | null | undefined;
    icon: string | null | undefined;
    marked: boolean;
    link: string | null | undefined;
    file_id: string;
    tender_id: string;
    download_link: string | null;
    onFileClick?: (folder: number) => void;
    relevantDocumentIds?: number[];
    onFileClickData?: (folder: { id: any; label: any; link: any }) => void;
    lotNumber: number | null;
    downloadable: boolean;
}

const Document: React.FC<Props> = ({
    label,
    icon,
    link,
    marked,
    file_id,
    tender_id,
    download_link,
    onFileClick,
    relevantDocumentIds,
    onFileClickData,
    lotNumber,
    downloadable,
}) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const colorDownload = "#9e9e9e";
    const [open, setOpen] = useState<boolean>(false);
    const blue = "#173357";
    const { trackEvent } = useMatomo();
    const closeDrawer = () => {
        setOpen(false);
    };

    // Track event
    const trackSingleDownload = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <>
            {/* on listitem hover: downloadbutton shows up -> click is download document
             * on link hover: downloadbutton disappears -> click is open pdf in viewer */}
            {onFileClick && onFileClickData && relevantDocumentIds ? (
                <ListItem
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        "&:hover": {
                            cursor: "pointer",
                            textDecoration: "underline",
                        },
                    }}
                    // button
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => {
                        onFileClick(parseInt(file_id));
                        const file = { id: file_id, label: label, link: link };
                        onFileClickData(file);
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            style={{ padding: 0 }}
                            edge="start"
                            checked={relevantDocumentIds.includes(parseInt(file_id))}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": file_id }}
                        />
                    </ListItemIcon>

                    {/* document title */}
                    <ListItemText
                        primary={
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ maxWidth: "95%" }}>
                                    {/* Link opens pdf in viewer */}
                                    <Link
                                        component="button"
                                        underline="none"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            width: "100%",
                                            "&:hover": {
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={(e) => {
                                            if (icon === "pdf" || icon === "fa-file-pdf-o") {
                                                e.stopPropagation();
                                                setOpen(true);
                                            } else if (downloadable) {
                                                // Stop click on listitem to prevent document-viewer to initiate
                                                e.stopPropagation();
                                                trackSingleDownload({
                                                    category: "Tender",
                                                    action: "Click-download-file",
                                                    name: `${label}`,
                                                    href: window.location.href,
                                                });

                                                const element = document.createElement("a");
                                                const encodedFilename = label ? encodeURIComponent(label) : "doc";
                                                element.setAttribute(
                                                    "href",
                                                    `${link}&response-content-disposition=attachment; filename=${encodedFilename}`
                                                );

                                                element.style.display = "none";
                                                element.style.textDecoration = "none";
                                                document.body.appendChild(element);
                                                element.click();

                                                document.body.removeChild(element);
                                            }
                                        }}
                                        onMouseEnter={() => setHovered(false)}
                                        onMouseLeave={() => setHovered(true)}
                                    >
                                        <Typography noWrap sx={{ textDecoration: "none" }}>
                                            {label}
                                        </Typography>
                                    </Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* on hover, show download icon */}
                                    {hovered && downloadable && <DownloadIcon fontSize="small" style={{ color: `${colorDownload}` }} />}
                                    {lotNumber && (
                                        <Chip
                                            size="small"
                                            label={
                                                <Typography variant="caption" fontWeight={500}>
                                                    {t("Lot")} {lotNumber}
                                                </Typography>
                                            }
                                            sx={{ marginLeft: "8px" }}
                                        />
                                    )}
                                </div>
                            </div>
                        }
                    />
                </ListItem>
            ) : (
                <ListItem
                    disabled={!(icon === "pdf" || icon === "fa-file-pdf-o") && !downloadable}
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        "&:hover": {
                            cursor: "pointer",
                        },
                    }}
                    // button
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={(e) => {
                        // Stop click on listitem to prevent document-viewer to initiate
                        // e.stopPropagation();
                        // const element = document.createElement("a");
                        // const encodedFilename = label ? encodeURIComponent(label) : "doc";
                        // element.setAttribute("href", `${link}&response-content-disposition=attachment; filename=${"qq"}`);
                        // element.style.display = "none";
                        // document.body.appendChild(element);
                        // element.click();
                        // document.body.removeChild(element);
                        if (downloadable) {
                            e.stopPropagation();
                            const element = document.createElement("a");
                            const encodedFilename = label ? encodeURIComponent(label) : "doc";
                            element.setAttribute("download", `${encodedFilename}`);
                            element.setAttribute("href", `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFile/${file_id}`);
                            element.style.display = "none";
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                        }
                    }}
                >
                    {/* show icon */}
                    {marked ? <MarkedDocIconSwitch icon={icon} /> : <DocIconSwitch icon={icon} />}
                    {/* document title */}
                    <ListItemText
                        primary={
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ maxWidth: "95%" }}>
                                    {/* Link opens pdf in viewer */}
                                    <Link
                                        style={{ width: "100%" }}
                                        component="button"
                                        underline="none"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,

                                            "&:hover": {
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={(e) => {
                                            if (icon === "pdf" || icon === "fa-file-pdf-o") {
                                                e.stopPropagation();
                                                setOpen(true);
                                            } else if (downloadable) {
                                                // Stop click on listitem to prevent document-viewer to initiate
                                                e.stopPropagation();
                                                const element = document.createElement("a");
                                                const encodedFilename = label ? encodeURIComponent(label) : "doc";

                                                element.setAttribute(
                                                    "href",
                                                    `${link}&response-content-disposition=attachment; filename=${encodedFilename}`
                                                );
                                                element.style.display = "none";
                                                document.body.appendChild(element);
                                                element.click();
                                                document.body.removeChild(element);
                                            }
                                        }}
                                        onMouseEnter={() => setHovered(false)}
                                        onMouseLeave={() => setHovered(true)}
                                    >
                                        <Typography noWrap>{label}</Typography>
                                    </Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* on hover, show download icon */}
                                    {hovered && downloadable && <DownloadIcon fontSize="small" style={{ color: `${colorDownload}` }} />}
                                    {lotNumber && (
                                        <Chip
                                            size="small"
                                            label={
                                                <Typography variant="caption" fontWeight={500}>
                                                    {t("Lot")} {lotNumber}
                                                </Typography>
                                            }
                                            sx={{ marginLeft: "8px" }}
                                        />
                                    )}
                                </div>
                            </div>
                        }
                    />
                </ListItem>
            )}
            {hovered ? <Divider variant="middle" /> : <div style={{ width: "100%", height: "1px" }} />}
            {/* Drawer that contains clicked pdf file */}
            {typeof label === "string" && typeof link === "string" && open === true && (
                <DocumentDrawer
                    open={open}
                    onClose={closeDrawer}
                    title={label}
                    color={blue}
                    url={link}
                    doc_id={file_id}
                    module_item_id={tender_id}
                    downloadable={downloadable}
                />
            )}
        </>
    );
};

export default Document;
