import React from "react";
import { Typography } from "@mui/material";
import { FormatQuote } from "@mui/icons-material";

interface Props {
    /**
     * Quote text
     */
    text: string;
}

const Quote: React.FC<Props> = ({ text }) => {
    return (
        <Typography
            sx={{
                margin: "8px 0px",
                position: "relative",
                padding: "16px",
                backgroundColor: "#70a4b717",
                borderLeft: "6px solid #70a4b7",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
            }}
        >
            {text}
            <FormatQuote
                sx={{
                    position: "absolute",
                    right: 0,
                    top: -24,
                    fontSize: "54px",
                    color: "#70a4b7",
                }}
            />
        </Typography>
    );
};

export default Quote;
