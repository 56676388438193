import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_ORGANIZATION_SEARCHES } from "../../../../graphql/queryDefCurrentUser";
import { GetOrganizationSearchRules } from "../../../../__generated__/GetOrganizationSearchRules";
import { Grid, List, ListItem, Typography } from "@mui/material";
import RenderPage from "../../../../components/loading/RenderPage";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import { useTranslation } from "react-i18next";
// import { useMatomo } from "@datapunt/matomo-tracker-react";
// import MatomoEvent from "../../../../models/MatomoEvent";
import SearchOwnerFilter from "../SearchOwnerFilter";
import SearchLineItemOnlyShare from "../only_share/SearchLineItemOnlyShare";
import { useUserId } from "../../../../components/contextProviders/UserIdContext";

interface Props {
    admin: boolean;
}

const OrganisationSearchLineOnlyShare: React.FC<Props> = ({ admin }) => {
    const { t } = useTranslation();
    // const { trackEvent } = useMatomo();
    const [uniqueOwners, setUniqueOwners] = useState<any[]>([]);
    const [chosenOwners, setChosenOwners] = useState<number[]>([]);
    const { userId } = useUserId();

    // // Track event
    // const trackevents = (event: MatomoEvent) => {
    //     trackEvent(event);
    // };

    /**
     * Define the GQL query
     */
    const { data, loading, error, refetch } = useQuery<GetOrganizationSearchRules>(GET_ORGANIZATION_SEARCHES, {
        skip: !admin,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.getOrganizationSearches) {
                const searchlines = data.getOrganizationSearches as any;
                const uniqueOwnerIds = [...new Set(data.getOrganizationSearches.map((searchline: any) => searchline.owner?.id))];
                const uniqueOwners = uniqueOwnerIds.map((ownerId) => {
                    const ownerSearches = searchlines.filter((searchline: any) => searchline.owner?.id === ownerId);
                    return {
                        ...searchlines.find((searchline: any) => searchline.owner.id === ownerId).owner,
                        count: ownerSearches.length,
                    };
                });
                setUniqueOwners(uniqueOwners);
            }
        },
    });

    if (loading)
        /**
         * if loading -> show loading
         */
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );

    /**
     * if error or empty -> show error
     */
    if (error || !data)
        return (
            <List style={{ padding: 0 }}>
                <ListItem
                    sx={{
                        borderLeft: (theme) => `5px solid ${"#173357"}`,
                        backgroundColor: "#ffffff",
                        height: "75px",
                    }}
                >
                    <Typography variant="h6">{t("Error")}</Typography>
                </ListItem>
            </List>
        );

    // Filter the array based on the chosenOwner IDs
    const filteredSearchlines =
        chosenOwners.length > 0
            ? data.getOrganizationSearches.filter((searchline) => chosenOwners.includes(parseInt(searchline?.owner?.id as string)))
            : data.getOrganizationSearches;

    // Sort array => CU searches first
    const sortedSearchlines = filteredSearchlines.slice().sort((a, b) => {
        const ownerA = parseInt(a?.owner?.id as string);
        const ownerB = parseInt(b?.owner?.id as string);
        const UID = parseInt(userId as string);

        // Eerst sorteren zodat de zoekregels met owner.id === 10291 bovenaan komen
        if (ownerA === UID && ownerB !== UID) return -1; // A komt eerst als A de eigenaar 10291 heeft
        if (ownerB === UID && ownerA !== UID) return 1; // B komt eerst als B de eigenaar 10291 heeft

        // Als beide dezelfde eigenaar hebben, of geen van beide 10291 is, sorteer dan op owner.id
        if (ownerA < ownerB) return -1;
        if (ownerA > ownerB) return 1;

        return 0; // Als ze gelijk zijn, blijven ze op hun plek
    });

    return (
        <React.Fragment>
            <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
                    <SearchOwnerFilter owners={uniqueOwners} chosenOwners={chosenOwners} setChosenOwners={setChosenOwners} />
                </Grid>

                <Grid item xs={12}>
                    <List>
                        {sortedSearchlines

                            // .filter((search) => search?.state !== "PENDING_ACCEPTANCE")
                            .map((searchline) => {
                                if (searchline === null) {
                                    return <></>;
                                }
                                return <SearchLineItemOnlyShare refetch={refetch} type={"tender"} key={searchline.id} searchline={searchline} />;
                            })}
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default OrganisationSearchLineOnlyShare;
