import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GetContractInfoForCurrentTender, GetContractInfoForCurrentTenderVariables } from "../../../../__generated__/GetContractInfoForCurrentTender";
import { QUERY_CONTRACT_INFORMATION_FOR_CURRENT_TENDER } from "../../../../graphql/queryDefinitions";
import ContractLotsWidget from "../../../../components/tenderDetail/ContractLotsWidget";
import ContractWidget from "../../../../components/tenderDetail/ContractWidget";
import { Grid } from "@mui/material";
import { mergeOptiesEinde } from "../../../../utils/contracttimeline";
import { DoesSectorMatchWithUserSubscription } from "../../../../hooks/isAccountAllowed";

interface Props {
    tender_id: string;
}

const TenderContractWidget: React.FC<Props> = ({ tender_id }) => {
    const [isResultOrSectorMatch, setIsVisible] = useState<boolean>(false);
    const [loadVisible, setLoadVisible] = useState<boolean>(false);

    const { loading, error, data } = useQuery<GetContractInfoForCurrentTender, GetContractInfoForCurrentTenderVariables>(
        QUERY_CONTRACT_INFORMATION_FOR_CURRENT_TENDER,
        {
            variables: { id: tender_id },
        }
    );

    useEffect(() => {
        const checkVisibility = async () => {
            setLoadVisible(true);
            const result = await DoesSectorMatchWithUserSubscription(parseInt(tender_id));
            setIsVisible(result); // Update visibility state
            setLoadVisible(false);
        };

        checkVisibility();
    }, [tender_id]);

    if (loading || loadVisible) {
        return null;
    }
    if (error || !data || !data.tender) {
        return null;
    }

    const tender = data.tender;

    if (Boolean(tender.lots.length === 1 && tender.lots.some((item) => item.number === "0"))) {
        if (
            tender.contract ||
            tender.published_looptijd_start ||
            tender.published_looptijd_einde ||
            //   tender.published_looptijd_interval ||
            //   tender.published_looptijd_periode ||
            tender.published_looptijd_beschrijving
        ) {
            return (
                <Grid item xs={12} md={6}>
                    <ContractWidget
                        isResultOrSectorMatch={isResultOrSectorMatch}
                        datum_gunning={tender.datum_gunning}
                        contract={tender.contract}
                        start={tender.published_looptijd_start}
                        end={tender.published_looptijd_einde}
                        description={tender.published_looptijd_beschrijving}
                        interval={tender.published_looptijd_interval}
                        period={tender.published_looptijd_periode}
                        renew_desc={tender.displayPublications[0].renewal_descr}
                        next_looptijd_einde={tender.next_looptijd_einde}
                        looptijd_start={tender.looptijd_start}
                        looptijd_einde_incl_opties={tender.looptijd_einde_incl_opties}
                        status_contract={tender.status_contract}
                        tender_id={tender.id}
                        elevation={0}
                        looptijd_einde={tender.looptijd_einde}
                        opties={mergeOptiesEinde(tender.opties_einde)}
                    />
                </Grid>
            );
        }
        return null;
    }

    if (Boolean(tender.lots.length > 0)) {
        return (
            <Grid item md={6}>
                <ContractLotsWidget
                    isResultOrSectorMatch={isResultOrSectorMatch}
                    datum_gunning={tender.datum_gunning}
                    lots={tender.lots}
                    tender_id={tender_id}
                    elevation={0}
                    contract_tender_level={tender.contract}
                    opties={mergeOptiesEinde(tender.opties_einde)}
                />
            </Grid>
        );
    }

    return (
        <Grid item md={6}>
            {tender.contract ||
            tender.published_looptijd_start ||
            tender.published_looptijd_einde ||
            //   tender.published_looptijd_interval ||
            //   tender.published_looptijd_periode ||
            tender.published_looptijd_beschrijving ? (
                <ContractWidget
                    isResultOrSectorMatch={isResultOrSectorMatch}
                    datum_gunning={tender.datum_gunning}
                    elevation={0}
                    contract={tender.contract}
                    start={tender.published_looptijd_start}
                    end={tender.published_looptijd_einde}
                    description={tender.published_looptijd_beschrijving}
                    interval={tender.published_looptijd_interval}
                    period={tender.published_looptijd_periode}
                    renew_desc={tender.displayPublications[0].renewal_descr}
                    next_looptijd_einde={tender.next_looptijd_einde}
                    looptijd_einde_incl_opties={tender.looptijd_einde_incl_opties}
                    status_contract={tender.status_contract}
                    tender_id={tender.id}
                    looptijd_start={tender.looptijd_start}
                    looptijd_einde={tender.looptijd_einde}
                    opties={mergeOptiesEinde(tender.opties_einde)}
                />
            ) : null}
        </Grid>
    );
};

export default TenderContractWidget;
