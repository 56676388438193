import React from "react";
import Quote from "./conten_types/Quote";
import Introduction from "./conten_types/Introduction";
import CustomLink from "./conten_types/CustomLink";

interface Props {
    text: string;
}

// const exampleText = `
//   Check out <LINK>url:https://example.com| tooltip_favicon:https://example.com/favicon.ico| tooltip_title:Example Title| tooltip_text:This is an example tooltip.| url_text:Example</LINK>
//   for more details!
// `;

const ColumnContent: React.FC<Props> = ({ text }) => {
    // Regular expression to match <LINK>...</LINK> tags and extract the values
    const unescapedText = text.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    // const linkRegex = /<LINK>url:([^|]+); tooltip_favicon:([^|]+); tooltip_title:([^|]+); tooltip_text:([^|]+); url_text:\s*([^|]+)<\/LINK>/g;
    const linkRegex = /<LINK>url:([^|]+)\| tooltip_favicon:([^|]+)\| tooltip_title:([^|]+)\| tooltip_text:([^|]+)\| url_text:\s*([^<]+)<\/LINK>/g;

    const modifiedText = unescapedText.replace(linkRegex, (_, url, tooltip_favicon, tooltip_title, tooltip_text, url_text) => {
        return `<CustomLink href="${url}" tooltip_favicon="${tooltip_favicon}" tooltip_title="${tooltip_title}" tooltip_text="${tooltip_text}">${url_text}</CustomLink>`;
    });

    const RenderHtmlContent = (htmlContent: string) => {
        if (!htmlContent) return <div />; // Early return if content is empty

        // Split the content by the tags you want to replace
        const parts = htmlContent.split(/(<blockquote.*?<\/blockquote>|<del.*?<\/del>|<CustomLink.*?>.*?<\/CustomLink>)/g);

        const processParts = parts.map((part, index) => {
            if (part.startsWith("<CustomLink")) {
                const matches = part.match(
                    /<CustomLink href="([^"]+)" tooltip_favicon="([^"]+)" tooltip_title="([^"]+)" tooltip_text="([^"]+)">([^<]+)<\/CustomLink>/
                );
                if (matches) {
                    const [, href, tooltip_favicon, tooltip_title, tooltip_text, content] = matches;
                    return (
                        <CustomLink
                            key={index}
                            href={href}
                            tooltip_favicon={tooltip_favicon}
                            tooltip_title={tooltip_title}
                            tooltip_text={tooltip_text}
                        >
                            {content}
                        </CustomLink>
                    );
                }
            }

            // Check for <blockquote> tags
            else if (part.startsWith("<blockquote") && part.endsWith("</blockquote>")) {
                return <Quote key={index} text={part.replace(/<\/?blockquote>/g, "")} />;
            }

            // Check for <del> tags
            else if (part.startsWith("<del") && part.endsWith("</del>")) {
                return <Introduction key={index} text={part.replace(/<\/?del>/g, "")} />;
            }

            // For other parts (text and other tags), render them as normal text
            else if (part.trim() !== "") {
                const party = part.replaceAll("<div>", '<div style="display:contents">');

                return <span key={index} dangerouslySetInnerHTML={{ __html: party }} />;
            }

            // In case the part is empty (like for <br>), return a null or empty component
            return null;
        });

        // If no content is returned after processing parts, return an empty div
        return <p>{processParts.length > 0 ? processParts : <p />}</p>;
    };

    // RETURN CONTENT WITH CUSTOM COMPONENTS
    return (
        <div>
            <style>
                {`
                    ul li::marker {
                        color: #70a4b7; /* Change marker color */
                        font-size: 18px;
                    }

                     ol li::marker {
                        color: #70a4b7; /* Change marker color */
                        font-weight:500;
                    }
                `}
            </style>
            {RenderHtmlContent(modifiedText)}
        </div>
    );

    //  <div>{parseText(text).map((i) => i)}</div>;
};

export default ColumnContent;

// IMAGE LINK
// const ImageComponent: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
//     <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
//         <img src={src} alt={alt} style={{ maxWidth: "100%", height: "auto" }} />
//     </Box>
// );

// IMAGE (FIGURE TRIX)
// const FigureComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => (
//     <Box sx={{ my: 2 }}>
//         <figure style={{ margin: 0 }}>{children}</figure>
//     </Box>
// );

// if (part.startsWith("<img") && part.endsWith("/>")) {
//     // Extract src and alt from the img tag
//     const srcMatch = part.match(/src="([^"]+)"/);

//     const altMatch = part.match(/alt="([^"]+)"/);

//     if (srcMatch) {
//         return <ImageComponent key={index} src={srcMatch[1]} alt={altMatch ? altMatch[1] : ""} />;
//     }
// }

// if (part.startsWith("<figure") && part.endsWith("</figure>")) {
//     // Extract the content inside <figure>...</figure>
//     const figureContent = part.replace(/<\/?figure>/g, "");
//     return <FigureComponent key={index}>{figureContent}</FigureComponent>;
// }
