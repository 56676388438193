import React, { useContext, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Popover,
    Theme,
    Tooltip,
    Typography,
    Badge,
    Dialog,
} from "@mui/material";
import { AccountTree, Add } from "@mui/icons-material";
// import components
import CaSection from "./CaSection";
import ProjectTitle from "./ProjectTitle";
import WidgetsToChoose from "./WidgetsToChoose";
import UserAndTeamsSection from "./UserAndTeamsSection";
import { ProjectToOpenContext, Widget } from "../../../components/layout/MainLayout";
import { useMutation, useQuery } from "@apollo/client";
import { GetCurrentUserNameToShow } from "../../../__generated__/GetCurrentUserNameToShow";
import { createNewProject, createNewProjectVariables } from "../../../__generated__/createNewProject";
import { CREATE_NEW_PROJECT } from "../../../graphql/mutationDefProjects";
import { ALL_PROJECTS, PROJECT_STATES } from "../../../graphql/queryDefProjects";
import { toast } from "react-toastify";
import { Disable } from "react-disable";
import { withStyles } from "tss-react/mui";
import { likeTender, likeTenderVariables } from "../../../__generated__/likeTender";
import { QUERY_TENDER_PROJECTS_FOR_USER } from "../../../components/tenderDetail/OpenOrCreateProject";
import { LIKETENDER } from "../../../graphql/mutationDefinitions";
import { QUERY_CURRENT_USER_NAME } from "../../../graphql/queryDefCurrentUser";
import { TenderUserStatus } from "../../../__generated__/globalTypes";

interface Props {
    tender_title: string;
    tender_id: string;
    tenderReviewed?: boolean;
    openFromTable: boolean;
    btnSize: "small" | "medium";
    refetchQueries?: any;
    tenderUserState: TenderUserStatus | undefined;
}

const StyledAvatar = withStyles(Avatar, (theme: Theme) => ({
    root: {
        width: "32px",
        height: "32px",
        fontSize: "0.8rem",
        // backgroundColor: "#454545",
        marginTop: "8px",
        border: "2px solid #fff",
        marginLeft: "16px",
        zIndex: 99,
    },
}));

const CreateProjectFromEntity: React.FC<Props> = ({
    tender_title,
    tender_id,
    tenderReviewed,
    openFromTable,
    btnSize,
    refetchQueries = [],
    tenderUserState,
}) => {
    const [saveProject] = useMutation<createNewProject, createNewProjectVariables>(CREATE_NEW_PROJECT);
    const { setOpen, project, changeProject } = useContext(ProjectToOpenContext);
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openProjectAfterShare, setOpenProjectAfterShare] = useState<boolean>(true);
    const [save, { error: errorMutation }] = useMutation<likeTender, likeTenderVariables>(LIKETENDER);

    const { data } = useQuery<GetCurrentUserNameToShow>(QUERY_CURRENT_USER_NAME);

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        changeProject({
            projectTitle: "",
            state: "",
            widgets: { summary: true, planning: true, scope: true, documents: true, contract: true, labels: true },
            tasks: [],
            users: [],
            ca_ids: [],
            tender_ids: [],
        });
        setAnchorEl(event.currentTarget);
        setOpenPopper(true);
        changeProject({ projectTitle: tender_title, tender_ids: [tender_id] });
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenPopper(false);
    };

    const handleChangeSingleWidget = (name: string, checked: boolean, event?: React.ChangeEvent<HTMLInputElement>) => {
        changeProject({
            ...project,
            widgets: {
                ...project.widgets,
                [name]: checked,
            },
        });
    };

    const handleChangeAll = (widgets: Widget) => {
        changeProject({
            ...project,
            widgets: widgets,
        });
    };

    // Function to like a tender
    const likeTender = async () => {
        const cmbnd = refetchQueries.concat([QUERY_TENDER_PROJECTS_FOR_USER, "GetTenderProjectsForUser", PROJECT_STATES]);
        try {
            await save({
                variables: {
                    tenderID: tender_id,
                    reason: "",
                    remark: "",
                },
                refetchQueries: cmbnd,
                awaitRefetchQueries: true,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
            if (errorMutation) {
                toast.error("Fout tijdens opslaan (error)");
            }
        }
    };

    /**
     * Check if selection is a single word without special characters
     * If so, add it to the searchterms
     */
    const createNewProject = async () => {
        const cmbnd = refetchQueries.concat([
            ALL_PROJECTS, // DocumentNode object parsed with gql
            "GetAllProjects", // Query name
            QUERY_TENDER_PROJECTS_FOR_USER,
            "GetTenderProjectsForUser",
            PROJECT_STATES,
            "projectStates",
        ]);
        try {
            await saveProject({
                variables: {
                    title: project.projectTitle,
                    tender_ids: project.tender_ids,
                    user_ids: project.users.map((i) => i.id).concat(data?.currentUser.id || ""),
                    contractingAuthority_ids: project.ca_ids,
                    state: project.state,
                    widgets: project.widgets,
                },
                refetchQueries: cmbnd,
                awaitRefetchQueries: true,
                onCompleted: (e) => {
                    changeProject({ projectID: e.createProject?.id });
                    toast.success(`Project aangemaakt`);
                    setOpenPopper(false);

                    setAnchorEl(null);

                    if (openProjectAfterShare === true) {
                        setTimeout(() => {
                            setOpen(true);
                        }, 400);
                    }
                },
            });
        } catch (e) {}
    };

    return (
        <React.Fragment>
            <Tooltip title="Maak project" enterNextDelay={100} placement="top">
                <Badge
                    sx={{
                        cursor: "pointer",
                        marginRight: "0px",
                        "& .MuiBadge-badge": {
                            right: 6,
                            top: 6,
                            border: `1px solid #fff`,
                            backgroundColor: "#9e9e9e",
                            padding: "0 2px",
                            fontSize: "9px",
                            minWidth: "16px",
                            height: "16px",
                        },
                    }}
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    badgeContent={
                        <>
                            <Add sx={{ height: "10px", width: "10px", padding: 0, color: "#ffffff" }} />
                        </>
                    }
                >
                    <IconButton onClick={handleClickSub} size={btnSize}>
                        <AccountTree fontSize="small" />
                    </IconButton>
                </Badge>
            </Tooltip>

            {openFromTable ? (
                <Dialog open={openPopper}>
                    <div style={{ padding: "16px 16px 0px 16px", maxHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}>
                        <div style={{ marginBottom: "16px" }}>
                            <ProjectTitle />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Gebruikers toevoegen
                            </Typography>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* local user added by default */}
                                <StyledAvatar>
                                    <Typography>
                                        {data?.currentUser.employee.name?.slice(0, 1)}
                                        {data?.currentUser.employee.familyname?.slice(0, 1)}{" "}
                                    </Typography>
                                </StyledAvatar>

                                <div style={{ marginLeft: "-24px" }}>
                                    <UserAndTeamsSection />
                                </div>
                            </div>
                        </div>

                        {/*
                         * CA section
                         */}
                        <div style={{ marginBottom: "16px" }}>
                            <CaSection id={tender_id} />
                        </div>

                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Widgets toevoegen
                            </Typography>
                            <Disable disabled={false}>
                                <WidgetsToChoose onChangeWidget={handleChangeSingleWidget} onChangeAllWidgets={handleChangeAll} project={project} />
                            </Disable>
                        </Box>
                    </div>
                    {/* Buttons to cancel or share */}
                    <div style={{ margin: "0px 16px" }}>
                        <Divider />
                    </div>
                    <Box
                        style={{
                            marginTop: 0,
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <Box style={{ display: "flex", justifyContent: "flex-end", marginRight: 8 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={openProjectAfterShare}
                                            onChange={() => setOpenProjectAfterShare(!openProjectAfterShare)}
                                        />
                                    }
                                    label="Open project"
                                    labelPlacement="end"
                                />
                            </Box>
                        </div>
                        <div>
                            <Button
                                variant="text"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCloseSub(e);
                                }}
                            >
                                Annuleren
                            </Button>
                            <Button
                                disabled={project.state === ""}
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    createNewProject();
                                    if (tenderUserState !== TenderUserStatus.LIKED) {
                                        likeTender();
                                    }
                                }}
                            >
                                Maak project
                            </Button>
                        </div>
                    </Box>
                </Dialog>
            ) : (
                <Popover
                    anchorEl={anchorEl}
                    open={openPopper}
                    onClose={handleCloseSub}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    /**
                     * Width and height for chat/knowledge base
                     */
                    slotProps={{
                        paper: {
                            sx: {
                                width: "600px",
                            },
                        },
                    }}
                >
                    <div style={{ padding: "16px 16px 0px 16px", maxHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}>
                        <div style={{ marginBottom: "16px" }}>
                            <ProjectTitle />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Gebruikers toevoegen
                            </Typography>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* local user added by default */}
                                <StyledAvatar>
                                    <Typography>
                                        {data?.currentUser.employee.name?.slice(0, 1)}
                                        {data?.currentUser.employee.familyname?.slice(0, 1)}
                                    </Typography>
                                </StyledAvatar>

                                <div style={{ marginLeft: "-24px" }}>
                                    <UserAndTeamsSection />
                                </div>
                            </div>
                        </div>

                        {/*
                         * CA section
                         */}
                        <div style={{ marginBottom: "16px" }}>
                            <CaSection id={tender_id} />
                        </div>

                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Widgets toevoegen
                            </Typography>
                            <Disable disabled={false}>
                                <WidgetsToChoose onChangeWidget={handleChangeSingleWidget} onChangeAllWidgets={handleChangeAll} project={project} />
                            </Disable>
                        </Box>
                    </div>

                    {/* Buttons to cancel or share */}
                    <div style={{ margin: "0px 16px" }}>
                        <Divider />
                    </div>
                    <Box
                        style={{
                            marginTop: 0,
                            padding: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <Box style={{ display: "flex", justifyContent: "flex-end", marginRight: 8 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={openProjectAfterShare}
                                            onChange={() => setOpenProjectAfterShare(!openProjectAfterShare)}
                                        />
                                    }
                                    label="Open project"
                                    labelPlacement="end"
                                />
                            </Box>
                        </div>
                        <div>
                            <Button
                                variant="text"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCloseSub(e);
                                }}
                            >
                                Annuleren
                            </Button>
                            <Button
                                disabled={project.state === ""}
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    createNewProject();
                                    if (tenderUserState !== TenderUserStatus.LIKED) {
                                        likeTender();
                                    }
                                }}
                            >
                                Maak project
                            </Button>
                        </div>
                    </Box>
                </Popover>
            )}
        </React.Fragment>
    );
};

export default CreateProjectFromEntity;
