import React from "react";
import SearchLineOnlyShare from "../SearchRule/only_share/SearchLineOnlyShare";
import { Button } from "@mui/material";

interface Props {
    onClose: () => void;
}

const SearchruleList: React.FC<Props> = ({ onClose }) => {
    return (
        <div>
            <div style={{ padding: "0px 16px", height: "60vh", overflowY: "auto" }}>
                {/* ALL tender searchrules of org */}
                <SearchLineOnlyShare chosenRules={"All"} />
                {/* current user opportunity searchrules */}
                {/* <SearchLineOnlyShare chosenRules={"opportunities"} /> */}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "12px 16px 12px 0px" }}>
                <Button onClick={onClose}>Overslaan</Button>
            </div>
        </div>
    );
};

export default SearchruleList;
