import React, { useState } from "react";
import { Dialog, DialogTitle, TextField, Paper, DialogContent, Grid, TextFieldProps, Box, Avatar, Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
// import { Dialog, DialogTitle, Button, TextField, Paper, DialogContent, Grid, TextFieldProps, Box, Avatar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import _ from "lodash";
import emailDomainCheck from "../../../utils/maildomainchecker";
import { GetScubscriptionAndTeams_organization_subscriptions_allowedDomains } from "../../../__generated__/GetScubscriptionAndTeams";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import { Call, Smartphone } from "@mui/icons-material";
import SearchruleList from "./SearchruleList";
import { QUERY_USER_IS_SUB_ADMIN } from "../../../graphql/queryDefCurrentUser";
import { useQuery } from "@apollo/client";
import { CheckIfUserIsAdmin } from "../../../__generated__/CheckIfUserIsAdmin";
import { LoadingButton } from "@mui/lab";

interface Props {
    open: boolean;
    onClose: (reset: boolean) => void;
    onSubmit: (data: any, boolean: boolean) => void;
    allowedDomains: (GetScubscriptionAndTeams_organization_subscriptions_allowedDomains | null)[];
    manualInput: Phonenumber;
    manualMobileInput: Phonenumber;
    setManualInput: React.Dispatch<React.SetStateAction<Phonenumber>>;
    setManualMobileInput: React.Dispatch<React.SetStateAction<Phonenumber>>;
    step: number;
    loadAdding: boolean;
}

export const TextFieldProfile = withStyles(
    (props: TextFieldProps) => <TextField autoComplete="off" {...props} variant={"outlined"} fullWidth size={"small"} />,
    {
        root: {
            marginBottom: "12px",
            display: "block",
        },
    }
);

interface CountryData {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
}
export interface Phonenumber {
    phonenumber: string;
    country?: string;
}

const NewUserModal: React.FC<Props> = ({
    open,
    step,
    onClose,
    onSubmit,
    loadAdding,
    allowedDomains,
    manualInput,
    setManualInput,
    manualMobileInput,
    setManualMobileInput,
}) => {
    const { t } = useTranslation();
    const domains = allowedDomains.map((d) => d?.domain as string);

    /**
     * mode 'all' => Check all fields if params are good
     */
    const {
        register,
        handleSubmit,
        errors,
        watch,
        formState: { isDirty, isValid },
    } = useForm({ mode: "all" });
    const watching = watch(["givenname", "middlename", "familyname", "email"]);
    const [givenname, setGivenname] = useState<string>("");
    const [mail, setMail] = useState<string>("");

    const { data, loading } = useQuery<CheckIfUserIsAdmin>(QUERY_USER_IS_SUB_ADMIN);

    /**
     * @param mail => email input
     * @returns Error string or true
     * TODO: Change static list of domains to property allowed domains (not available yet)
     */
    const emailchecker = (mail: string) => {
        return emailDomainCheck(mail, domains) === false ? "Domein niet toegestaan" : true;
    };
    const hasSearches = Boolean(data?.currentUser?.searches?.length ?? 0);
    return (
        <Dialog fullWidth maxWidth={"md"} onClose={() => onClose(false)} open={open}>
            {step === 1 ? (
                <Paper>
                    <DialogTitle sx={{ paddingLeft: "16px" }} id="confirmation-dialog-title">
                        {t("UserAndTeams.add-user")}{" "}
                    </DialogTitle>

                    {/*
                     * Box with centered avatar
                     */}
                    <Box mb={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        {/*
                         * Avatar will be filled with initials
                         */}
                        <Avatar sx={{ width: "100px", height: "100px", fontSize: "2.5rem", border: "2px solid white" }} alt={t("Profile picture")}>
                            {watching.givenname !== undefined && watching.givenname.charAt(0).toUpperCase()}
                            {watching.familyname !== undefined && watching.familyname.charAt(0).toUpperCase()}
                        </Avatar>

                        {/*
                         * Name of person to add
                         */}
                        <Typography mt={1} style={{ height: "28px" }} variant="h3">
                            {_.startCase(watching.givenname)} {watching.middlename} {_.startCase(watching.familyname)}
                        </Typography>
                    </Box>
                    <DialogContent dividers>
                        <form
                            onSubmit={handleSubmit((data) => {
                                setGivenname(watching.givenname);
                                setMail(watching.email);
                                onSubmit(data, hasSearches);
                            })}
                        >
                            <Grid container spacing={4} justifyContent="space-between">
                                {/* Grid item for all the formfields on the left side. */}
                                <Grid item xs={12} sm={12} md={6}>
                                    {/*
                                     *  Textfield givenname, this is a required field
                                     *  First character will be caps
                                     */}
                                    <TextFieldProfile
                                        inputRef={register({
                                            required: "Verplicht veld",
                                            pattern: {
                                                value: /^\S((?!.* {2}).*\S)?$/,
                                                message: "Spatie gebruikt",
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Minimaal 2 karakters",
                                            },
                                        })}
                                        type="text"
                                        name={"givenname"}
                                        label={errors.givenname ? errors.givenname.message : `${t("personal.firstname")}*`}
                                        error={Boolean(errors.givenname) && errors.givenname.message}
                                        inputProps={{ style: { textTransform: "capitalize" } }}
                                    />

                                    {/*
                                     *  Textfield middlename
                                     */}
                                    <TextFieldProfile inputRef={register} name={"middlename"} label={`${t("personal.middlename")}`} />

                                    {/*
                                     *  Textfield for familyname, this is a required field
                                     *  First character will be caps
                                     */}
                                    <TextFieldProfile
                                        inputRef={register({
                                            required: "Verplicht veld",
                                            pattern: {
                                                value: /^\S((?!.* {2}).*\S)?$/,
                                                message: "Spatie gebruikt",
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Minimaal 2 karakters",
                                            },
                                        })}
                                        type="text"
                                        name={"familyname"}
                                        label={errors.familyname ? errors.familyname.message : `${t("personal.lastname")}*`}
                                        error={Boolean(errors.familyname)}
                                        inputProps={{ style: { textTransform: "capitalize" } }}
                                    />
                                </Grid>

                                {/*
                                 *  Grid item for all the items on the right side.
                                 */}
                                <Grid item xs={12} sm={12} md={6}>
                                    {/*
                                     *  Textfield for phonenumber
                                     */}
                                    {/* <TextFieldProfile inputRef={register} name={"phonenumber"} label={`${t("personal.phone")}`} type="tel" /> */}
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "-24px" }}>
                                        <span style={{ marginBottom: "5px", color: "#e0e0e0" }}>
                                            <Call />
                                        </span>
                                        <div style={{ marginBottom: "12px", width: "100%" }}>
                                            <PhoneInput
                                                inputStyle={{
                                                    lineHeight: "1.4375em",
                                                    padding: "8.5px 14px 8.5px 45px",
                                                    height: "37px",
                                                    width: "100%",
                                                }}
                                                country={manualInput.country}
                                                preferredCountries={["nl", "be"]}
                                                enableSearch
                                                value={manualInput.phonenumber}
                                                placeholder="Telefoon"
                                                copyNumbersOnly={false}
                                                onChange={(value, data: CountryData, event, formattedValue) => {
                                                    const stripNumber = value.slice(data.dialCode.length);
                                                    const country = data.countryCode.toUpperCase() as CountryCode;
                                                    const phoneToUse = parsePhoneNumber(stripNumber, country);
                                                    const finalLibbedNumber = phoneToUse?.number;
                                                    setManualInput({ ...manualInput, phonenumber: `${finalLibbedNumber}`, country: country });
                                                }}
                                                inputProps={{
                                                    name: "phone",
                                                    required: false,
                                                    autoFocus: false,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "-24px" }}>
                                        <span style={{ marginBottom: "5px", color: "#e0e0e0" }}>
                                            <Smartphone />
                                        </span>
                                        <div style={{ marginBottom: "12px", width: "100%" }} ref={register}>
                                            <PhoneInput
                                                inputStyle={{
                                                    lineHeight: "1.4375em",
                                                    padding: "8.5px 14px 8.5px 45px",
                                                    height: "37px",
                                                    width: "100%",
                                                }}
                                                country={manualMobileInput.country}
                                                preferredCountries={["nl", "be"]}
                                                enableSearch
                                                value={manualMobileInput.phonenumber}
                                                placeholder="Mobiel"
                                                copyNumbersOnly={false}
                                                onChange={(value, data: CountryData, event, formattedValue) => {
                                                    const stripNumber = value.slice(data.dialCode.length);
                                                    const country = data.countryCode.toUpperCase() as CountryCode;
                                                    const phoneToUse = parsePhoneNumber(stripNumber, country);
                                                    const finalLibbedNumber = phoneToUse?.number;
                                                    setManualMobileInput({
                                                        ...manualMobileInput,
                                                        phonenumber: `${finalLibbedNumber}`,
                                                        country: country,
                                                    });
                                                }}
                                                inputProps={{
                                                    name: "phone",
                                                    required: false,
                                                    autoFocus: false,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/*
                                     *  Textfield for cellphone
                                     */}
                                    {/* <TextFieldProfile inputRef={register} name={"cellphone"} label={`${t("personal.cellphone")}`} type="tel" /> */}

                                    {/*
                                     *  Textfield function
                                     */}
                                    <TextFieldProfile inputRef={register} name={"function"} label={`${t("personal.function")}`} />

                                    {/*
                                     * Selector for userrole, 3 options
                                     * Will be added in 4.x release
                                     */}
                                    {/* <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{ marginTop: 0, marginBottom: "12px" }}
                                    margin="dense"
                                    error={Boolean(errors.role)}
                                >
                                    <InputLabel id="role-select">{t("personal.role")}*</InputLabel>

                                    <Controller
                                        as={
                                            <Select fullWidth labelId="role-select" id="role-select-select" label={`${t("personal.role")}*`}>
                                                <MenuItem value={undefined}>
                                                    <em>-</em>
                                                </MenuItem>
                                                <MenuItem value={"User"}>{t("personal.user")}</MenuItem>
                                                <MenuItem value={"TeamOwner"}>{t("personal.teamOwner")}</MenuItem>
                                                <MenuItem value={"AccountOwner"}>{t("personal.accountOwner")}</MenuItem>
                                            </Select>
                                        }
                                        name={"role"}
                                        rules={{ required: true }}
                                        control={control}
                                    />
                                </FormControl> */}
                                </Grid>
                                {/*
                                 *  Field for email, required field
                                 *  Set to left panel when roles become active as option
                                 *  TODO: Check if domain input is equal to one of the possibilities
                                 */}
                            </Grid>
                            <div>
                                <TextFieldProfile
                                    inputRef={register({
                                        required: "Verplicht veld",
                                        pattern: {
                                            // value: /\S+@\S+\.\S+/,
                                            // eslint-disable-next-line no-useless-escape
                                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Geen geldig e-mailadres",
                                        },

                                        // Validate mail
                                        validate: (value) => emailchecker(value),
                                    })}
                                    name={"email"}
                                    label={errors.email ? errors.email.message : `${t("personal.email")}*`}
                                    error={Boolean(errors.email)}
                                    type="email"
                                    autoComplete="off"
                                />
                            </div>
                            <div>
                                {/*
                                 *  Checkbox that displays a textfield when checked.
                                 */}
                                {/* <TextField
                                placeholder={`Type hier je bericht aan ${watching.givenname ? _.startCase(watching.givenname) : `-voornaam-`}`}
                                fullWidth
                                multiline
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                            /> */}
                            </div>

                            {/*
                             *  Button to send the invite to the emailadress
                             */}
                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <LoadingButton
                                    loading={loadAdding}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!isDirty || !isValid || loading || loadAdding} // here
                                >
                                    {t("personal.Create")}
                                </LoadingButton>
                            </div>
                        </form>
                    </DialogContent>
                </Paper>
            ) : step === 2 ? (
                <Paper>
                    <DialogTitle sx={{ paddingLeft: "16px" }} id="confirmation-dialog-title">
                        Zoekregels delen
                    </DialogTitle>
                    <Typography sx={{ paddingLeft: "16px" }}>
                        Er is een activatiemail gestuurd naar <strong>{mail}</strong> om het account van <strong>{givenname}</strong> te activeren.
                        <br />
                        Je kunt ervoor kiezen om zoekregels te delen met <strong>{givenname}</strong> en/of andere gebruikers binnen je organisatie.{" "}
                        <br />
                        Maak je keuze en deel eenvoudig bestaande zoekregels.
                    </Typography>
                    <Divider sx={{ marginTop: "24px" }} />
                    <DialogContent sx={{ padding: "0px", marginTop: "16px" }}>
                        <SearchruleList onClose={() => onClose(true)} />
                    </DialogContent>
                </Paper>
            ) : (
                <></>
            )}
        </Dialog>
    );
};

export default NewUserModal;
